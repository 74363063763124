import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { REACT_APP_GOOGLE_APP_ID } from "../../../Utils/Common";
import { useRef } from "react";

const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const GoogleAuth = ({ renderComponent = <></>, googleCallBack = () => null }) => {
  const handleSocialAuthRef = useRef(null);
  const handleGoogleCallback = (r) => {
    const res = parseJwt(r.credential);
    if (res && Object.keys(res).length > 0) {
      googleCallBack(res);
    } else {
      googleCallBack(res);
    }
  };

  const handleGoogleIdentity = () => {
    window.google.accounts.id.initialize({
      client_id: REACT_APP_GOOGLE_APP_ID,
      callback: handleGoogleCallback,
    });

    window.google.accounts.id.renderButton(document.getElementById("buttonDivGoogle"), {
      // theme: "outline",
      // size: "large",
      // shape: "rectangular",
      // width: "256px",
      // height: "40px",
      // text: "continue_with",
    });
  };

  const googleIdentityScript = () => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.id = "google-identity-script";
    script.onload = handleGoogleIdentity;
    document.querySelector("body")?.appendChild(script);
  };

  useEffect(() => {
    googleIdentityScript();
    return () => {
      if (window.google) {
        window.google?.accounts.id.cancel();
      }
      document.getElementById("google-identity-script")?.remove();
    };
  }, []); // eslint-disable-line
  return (
    <>
      <span ref={handleSocialAuthRef} id="buttonDivGoogle">
        {renderComponent}
      </span>
      {/* <span
        onClick={(e) => {
          console.log(handleSocialAuthRef.current.click());
          handleSocialAuthRef.current.click();
        }}>
        {renderComponent}
      </span> */}
    </>
  );
};

export default GoogleAuth;
