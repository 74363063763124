export const USERPANEL = "userPanel/";
export const ADMINPANEL = "adminPanel/";

export const CHECK_ACCOUNT = "check-account";
export const ZOHO_ACCESS_TOKEN = "zoho-access-token";
export const GET_USER_COUNTRY = "get-user-info";
export const CURRENCY_LIST = "currency-list";
export const PLANS_LIST = "plan-list";
export const ACCOUNT_SUMMARY = "account-summary";
export const GET_PLAN_DETAILS = "get-plan";
export const PLAN_LISTING_NEW = "plan-list-new";
export const ZOHO_BOOK_DEMO_APPOINTMENT =  `${process.env.REACT_APP_PAYMENT_API_URL}api/NxaAPI/ZohoBookingsBookAppointment`
export const AppDomain = window.location.hostname === "localhost"  ? "localhost:3000" : process.env.REACT_APP_PORTAL_DOMAIN;
