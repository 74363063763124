import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import PhoneInput, { parsePhoneNumber, getCountryCallingCode } from "react-phone-number-input";
import Fade from "@material-ui/core/Fade";
import { store } from "../../Services";
import { CheckAccount, getUserCountry, IsSocialSignupFrom } from "../../Services/Actions/userActions";
import { SendOTP } from "../../Services/Actions/LoginSignupActions";
import { Link } from "react-router-dom";
import ContactBar from "./ContactBar";
import ProgressBar from "./ProgressBar";
import { withRouter } from "../../Utils/withRouter";
import { connect } from "react-redux";
import swal from "sweetalert";
import { LINKEDIN_WEB_SIGNUP_URL } from "../LinkedIn/parameters";
import { REACT_APP_GOOGLE_APP_ID } from "../../Utils/Common";
import GoogleAuth from "./SocialAuth/GoogleAuth";

const { getName } = require("country-list");

const initialState = {
  checked: false,
  terms_conditions: false,
  signUpWith: "email",
  email: "",
  password: "",
  promotional_code: "",
  affiliate_code: "",
  click: false,
  openForgotModal: false,
  firstname: "",
  lastname: "",
  company: "",
  phone: "",
  call_number: "",
  prefix: "",
  country_code: "",
  country: "",
  signupWithFacebook: false,
  signUpWithGoogle: false,
  signUpWithLinkedIn: false,
  accessState: "",
  isSocialSignUp: false,
};

class Signup extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      validators: {
        Phone: {
          required: true,
          message: "Please enter a valid number.",
          rule: (val, params, validatorP) => !!(val && val.length <= 9),
        },
      },
      messages: {
        in: "Password and Confirm Password must be same!",
      },
    });
    this.signupValidator = new SimpleReactValidator();
    this.state = initialState;
  }

  componentDidUpdate(prevProps) {
    const {
      web_registration: { social_signup, is_social_signup_from },
      selectSubTab,
    } = this.props;
    if (is_social_signup_from && is_social_signup_from?.from === "nxa-page") {
      this.setState(
        {
          signUpWith: is_social_signup_from?.with,
        },
        () => {
          selectSubTab("custom-sign-up");
          this._getSocialSignupDetails(social_signup);
          IsSocialSignupFrom({}, store.dispatch);
        }
      );
    }
  }

  _getSocialSignupDetails = (social_signup) => {
    console.log({ social_signup });
    const { name, email } = social_signup;
    if (name) {
      const [firstname, lastname] = name && name.split(" ");
      this.setState({ firstname, lastname, email, isSocialSignUp: true }, () => {
        const { signUpWith } = this.state;
        const wrapper = document.createElement("div");
        const text = `Thanks for choosing ${signUpWith.charAt(0).toUpperCase() + signUpWith.slice(1)} Sign up. <br /> Please fill the  remaining mandatory fields.`;
        wrapper.innerHTML = `<p class="text-center">${text}</p>`;
        swal({
          content: wrapper,
          icon: "success",
          title: "Success!",
        });
      });
    }
  };

  numberChange = (number) => {
    let phoneNumber = number ? parsePhoneNumber(number.toString()) : "";
    let prefix = "";
    let country_code = "";
    let call_number = "";
    let country = "";
    if (phoneNumber) {
      if (phoneNumber.nationalNumber && phoneNumber.country) {
        call_number = phoneNumber.nationalNumber.replace(/^0+/, "");
        country_code = phoneNumber.country;
        country = country_code;
        // country = getName(country_code);
        if (getCountryCallingCode(phoneNumber.country)) {
          prefix = getCountryCallingCode(phoneNumber.country);
        }
      }
    }
    this.setState({ call_number, prefix, country_code, country });
  };

  onEnterPress = (e) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };

  handleSubmit = async (e, subTab) => {
    e.preventDefault();
    const validator = this.validator;
    const { firstname, lastname, company, email, call_number, password, prefix, country, promotional_code, affiliate_code } = this.state;
    const data = {
      firstname,
      lastname,
      email,
      password,
      phone: call_number,
      prefix,
      country,
      company,
      promotional_code,
      affiliate_code,
    };
    if (validator.allValid()) {
      this.setState({ click: true });
      let signup = await CheckAccount(data, store.dispatch);
      if (signup.code === 1) {
        this.setState({ initialState });
        let {
          account_details: { phone, prefix },
        } = this.props;
        if (!phone) {
          this.props.navigate("/");
          return false;
        }
        prefix = prefix.includes("+") ? prefix : `+${prefix}`;
        // phone = `${prefix}${phone}`;
        this.props.navigate("/verify-number");
        let response = await SendOTP({ Number: phone, prefix }, store.dispatch);
        if (response === 1) {
          this.props.navigate("/verify-number");
        } else {
          this.setState({ click: false });
          return false;
        }
      } else {
        this.setState({ click: false });
      }
    } else {
      validator.showMessages();
      this.forceUpdate();
    }
  };

  responseGoogle = (data) => {
    if (this.signupValidator.allValid()) {
      const { selectSubTab } = this.props;
      let profileObj = data;
      if (profileObj) {
        this.setState(
          {
            signUpWith: "google",
          },
          () => {
            this._getSocialSignupDetails(profileObj);
            selectSubTab("custom-sign-up");
          }
        );
      }
    } else {
      this.signupValidator.showMessages();
      this.forceUpdate();
    }
  };

  _facebookLogin = () => {
    if (this.signupValidator.allValid()) {
      window.FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          this.getCurrentFacebookUserInfo(response);
        } else {
          window.FB.login(
            (response) => {
              if (response.authResponse) {
                this.getCurrentFacebookUserInfo(response);
              }
            },
            { scope: "email" }
          );
        }
      });
    } else {
      this.signupValidator.showMessages();
      this.forceUpdate();
    }
  };

  getCurrentFacebookUserInfo(res) {
    console.log({ res });
    window.FB.api("/me?fields=id,email,name", (userInfo) => {
      console.log({ userInfo });
      this.setState(
        {
          signUpWith: "facebook",
        },
        () => {
          const { selectSubTab } = this.props;
          this._getSocialSignupDetails(userInfo);
          selectSubTab("custom-sign-up");
        }
      );
      if (Object.keys(userInfo).length) {
        window.FB.logout((response) => {
          console.log("logout response", response);
          // Person is now logged out
        });
      }
    });
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  SelectedSignUpHanlder = () => {
    const { signUpWith } = this.state;
    const { selectSubTab } = this.props;
    if (this.signupValidator.allValid()) {
      if (signUpWith === "email") {
        selectSubTab("custom-sign-up");
      }
    } else {
      this.signupValidator.showMessages();
      this.forceUpdate();
    }
  };

  SelectedSign = () => {
    const { selectSubTab } = this.props;
    selectSubTab("custom-sign-up");
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get("email");
    if (email) {
      this.setState({ receivedEmail: email, terms_conditions: true });
      this.SelectedSign();
    }

    getUserCountry(store.dispatch);
    this.setState({
      accessState: window.randomString(15, "aA#"),
      checked: true,
    });
  }

  render() {
    //prettier-ignore
    const {checked,signUpWith, terms_conditions,click,firstname,lastname,company,phone,email,password,call_number, promotional_code,affiliate_code,isSocialSignUp } = this.state;
    const { subTab, get_user_country: { country_code = "ZA" } = {} } = this.props;
    this.validator.purgeFields();

    return (
      <Fade in={checked} timeout={500}>
        <div className="sign-up-step-bar pb-5">
          <div className="container">
            <ContactBar />
            <ProgressBar active="sign-up" />
            <div className="row">
              <div className={`col-md-${subTab === "" ? "4" : "6"}`}>
                <div className={`sign-left-part sign-left-img pt-5 ${subTab === "" ? "" : "sign-left-first-page"}`}>
                  <img className="w-80" src="./../assets/images/signup/3.png" alt="1" />
                </div>
                {subTab !== "" && (
                  <div className="middal left-bottom">
                    <li>
                      <i className="fas fa-circle"></i>
                      Instant provisioning, No waiting, just add users and get your local business numbers instantly.
                    </li>
                    <li>
                      <i className="fas fa-circle"></i>
                      14-days trial available.
                    </li>
                    <li>
                      <i className="fas fa-circle"></i>
                      Work From Anywhere Connect from any device, anywhere in the world.
                    </li>
                  </div>
                )}
              </div>
              {subTab === "" && (
                <div className="col-md-4">
                  <div className="middal  pt-5">
                    <p className="text--gray-3b6e font-17  ">
                      Instant provisioning, No waiting, just add users and get your local business numbers instantly.
                      <br></br> <br></br>14-days trial available. <br></br>
                      <br></br> Work From Anywhere Connect from any device, anywhere in the world.
                    </p>
                  </div>
                </div>
              )}
              {subTab === "" && (
                <div className="col-md-4">
                  <div className="sign-right-part pt-5">
                    <button
                      type="button"
                      className={`btn btn-social-signup btn-block form-group" ${signUpWith === "email" ? "active-bar" : ""}`}
                      onClick={() =>
                        this.setState({
                          signUpWith: "email",
                        })
                      }
                    >
                      <img src="./../assets/images/signup/mail.png" alt="1" />
                      <span className="color-9c9c font-15">Sign Up with Email</span>
                    </button>
                    <button
                      type="button"
                      className={`btn btn-social-signup btn-block form-group" ${signUpWith === "google" ? "active-bar" : ""}`}
                      onClick={() =>
                        this.setState({
                          signUpWith: "google",
                        })
                      }
                    >
                      <img src="./../assets/images/signup/google-circle.png" alt="1" />
                      <span className="color-9c9c font-15">Sign Up with Google</span>
                    </button>
                    <button
                      type="button"
                      className={`btn btn-social-signup btn-block form-group" ${signUpWith === "linkedin" ? "active-bar" : ""}`}
                      onClick={() =>
                        this.setState({
                          signUpWith: "linkedin",
                        })
                      }
                    >
                      <img src="./../assets/images/signup/linkedin-circle.png" alt="1" />
                      <span className="color-9c9c font-15">Sign Up with Linkedin</span>
                    </button>
                    <button
                      onClick={() =>
                        this.setState({
                          signUpWith: "facebook",
                        })
                      }
                      type="button"
                      className={`btn btn-social-signup btn-block form-group" ${signUpWith === "facebook" ? "active-bar" : ""}`}
                    >
                      <img src="./../assets/images/signup/facebook-circle.png" alt="1" />
                      <span className="color-9c9c font-15">Sign Up with Facebook</span>
                    </button>
                    <div className="form-check text-center form-group pl-0">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        value={terms_conditions ? "checked" : ""}
                        name="terms_conditions"
                        checked={terms_conditions ? "checked" : ""}
                        onChange={() =>
                          this.setState({
                            terms_conditions: !terms_conditions,
                          })
                        }
                      />
                      <label className="form-check-label font--12 text--black-191b fw-500" for="exampleCheck1">
                        By signing up, you agree to our{" "}
                        <Link to="/terms" className="color-7414 fw-500">
                          Terms
                        </Link>
                        &nbsp; & &nbsp;
                        <Link to="/privacy" className="color-7414 fw-500">
                          Privacy Policy
                        </Link>
                      </label>
                      {this.signupValidator.message("Terms & Privacy Policy", terms_conditions, "required|accepted")}
                    </div>
                    <div className="d-flex justify-content-center">
                      <button type="button" className="btn btn-back-signup mr-3" onClick={() => this.props.navigate("/")}>
                        Back
                      </button>
                      {signUpWith === "email" && (
                        <button type="button" className="btn btn-start-with" onClick={this.SelectedSignUpHanlder}>
                          Start
                        </button>
                      )}
                      {signUpWith === "facebook" && (
                        <button type="button" className="btn btn-start-with" onClick={this._facebookLogin}>
                          Start
                        </button>
                      )}
                      {signUpWith === "google" && (
                        <GoogleAuth
                          renderComponent={
                            <button type="button" className="btn btn-start-with">
                              Start
                            </button>
                          }
                          googleCallBack={this.responseGoogle}
                        />
                        // <GoogleLogin
                        //   clientId={REACT_APP_GOOGLE_APP_ID}
                        //   render={(renderProps) => {
                        //     return (
                        //       <button
                        //         type="button"
                        //         className="btn btn-start-with"
                        //         onClick={() => {
                        //           console.log("clicking", renderProps);
                        //           renderProps.onClick();
                        //         }}>
                        //         Start
                        //       </button>
                        //     );
                        //   }}
                        //   buttonText="Login"
                        //   onSuccess={this.responseGoogle}
                        //   onFailure={this.responseGoogle}
                        //   cookiePolicy={"single_host_origin"}
                        // />
                      )}

                      {signUpWith === "linkedin" && (
                        <a
                          href={LINKEDIN_WEB_SIGNUP_URL}
                          className="btn btn-start-with"
                          onClick={(e) => {
                            if (!this.signupValidator.allValid()) {
                              this.signupValidator.showMessages();
                              this.forceUpdate();
                              e.stopPropagation();
                              e.preventDefault();
                            }
                          }}
                        >
                          Start
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {subTab === "custom-sign-up" && (
                <Fade in={true} timeout={500}>
                  <div className="col-md-6">
                    <form onSubmit={(e) => this.handleSubmit(e, subTab)}>
                      <div className="services-right-box sign-right-part sign-up-form-page ">
                        <h2 className="font--28 mb-3 text--black-191b fw--400 text-center">Get Started Now!</h2>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="color2F4B">First Name*</label>
                              <input type="text" className="form-control" name="firstname" value={firstname} onChange={this.onChangeHandler} maxLength="20" />
                              {this.validator.message("First Name", firstname, "required|string|max:20,string")}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="color2F4B">Last Name*</label>
                              <input type="text" className="form-control" name="lastname" value={lastname} onChange={this.onChangeHandler} maxLength="20" />
                              {this.validator.message("Last Name", lastname, "required|string|max:20,string")}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="color2F4B">Business Name*</label>
                              <input type="text" className="form-control" name="company" value={company} onChange={this.onChangeHandler} maxLength="20" />
                              {this.validator.message("Company", company, "required|min:2,string|max:50,string")}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="color2F4B">Work Email*</label>
                              <input type="text" className="form-control" name="email" value={this.state.receivedEmail} onChange={this.onChangeHandler} />
                              {this.validator.message("Email", email, "required|email|min:10,string|max:60,string")}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="color2F4B">Mobile Number*</label>
                            <div className="input-group mb-3">
                              <PhoneInput international={true} addInternationalOption={false} withCountryCallingCode={true} name="phone" value={phone} onChange={(phone) => this.numberChange(phone)} defaultCountry="ZA" limitMaxLength={11} />
                              {this.validator.message("Phone", call_number, "required|Phone")}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="color2F4B">Password*</label>
                              <input type="password" className="form-control" name="password" value={password} onChange={this.onChangeHandler} maxLength="20" />
                              {this.validator.message("Password", password, "required")}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="color2F4B">Affiliate Code</label>
                            <div className="input-group mb-3">
                              <input type="text" className="form-control" name="affiliate_code" value={affiliate_code} onChange={this.onChangeHandler} maxLength="20" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="color2F4B">Promotional Code</label>
                              <input type="text" className="form-control" name="promotional_code" value={promotional_code} onChange={this.onChangeHandler} maxLength="20" />
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-orange  btn-block"
                          // onClick={this.handleSubmit}
                          // onKeyPress={this.onEnterPress}
                        >
                          {isSocialSignUp ? "Continue" : click ? "Please wait..." : "Sign up Now"}
                        </button>
                        <div className="or or-2">
                          <hr className="w-100"></hr>
                          <span className="or-inner">OR</span>
                        </div>
                        <div className="row row-google-btn">
                          <div className="col-md-4 sign-up-socical-btn-div">
                            <GoogleAuth
                              renderComponent={
                                <div
                                  className="social-btn"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <img src="./../assets/images/home-page-img/google.svg" alt="1" />
                                  Sign Up with G Suite
                                </div>
                              }
                              googleCallBack={this.responseGoogle}
                            />
                          </div>
                          <div className="col-md-4 sign-up-socical-btn-div">
                            <a href={LINKEDIN_WEB_SIGNUP_URL} className="d-flex align-items-center social-btn">
                              <img src="./../assets/images/home-page-img/link.png" alt="1" />
                              Sign Up with LinkedIn
                            </a>
                          </div>
                          <div className="col-md-4 sign-up-socical-btn-div">
                            <div
                              className="social-btn"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={this._facebookLogin}
                            >
                              <img src="./../assets/images/home-page-img/face.svg" alt="1" />
                              Sign Up with Facebook
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </Fade>
              )}
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    web_registration: state?.users ?? [],
    account_details: state?.users?.check_account ?? {},
    get_user_country: state?.users?.get_user_country ?? {},
  };
};

export default connect(mapStateToProps)(withRouter(Signup));
