import React, { Component } from "react";
import { Link } from "react-router-dom";

class Privacy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div className="privacy-page-banner">
          <h1>Privacy Policy</h1>
          <div className="privacy-respect-box">
            <div className="container">
              <div className="privacy-respect-bx-inner">
                <div className="privacy-left">
                  <h2>
                    We respect your <br />
                    privacy, here's how
                  </h2>
                  <p>
                    This document is to explain how we handle data
                    <br /> and information collected from you while using
                    <br /> our website and/or our services.
                  </p>
                  <Link to="#" className="read-more-privacy">
                    Read More
                  </Link>
                </div>
                <div className="privacy-right">
                  <img src="./../assets/images/privacy-banner-img.png" alt="1" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="privacy-content">
          <div className="container">
            <h5>
              BY ACCESSING AND USING THIS WEBSITE AND/OR OUR SERVICES, THE USER IS PROVIDING HIS/HER EXPRESS AND VOLUNTARY CONSENT TO THE PROCESSING
              OF THEIR PERSONAL INFORMATION BY NXATEL ON THE BASIS SET OUT IN THIS PRIVACY NOTICE. IF THE USER DOES NOT
              CONSENT, THE USER MUST IMMEDIATELY STOP ACCESSING AND/OR USING THIS WEBSITE AND OUR SERVICES.
            </h5>
            <p>
              This privacy notice for NxaTel Pty Ltd (<b> 'we', 'us', or our'</b>), describes how and why we might collect, store, use, and/or share (
              <b> 'process'</b>) your information when you use our services (<b>'Services'</b>), such as when you: <br />
              Visit our website at https://nxa.tel/privacy, or any website of ours that links to this privacy notice <br />
              Download and use our mobile application (NxaTel), or any other application of ours that links to this privacy notice <br />
              Engage with us in other related ways, including any sales, marketing, or events
              <br />
              <b>Questions or concerns?</b> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree
              with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at
              privacy@nxa.tel. <br />
              <h5>SUMMARY OF KEY POINTS</h5>
              <b>
                This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the
                link following each key point or by using our table of contents below to find the section you are looking for.
              </b>
              <br />
              <b> What personal information do we process?</b> When you visit, use, or navigate our Services, we may process personal information
              depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about
              personal information you disclose to us. <br />
              <b> Do we process any sensitive personal information? </b>We do not process sensitive personal information. <br />
              <b> Do we receive any information from third parties? </b>We do not receive any information from third parties.
              <br />
              <b> How do we process your information?</b> We process your information to provide, improve, and administer our Services, communicate
              with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your
              consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.
              <br />
              <b> In what situations and with which parties do we share personal information? </b>We may share information in specific situations and
              with specific third parties. Learn more about when and with whom we share your personal information. <br />
              <b> How do we keep your information safe?</b> We have organisational and technical processes and procedures in place to protect your
              personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be
              100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to
              defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information
              safe.
              <br />
              <b> What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain
              rights regarding your personal information. Learn more about your privacy rights. <br />
              <b> How do you exercise your rights?</b> The easiest way to exercise your rights is by visiting app.nxa.tel, or by contacting us. We
              will consider and act upon any request in accordance with applicable data protection laws. <br />
              Want to learn more about what we do with any information we collect? Review the privacy notice in full. <br /> <br />
            </p>
            <h6>
              <b> TABLE OF CONTENTS</b>
            </h6>
            <ul className="table-content-list pl-3">
              <li>WHAT INFORMATION DO WE COLLECT?</li>
              <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
              <li>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</li>
              <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
              <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
              <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
              <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
              <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
              <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
              <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
              <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
              <li>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
              <li>DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</li>
              <li>MOBILE APPLICATION USE</li>
              <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
              <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
              <li>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
            </ul>
            <h6>
              <b> 1. WHAT INFORMATION DO WE COLLECT?</b>
            </h6>
            <p>
              <b> Personal information you disclose to us</b>
            </p>
            <p>
              <b> In Short: </b>
              We collect personal information that you provide to us.
            </p>
            <p>
              We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining
              information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
            </p>
            <p>
              Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and
              the Services, the choices you make, and the products and features you use. The personal information we collect may include the
              following:
            </p>
            <ul className="disc-list pl-3">
              <li> names </li>
              <li> phone numbers </li>
              <li> email addresses</li>
              <li> mailing addresses</li>
              <li> job titles</li>
              <li> passwords</li>
              <li> usernames</li>
              <li> contact preferences</li>
              <li> contact or authentication data</li>
              <li> billing addresses</li>
              <li> debit/credit card numbers</li>
              <li> contact list</li>
              <li> address</li>
              <li> copies of id which is stored on api.nxa.tel</li>
            </ul>
            <p>
              <b> Sensitive Information.</b> We do not process sensitive information.
            </p>
            <p>
              <b> Payment Data.</b> We may collect data necessary to process your payment if you make purchases, such as your payment instrument
              number, and the security code associated with your payment instrument. All payment data is stored by PayStack and Ozow. You may find
              their privacy notice link(s) here: https://paystack.com/za/terms?q=/terms and https://ozow.com/privacy-policy.
              <br />
              <b> Social Media Login Data.</b> We may provide you with the option to register with us using your existing social media account
              details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the
              information described in the section called HOW DO WE HANDLE YOUR SOCIAL LOGINS?' below.
              <br />
              <b> Application Data.</b> If you use our application(s), we also may collect the following information if you choose to provide us with
              access or permission:
            </p>
            <ul className="disc-list pl-3  mb-2">
              <li className="mb-3">
                Geolocation Information. We may request access or permission to track location- based information from your mobile device, either
                continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our
                access or permissions, you may do so in your devices settings.
              </li>
              <li className="mb-3">
                Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile devices
                bluetooth, calendar, camera, contacts, microphone, reminders, storage, social media accounts, sms messages, and other features. If you
                wish to change our access or permissions, you may do so in your devices settings.
              </li>
              <li className="mb-3">
                Mobile Device Data. We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating
                system, version information and system configuration information, device and application identification numbers, browser type and
                version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you
                are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile
                devices operating system or platform, the type of mobile device you use, your mobile devices unique device ID, and information about
                the features of our application(s) you accessed.
              </li>
              <li className="mb-3">
                Mobile Device Contacts. We request permission to access and add contacts information (such as team contacts, personal and business
                contact ). We collect and store contacts data to https://api.nxa.tel/ which is used to facilitate the processing of business functions
                within the application which allows a shared contact repository between all team members to promote faster information exchange, thus
                bettering collaboration and consolidates all the information gathered by various team members interacting with the customer. We
                however do not share any personal contacts with any team member nor any third party.
              </li>
              <li>
                Push Notifications. We may request to send you push notifications regarding your account or certain features of the application(s). If
                you wish to opt out from receiving these types of communications, you may turn them off in your devices settings.
              </li>
            </ul>
            <p>
              This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our
              internal analytics and reporting purposes.
              <br />
              All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such
              personal information.
            </p>
            <p>
              <b> Information automatically collected</b> <br />
              <b> In Short:</b> Some information -- such as your Internet Protocol (IP) address and/or browser and device characteristics -- is
              collected automatically when you visit our Services. <br />
              We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser
              and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about
              how and when you use our Services, and other technical information. <br />
              This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and
              reporting purposes. <br />
              Like many businesses, we also collect information through cookies and similar technologies. <br />
              The information we collect includes:
            </p>
            <ul className="disc-list pl-3">
              <li>
                Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically
                collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may
                include your IP address, device information, browser type, and settings and information about your activity in the Services (such as
                the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features
                you use), device event information (such as system activity, error reports (sometimes called crash dumps'), and hardware settings).
              </li>
              <li>
                Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the
                Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and
                application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating
                system, and system configuration information.
              </li>
              <li>
                Location Data. We collect location data such as information about your devices location, which can be either precise or imprecise. How
                much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS
                and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of
                allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your
                device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
              </li>
              <li>
                Selfie picture. This picture will be used to identify the customer for RICA and FICA purposes. The document will be processed and
                stored by api.nxa.tel
              </li>
            </ul>
            <h6>
              <b> 2. HOW DO WE PROCESS YOUR INFORMATION?</b>
            </h6>
            <p>
              <b>In Short:</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and
              fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
              <br />
              <b>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</b>
            </p>
            <ul className="disc-list pl-3">
              <li>
                <b>To facilitate account creation and authentication and otherwise manage user accounts.</b> We may process your information so you
                can create and log in to your account, as well as keep your account in working order.
              </li>
              <li>
                <b> To deliver and facilitate delivery of services to the user.</b> We may process your information to provide you with the requested
                service.
              </li>
              <li>
                <b> To respond to user inquiries/offer support to users.</b> We may process your information to respond to your inquiries and solve
                any potential issues you might have with the requested service.
              </li>
              <li>
                <b> To send administrative information to you.</b> We may process your information to send you details about our products and
                services, changes to our terms and policies, and other similar information.
              </li>
              <li>
                <b> To fulfil and manage your orders.</b> We may process your information to fulfil and manage your orders, payments, returns, and
                exchanges made through the Services.
              </li>
              <li>
                <b> To enable user to-user communications.</b> We may process your information if you choose to use any of our offerings that allow
                for communication with another user.
              </li>
              <li>
                <b> To request feedback. </b>We may process your information when necessary to request feedback and to contact you about your use of
                our Services.
              </li>
              <li>
                <b> To send you marketing and promotional communications.</b> We may process the personal information you send to us for our marketing
                purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more
                information, see 'WHAT ARE YOUR PRIVACY RIGHTS?' below.
              </li>
              <li>
                <b> To deliver targeted advertising to you. </b>We may process your information to develop and display personalised content and
                advertising tailored to your interests, location, and more.
              </li>
              <li>
                <b> To protect our Services.</b> We may process your information as part of our efforts to keep our Services safe and secure,
                including fraud monitoring and prevention.
              </li>
              <li>
                <b> To identify usage trends.</b> We may process information about how you use our Services to better understand how they are being
                used so we can improve them.
              </li>
              <li>
                <b> To determine the effectiveness of our marketing and promotional campaigns. </b>We may process your information to better
                understand how to provide marketing and promotional campaigns that are most relevant to you.
              </li>
              <li>
                <b> To save or protect an individual s vital interest. </b>We may process your information when necessary to save or protect an
                individuals vital interest, such as to prevent harm.
              </li>
            </ul>
            <h6>
              <b> 3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</b>
            </h6>
            <p>
              <b> In Short:</b> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal
              basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil
              our contractual obligations, to protect your rights, or to fulfil our legitimate business interests. <br />
              <b> If you are located in the EU or UK, this section applies to you.</b> <br />
              The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process
              your personal information. As such, we may rely on the following legal bases to process your personal information:
              <br />
            </p>
            <ul className="disc-list pl-3">
              <li>
                <b> Consent.</b> We may process your information if you have given us permission (i.e. consent) to use your personal information for a
                specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent.
              </li>
              <li>
                <b> Performance of a Contract.</b> We may process your personal information when we believe it is necessary to fulfil our contractual
                obligations to you, including providing our Services or at your request prior to entering into a contract with you.
              </li>
              <li>
                <b> Legitimate Interests.</b> We may process your information when we believe it is reasonably necessary to achieve our legitimate
                business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process
                your personal information for some of the purposes described in order to:
                <ul className="pl-5 list-style-none">
                  <li>- Send users information about special offers and discounts on our products and services </li>
                  <li>- Develop and display personalised and relevant advertising content for our users </li>
                  <li>- Analyse how our Services are used so we can improve them to engage and retain users </li>
                  <li>- Support our marketing activities </li>
                  <li>- Diagnose problems and/or prevent fraudulent activities </li>
                  <li>- Understand how our users use our products and services so we can improve user experience</li>
                </ul>
              </li>
              <li>
                <b> Legal Obligations.</b> We may process your information where we believe it is necessary for compliance with our legal obligations,
                such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your
                information as evidence in litigation in which we are involved.
              </li>
              <li>
                <b> Vital Interests.</b> We may process your information where we believe it is necessary to protect your vital interests or the vital
                interests of a third party, such as situations involving potential threats to the safety of any person.
              </li>
            </ul>
            <p>
              <b> If you are located in Canada, this section applies to you.</b> <br />
              We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a
              specific purpose, or in situations where your permission can be inferred (i.e. implied consent). You can withdraw your consent at any
              time.
              <br />
              In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including,
              for example:
            </p>
            <ul className="disc-list pl-3">
              <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way </li>
              <li>For investigations and fraud detection and prevention </li>
              <li>For business transactions provided certain conditions are met </li>
              <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim </li>
              <li>For identifying injured, ill, or deceased persons and communicating with next of kin </li>
              <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse </li>
              <li>
                If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and
                the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada
                or a province
              </li>
              <li>
                If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records
              </li>
              <li>
                If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with
                the purposes for which the information was produced
              </li>
              <li>If the collection is solely for journalistic, artistic, or literary purposes </li>
              <li>If the information is publicly available and is specified by the regulations</li>
            </ul>
            <h6>
              <b> 4.WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</b>
            </h6>
            <p>
              <b> In Short:</b> We may share information in specific situations described in this section and/or with the following third parties.
              <br />
              We may need to share your personal information in the following situations:
            </p>
            <ul className="disc-list pl-3">
              <li>
                <b> Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale
                of company assets, financing, or acquisition of all or a portion of our business to another company.
              </li>
              <li>
                <b> When we use Google Maps Platform APIs.</b> We may share your information with certain Google Maps Platform APIs (e.g. Google Maps
                API, Places API). We use certain Google Maps Platform APIs to retrieve certain information when you make location-specific requests.
                This includes: User address; and other similar information. A full list of what we use information for can be found in this section
                and in the previous section titled HOW DO WE PROCESS YOUR INFORMATION?' We obtain and store on your device ('cache') your location for
                three (3) months. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.
                The Google Maps Platform APIs that we use store and access cookies and other information on your devices. If you are a user currently
                in the European Economic Area (EU countries, Iceland, Liechtenstein, and Norway) or the United Kingdom, please take a look at our
                Cookie Notice.
              </li>
              <li>
                <b> Affiliates.</b> We may share your information with our affiliates, in which case we will require those affiliates to honour this
                privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control
                or that are under common control with us.
              </li>
              <li>
                <b> Business Partners.</b> We may share your information with our business partners to offer you certain products, services, or
                promotions.
              </li>
              <li>
                <b> Other Users.</b> When you share personal information (for example, by posting comments, contributions, or other content to the
                Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be
                publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register for our
                Services through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and
                descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within
                our Services, and view your profile.
              </li>
            </ul>
            <h6>
              <b> 5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</b>
            </h6>
            <p>
              <b> In Short:</b> We may use cookies and other tracking technologies to collect and store your information. <br />
              We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information
              about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
            </p>
            <h6>
              <b> 6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</b>
            </h6>
            <p>
              <b> In Short:</b> If you choose to register or log in to our Services using a social media account, we may have access to certain
              information about you.
            </p>
            <p>
              Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or
              Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The
              profile information we receive may vary depending on the social media provider concerned, but will often include your name, email
              address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.
            </p>
            <p>
              We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear
              to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal
              information by your third- party social media provider. We recommend that you review their privacy notice to understand how they
              collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.
            </p>
            <h6>
              <b> 7. HOW LONG DO WE KEEP YOUR INFORMATION?</b>
            </h6>
            <p>
              <b> In Short:</b> We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless
              otherwise required by law.
            </p>
            <p>
              We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a
              longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for longer than twelve (12) months past the start of the idle period of the
              user s account.
            </p>
            <p>
              When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such
              information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we
              will securely store your personal information and isolate it from any further processing until deletion is possible.
            </p>
            <h6>
              <b> 8.HOW DO WE KEEP YOUR INFORMATION SAFE?</b>
            </h6>
            <p>
              <b> In Short:</b> We aim to protect your personal information through a system of organisational and technical security measures.
            </p>
            <p>
              We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any
              personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over
              the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
              cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or
              modify your information. Although we will do our best to protect your personal information, transmission of personal information to and
              from our Services is at your own risk. You should only access the Services within a secure environment.
            </p>
            <h6>
              <b> 9. DO WE COLLECT INFORMATION FROM MINORS?</b>
            </h6>
            <p>
              <b> In Short:</b> We do not knowingly collect data from or market to children under 18 years of age.
            </p>
            <p>
              We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at
              least 18 or that you are the parent or guardian of such a minor and consent to such minor dependents use of the Services. If we learn
              that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable
              measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age
              18, please contact us at nqaba@nxa.technology.
            </p>
            <h6>
              <b> 10. WHAT ARE YOUR PRIVACY RIGHTS?</b>
            </h6>
            <p>
              <b> In Short:</b> In some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have
              rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at
              any time. <br />
            </p>
            <p>
              In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may
              include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii)
              to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated
              decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can
              make such a request by contacting us by using the contact details provided in the section HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
              below.
            </p>
            <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
            <p>
              If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to
              complain to your Member State data protection authority or UK data protection authority.
            </p>
            <p> If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner. </p>
            <p>
              <b> Withdrawing your consent:</b> If we are relying on your consent to process your personal information, which may be express and/or
              implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent
              at any time by contacting us by using the contact details provided in the section HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below or
              updating your preferences.
            </p>
            <p>
              However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows,
              will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
            </p>
            <p>
              <b> Opting out of marketing and promotional communications:</b> You can unsubscribe from our marketing and promotional communications at
              any time by clicking on the unsubscribe link in the emails that we send, replying STOP or UNSUBSCRIBE to the SMS messages that we send,
              or by contacting us using the details provided in the section HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed
              from the marketing lists. However, we may still communicate with you for example, to send you service-related messages that are
              necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
            </p>
            <p>
              <b>Account Information</b>
            </p>
            If you would at any time like to review or change the information in your account or terminate your account, you can:
            <ul className="disc-list pl-3">
              <li>Log in to your account settings and update your user account.</li>
              <li>Contact us using the contact information provided.</li>
            </ul>
            <p>
              Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases.
              However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce
              our legal terms and/or comply with applicable legal requirements.
            </p>
            <p>
              <b>Cookies and similar technologies:</b> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose
              to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect
              certain features or services of our Services.
            </p>
            <p>If you have questions or comments about your privacy rights, you may email us at privacy@nxa.tel.</p>
            <h6>
              <b> 11. CONTROLS FOR DO-NOT-TRACK FEATURES</b>
            </h6>
            <p>
              Most web browsers and some mobile operating systems and mobile applications include a Do- Not-Track ('DNT') feature or setting you can
              activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage
              no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to
              DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for
              online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this
              privacy notice.
            </p>
            <h6>
              <b> 12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</b>
            </h6>
            <p>
              <b> In Short:</b> If you are a resident of Colorado, you are granted specific rights regarding access to your personal information.
            </p>
            <p>
              <b>What categories of personal information do we collect?</b> <br />
              We have collected the following categories of personal information in the past twelve (12) months:
            </p>
            <table className="table table-bordered privacy-table">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Examples</th>
                  <th>Collected</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>A. Identifiers</td>
                  <td>
                    Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online
                    identifier, Internet Protocol address, email address, and account name
                  </td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>B. Protected classification characteristics under state or federal law</td>
                  <td>Genderand date of birth</td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>C. Commercial information</td>
                  <td>Transaction information, purchase history, financial details, and payment information</td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>D. Biometric information</td>
                  <td>Finger printsand voiceprints</td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>E. Internet or other similar network activity</td>
                  <td>
                    Browsing history, search history, online behaviour, interest data, and interactions with our and other websites, applications,
                    systems, and advertisements
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>F. Geolocation data</td>
                  <td>Device location</td>
                  <td>YES</td>
                </tr>

                <tr>
                  <td>G. Audio, electronic, visual, thermal, olfactory, or similar information</td>
                  <td>Images and audio, video or call recordings created in connection with our business activities </td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>H. Professional or employment-related information</td>
                  <td>
                    Business contact details in order to provide you our Services at a business level or job title, work history, and professional
                    qualifications if you apply for a job with us
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>I. Education Information</td>
                  <td>Student records and directory information</td>
                  <td>NO</td>
                </tr>
              </tbody>
            </table>
            <p>We will use and retain the collected personal information as needed to provide the Services or for:</p>
            <ul className="disc-list pl-3">
              <li>Category A - As long as the user has an account with us</li>
              <li>Category B - As long as the user has an account with us</li>
              <li>Category F - As long as the user has an account with us</li>
              <li>Category G - As long as the user has an account with us</li>
            </ul>
            <p>
              We may also collect other personal information outside of these categories through instances where you interact with us in person,
              online, or by phone or mail in the context of:
            </p>
            <ul className="disc-list pl-3">
              <li>Receiving help through our customer support channels;</li>
              <li>Participation in customer surveys or contests; and</li>
              <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
            </ul>
            <p>
              <b>How do we use and share your personal information?</b> <br />
              Learn about how we use your personal information in the section, 'HOW DO WE PROCESS YOUR INFORMATION'?
            </p>
            <p>
              <b>Will your information be shared with anyone else?</b> <br />
              We may disclose your personal information with our service providers pursuant to a written contract between us and each service
              provider. Learn more about how we disclose personal information to in the section, 'WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
              INFORMATION?' <br />
              We may use your personal information for our own business purposes, such as for undertaking internal research for technological
              development and demonstration. This is not considered to be selling of your personal information. <br />
              We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding
              twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other
              consumers.
            </p>
            <p>
              <b>Colorado Residents</b> <br />
              This section applies only to Colorado residents. Under the Colorado Privacy Act (CPA), you have the rights listed below. However, these
              rights are not absolute, and in certain cases, we may decline your request as permitted by law.
            </p>
            <ul className="disc-list pl-3">
              <li>Right to be informed whether or not we are processing your personal data</li>
              <li>Right to access your personal data</li>
              <li>Right to correct inaccuracies in your personal data</li>
              <li>Right to request deletion of your personal data</li>
              <li>Right to obtain a copy of the personal data you previously shared with us </li>
              <li>
                Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or
                profiling in furtherance of decisions that produce legal or similarly significant effects ('profiling')
              </li>
            </ul>
            <p>To submit a request to exercise these rights described above, please email privacy@nxa.tel or visit app.nxa.tel.</p>
            <p>
              If we decline to take action regarding your request and you wish to appeal our decision, please email us at privacy@nxa.tel. Within
              forty-five (45) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal,
              including a written explanation of the reasons for the decisions.
            </p>
            <h6>
              <b> 13. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</b>
            </h6>
            <p>
              <b>In Short:</b> You may have additional rights based on the country you reside in.
            </p>
            <p>
              <b>Australia</b>
            </p>
            <p>
              We collect and process your personal information under the obligations and conditions set by Australias Privacy Act 1988 (Privacy Act).
            </p>
            <p>
              This privacy notice satisfies the notice requirements defined in the Privacy Act, in particular: what personal information we collect
              from you, from which sources, for which purposes, and other recipients of your personal information.
            </p>
            <p>
              If you do not wish to provide the personal information necessary to fulfill their applicable purpose, it may affect our ability to
              provide our services, in particular:
            </p>
            <ul className="disc-list pl-3">
              <li> offer you the products or services that you want</li>
              <li> respond to or help with your requests</li>
              <li> manage your account with us </li>
              <li> confirm your identity and protect your account</li>
            </ul>
            <p>
              At any time, you have the right to request access to or correction of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?'
            </p>
            <p>
              If you believe we are unlawfully processing your personal information, you have the right to submit a complaint about a breach of the
              Australian Privacy Principles to the Office of the Australian Information Commissioner.
            </p>
            <p>
              <b>Republic of South Africa</b>
            </p>
            <p>
              At any time, you have the right to request access to or correction of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?'
            </p>
            <p>
              If you are unsatisfied with the manner in which we address any complaint with regard to our processing of personal information, you can
              contact the office of the regulator, the details of which are:
            </p>
            <p>
              The Information Regulator (South Africa) <br /> General enquiries: enquiries@inforegulator.org.za <br /> Complaints (complete POPIA/PAIA
              form 5): PAIAComplaints@inforegulator.org.za & <br />
              POPIAComplaints@inforegulator.org.za
            </p>
            <h6>
              <b>14. MOBILE APPLICATION USE</b>
            </h6>
            <p>
              Features and functionality. In order for some features to function properly, our Products may access and use Personal Data you provided
              to third parties, including personal data belonging to other people. Such data will not reach us and will not be processed by us. In
              particular, our Product for desktop operating systems (Windows, Mac, Linux) needs the data contained in your Google Contacts lists to be
              able to show it to you in its respective contact list and call history sections. Because of this, during the process of adding the
              Google Contacts Service to the Product, you will be prompted to give NxaTel permission to see, download, edit and permanently delete
              Google Contact lists. The Product will perform all these actions only on your device and only according to your respective actions. The
              permission will allow you to see, call and manage your contacts when you use the Product. None of the Google Contacts data will be
              accessed by us or sent to us in any form, and we or our representatives will access no such data in any way. If the Product does not
              have permission to use the Google Contacts data, it will be unable to display your contact information and allow you to make calls to
              people in your Google Contacts list. Content. We collect the content of your files and communications when necessary to provide you with
              the services you use. For example, suppose you receive a message using a NxaTel- hosted messaging service. In that case, we need to
              collect the content of that message in order to deliver it to your softphone client, display it to you, enable you to reply to it and
              store it for you until you choose to delete it. Examples of this data include the content of instant messages, documents, photos, music
              or videos you file transfer using NxaTel services. When you grant the Contacts permission in our apps, we load your local address book
              into the app in order to provide direct access to your contacts names, phone numbers, and email addresses. We also collect the content
              of messages you send us, such as feedback and product reviews you write or questions or information you provide for customer support.
              Chat sessions with our representatives may be monitored and recorded when you contact us, such as for customer support. Push
              notification. To let you know of incoming calls, chats and other messages, our apps use the notification service on your device. For
              many devices, these services are provided by another company. To tell you who is calling, for example, or to give you the first few
              words of the new chat, our service has to tell the company providing the notification service so that they can provide the notification
              to you. The company providing the notification service on your device will use this information per their terms and privacy policy.
              NxaTel is not responsible for the data collected by the notification service company. If you dont want to use the notification services
              with our applications, turn it off in the settings found in the application or your device.
            </p>
            <h6>
              <b>15. DO WE MAKE UPDATES TO THIS NOTICE?</b>
            </h6>
            <p>
              <b>In Short: </b> Yes, we will update this notice as necessary to stay compliant with relevant laws.
            </p>
            <p>
              We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised date and the updated
              version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by
              prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice
              frequently to be informed of how we are protecting your information.
            </p>
            <h6>
              <b>16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</b>
            </h6>
            <p>
              If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO), Nqaba Dontache, by email at
              <br /> nqaba@nxa.technology, by phone at <br />
              +27711755727, or contact us by post at: <br /> <br />
              NxaTel Pty Ltd Nqaba Dontache <br /> 138 Frikkie De Beer Street <br /> Glen Manor Office Park Menlyn, Pretoria, Gauteng 0063 South
              Africa
            </p>
            <h6>
              <b>17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</b>
            </h6>
            <p>
              Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you,
              change that information, or delete it. To request to review, update, or delete your personal information, please visit: app.nxa.tel.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Privacy;
