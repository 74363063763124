import React, { Component, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";

export const VideoAutoPlay = () => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      videoRef.current.play();
    } else {
      if (videoRef.current.play) {
        videoRef.current.pause();
      }
    }
  }, [isVisible]);

  return (
    <VisibilitySensor onChange={(isVisible) => setIsVisible(isVisible)}>
      <video width="100%" height="100%" controls loop ref={videoRef}>
        <source
          src="./../assets/videos/Nxatel_Meet_Smarter.mp4"
          type="video/mp4"
        />
      </video>
    </VisibilitySensor>
  );
};

class Meet extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <div className="Meet-outer">
          <div className="meet-banner-sec product-meet-banner">
            <div className=" container">
              <div className="col-md-12">
                <div className="font--20 lh--25 text-center w-100  mb--10 text--gray-6971 dm--sans fw--700">
                  The easiest and secured way to host​​
                </div>
                <div className="text--black font--36 lh--42 text-center sf--t-regular fw--700 font--sm-30 mb--30">
                  Virtual Meetings, collaborate and screen share.​​
                </div>
                <div className="d-flex align-items-center gap--15 justify-content-center mb--30">
                  <Link
                    to="/signup"
                    className="btn  bg--red-f037 text--white border--2 border--red-f037 sf--t-medium radius--16-0">
                    Try for 14 days free
                  </Link>
                  <Link
                    to="/pricing"
                    className="btn  bg--transparent text--red-f037 border--2 border--red-f037 sf--t-medium radius--16-0 hover-text--red-f037">
                    See Pricing
                  </Link>
                </div>
                <img
                  src="./../assets/images/product-meet-banner-img.png"
                  alt="1"
                  className="w-60 m-auto d-flex"
                />
              </div>

              <div className="row mt--60">
                <div className="col-md-4">
                  <div>
                    <span className="h--50 w--50 radius--50 bg--orange d-flex align-items-center justify-content-center fw--700 font--20 dm--sans text--white m-auto">
                      1
                    </span>
                    <div className="font--16 lh--25 mt--10 text--gray-7c87 text-center dm--sans">
                      One video solution for<br></br> any​ size team.
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div>
                    <span className="h--50 w--50 radius--50 bg--orange d-flex align-items-center justify-content-center fw--700 font--20 dm--sans text--white m-auto">
                      2
                    </span>
                    <div className="font--16 lh--25 mt--10 text--gray-7c87 text-center dm--sans">
                      Easy to use, and scale​<br></br> with your business.
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div>
                    <span className="h--50 w--50 radius--50 bg--orange d-flex align-items-center justify-content-center fw--700 font--20 dm--sans text--white m-auto">
                      3
                    </span>
                    <div className="font--16 lh--25 mt--10 text--gray-7c87 text-center dm--sans">
                      Most affordable,​<br></br> straightforward pricing.​
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="why-use-nxa-sec why-use-nxa-meet">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text--black font--36 lh--42 text-center sf--t-regular fw--700 font--sm-30 mb--10">
                    Why Teams use NxaMeets by Nxatel
                  </div>
                  <div className="font--20 lh--25 text-center w-100  mb--10 text--gray-6971 dm--sans fw--700">
                    To Host Virtual Meetings​
                  </div>
                  <div className="why-use-nxameet-bx-div">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="why-use-nxameet-box">
                          <div className="why-use-nxameet-box-inner">
                            <span className="why-use-nxameet-box-cir">
                              <img
                                src="./../assets/images/bi_shield-lock-fill.png"
                                alt="1"
                              />
                            </span>
                            <h4>
                              Private and secure​ virtual meetings, Nxatel
                            </h4>
                            <p>
                              {" "}
                              is totally committed to protecting your
                              conversations with multiple layers of security.​
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="why-use-nxameet-box">
                          <div className="why-use-nxameet-box-inner">
                            <span className="why-use-nxameet-box-cir">
                              <img
                                src="./../assets/images/fluent_checkmark-lock-24-filled.png"
                                alt="1"
                              />
                            </span>
                            <h4>Enable waiting rooms</h4>
                            <p>
                              Lock confidential meetings to keep them secure
                              from unexpected attendees. Get notified when
                              someone tries to enter your meeting and let them
                              in only if you want to.​
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="why-use-nxameet-box">
                          <div className="why-use-nxameet-box-inner">
                            <span className="why-use-nxameet-box-cir">
                              <img
                                src="./../assets/images/bi_record-circle.png"
                                alt="1"
                              />
                            </span>
                            <h4>Securely record</h4>
                            <p>
                              {" "}
                              your meetings to the cloud, and store unlimited
                              recordings to your NxaBox storage Account​
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="why-use-nxameet-box">
                          <div className="why-use-nxameet-box-inner">
                            <span className="why-use-nxameet-box-cir">
                              <img
                                src="./../assets/images/fluent_dual-screen-desktop-24-filled.png"
                                alt="1"
                              />
                            </span>
                            <p>
                              Collaboration is simple with Nxatel Screen sharing
                              and​ file sharing in one​
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ------ */}
                    <div className="row">
                      <div className="col-md-3">
                        <div className="why-use-nxameet-box">
                          <div className="why-use-nxameet-box-inner">
                            <span className="why-use-nxameet-box-cir">
                              <img
                                src="./../assets/images/fluent_phone-laptop-20-filled.png"
                                alt="1"
                              />
                            </span>
                            <h4>Join from Any Device </h4>
                            <p>
                              Experience HD audio and video, no download
                              required.​​
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="why-use-nxameet-box">
                          <div className="why-use-nxameet-box-inner">
                            <span className="why-use-nxameet-box-cir">
                              <img
                                src="./../assets/images/entypo_chat.png"
                                alt="1"
                              />
                            </span>
                            <h4>
                              Create Instant Chat with every new meeting, and
                              get work done
                            </h4>
                            <p>
                              The new chat feature allows participants to
                              communicate with each other within the conference.
                              ​​
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="why-use-nxameet-box">
                          <div className="why-use-nxameet-box-inner">
                            <span className="why-use-nxameet-box-cir">
                              <img
                                src="./../assets/images/carbon_gui-management.png"
                                alt="1"
                              />
                            </span>
                            <h4>Manage and control your meetings.</h4>
                            <p>
                              The host can mute, remove uninvited attendees, and
                              designate presenters and participants. Allow
                              people to join automatically or have them wait in
                              the virtual waiting room.​​
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="why-use-nxameet-box">
                          <div className="why-use-nxameet-box-inner">
                            <span className="why-use-nxameet-box-cir">
                              <img
                                src="./../assets/images/pixelarticons_notes-multiple.png"
                                alt="1"
                              />
                            </span>
                            <p>
                              You can even note down the content of the call
                              immediately after the call.​​
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ----- */}
                    <div className="nxaproduct-meet-video">
                      <p>
                        Interested in seeing these features in action? Check out
                        our NxaMeets product video{" "}
                        <Link
                          to="#"
                          className="watch-video-link d-none d-md-block"
                          onClick={() => this.setState({ openModal: true })}
                          style={{ cursor: "pointer" }}>
                          Watch Video
                          <i className="fas fa-play ml-2"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="complete-communction-system one-platfrom-host-meet">
            <div className=" container">
              <div className="col-md-12">
                <h2 className="">
                  One platform. Host Beautiful Meetings with built-in team
                  messaging.​
                </h2>
                <h3>Enable your teams to work anywhere they want.​​​​</h3>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="complete-communction-system-left">
                    <div className="make-dreamer-video-box">
                      <VideoAutoPlay />
                    </div>
                    <img src="./../assets/images/circle-bg.png" alt="1" />
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="complete-communction-system-right">
                    <h4 className="">
                      Organize Virtual meetings from any device
                    </h4>
                    <p>
                      With the NxaMeets by Nxatel solution, easily meet with
                      your colleagues and clients in real time. Your teams can
                      launch video calls from their computer, smartphone or
                      tablet. ​
                    </p>
                    <h4 className="mt-3">
                      Schedule and Connect your Calendar.​​
                    </h4>
                    <p>
                      ​Name your meeting, invite participants, and set a date —
                      all on one screen. Then with one click, you can start your
                      call.​​​​
                    </p>
                    <Link to="#" className="btn schedule-first-meet">
                      Try it Schedule your first meeting ​​
                      <i class="fas fa-play ml-2"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="nxatel-free-seven-days product-meet-free-day">
            <div className=" container">
              <div className="row">
                <div className="col-md-6">
                  <div className="nxatel-free-seven-days-left">
                    <img
                      src="./../assets/images/try-free-seven-day-meetpage.png"
                      alt="1"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="nxatel-free-seven-days-right">
                    <h3 className="">
                      Try Nxatel for<br></br> free for 14 Days
                    </h3>
                    <p>
                      Nxatel runs on the device you're <br></br>using right
                      now.​
                    </p>
                    <p className="mt-4">
                      Ready to switch to a unified platform?​{" "}
                    </p>
                    <div className="btn-center-home ">
                      <Link to="/signup" className="btn htw-learn-more mr-2">
                        Try for 14 days free
                      </Link>
                      <Link to="/pricing" className="btn get-demo-red">
                        See Pricing
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Meet;
