import React, { Component, useEffect, useState, useRef } from "react";
import NxaVideoModal from "./NxaVideoModal";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";

import { withRouter } from "../Utils/withRouter";
import PhoneInput, { parsePhoneNumber, getCountryCallingCode } from "react-phone-number-input";
import { CheckAccount, IsSocialSignupFrom, SocialSignup } from "../Services/Actions/userActions";
import { SendOTP } from "../Services/Actions/LoginSignupActions";
import { store } from "../Services";
import { LINKEDIN_WEB_SIGNUP_URL } from "./LinkedIn/parameters";
import { REACT_APP_GOOGLE_APP_ID } from "../Utils/Common";
const { getName } = require("country-list");

const crm_links = [
  "None",
  "amoCRM",
  "Copper",
  "FLG",
  "Freshsales",
  "HubSpot",
  "Microsoft Dynamics",
  "noCRM.io",
  "Onpipeline",
  "Pipedrive",
  "Salesforce",
  "sugarCRM",
  "TeamGram",
  "Zendesk Sell",
  "Zoho CRM",
  "Sage One",
  "Xero Accounting ",
  "Excel",
  "Other",
];
const initialState = {
  openModal: false,
  firstname: "",
  lastname: "",
  company: "",
  email: "",
  phone: "",
  password: "",
  call_number: "",
  prefix: "",
  country_code: "",
  country: "",
  click: false,
  accessState: "",
  crm: "",
  affiliate_code: "",
  promotional_code: "",
};

export const VideoAutoPlay = () => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      videoRef.current.play();
    } else {
      if (videoRef.current.play) {
        videoRef.current.pause();
      }
    }
  }, [isVisible]);

  return (
    <VisibilitySensor onChange={(isVisible) => setIsVisible(isVisible)}>
      <video width="100%" height="100%" autoPlay={true} controls loop ref={videoRef}>
        <source src="./../assets/videos/nxatel_dreamers.mp4" type="video/mp4" />
      </video>
    </VisibilitySensor>
  );
};

class InnerHome extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      validators: {
        Phone: {
          required: true,
          message: "Please enter a valid number.",
          rule: (val, params, validatorP) => !!(val && val.length <= 9),
        },
      },
      messages: {
        in: "Password and Confirm Password must be same!",
      },
    });
    this.state = initialState;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ accessState: window.randomString(15, "aA#") });
    let chat = document.getElementById("zoho-chat-popup");
    chat &&
      chat.addEventListener("click", () => {
        window.$zohosq.floatwindow.open();
      });
  }

  componentWillUnmount() {
    let chat = document.getElementById("zoho-chat-popup");
    chat && chat.removeEventListener("click", () => {});
  }
  numberChange = (number) => {
    let phoneNumber = number ? parsePhoneNumber(number.toString()) : "";
    let prefix = "";
    let country_code = "";
    let call_number = "";
    let country = "";
    if (phoneNumber) {
      if (phoneNumber.nationalNumber && phoneNumber.country) {
        call_number = phoneNumber.nationalNumber.replace(/^0+/, "");
        country_code = phoneNumber.country;
        country = getName(country_code);
        if (getCountryCallingCode(phoneNumber.country)) {
          prefix = getCountryCallingCode(phoneNumber.country);
        }
      }
    }
    this.setState({
      call_number: call_number,
      prefix: prefix,
      country_code: country_code,
      country: country,
    });
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onEnterPress = (e) => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };

  handleSubmit = async () => {
    const validator = this.validator;
    const { firstname, lastname, company, email, call_number, password, prefix, country, promotional_code, affiliate_code } = this.state;
    const data = {
      firstname,
      lastname,
      email,
      password,
      phone: call_number,
      prefix,
      country,
      company,
      promotional_code,
      affiliate_code,
    };
    if (validator.allValid()) {
      this.setState({ click: true });
      let signup = await CheckAccount(data, store.dispatch);
      console.log(signup);
      if (signup.code === 1) {
        this.setState({ initialState });
        let {
          account_details: { phone, prefix },
        } = this.props;
        if (!phone) {
          this.props.navigate("/");
          return false;
        }
        prefix = prefix.includes("+") ? prefix : `+${prefix}`;
        // phone = `${prefix}${phone}`;

        let response = await SendOTP({ Number: phone, prefix: prefix }, store.dispatch);
        if (response === 1) {
          this.props.navigate("/verify-number");
        } else {
          this.setState({ click: false });
          return false;
        }
      } else {
        this.setState({ click: false });
      }
    } else {
      validator.showMessages();
      this.forceUpdate();
    }
  };

  responseGoogle = (data) => {
    let profileObj = data?.profileObj;
    if (profileObj) {
      SocialSignup(profileObj, store.dispatch);
      IsSocialSignupFrom({ from: "nxa-page", with: "google" }, store.dispatch);
      this.props.navigate("/signup");
    }
  };

  _facebookLogin = () => {
    window.FB.getLoginStatus((response) => {
      if (response.status == "connected") {
        this.getCurrentFacebookUserInfo(response);
      } else {
        window.FB.login(
          (response) => {
            if (response.authResponse) {
              this.getCurrentFacebookUserInfo(response);
            }
          },
          { scope: "email" }
        );
      }
    });
  };

  getCurrentFacebookUserInfo() {
    window.FB.api("/me?fields=id,email,name", (userInfo) => {
      this.setState(
        {
          signUpWith: "facebook",
        },
        () => {
          SocialSignup(userInfo, store.dispatch);
          IsSocialSignupFrom({ from: "nxa-page", with: "facebook" }, store.dispatch);
          this.props.navigate("/signup");
        }
      );
      if (Object.keys(userInfo).length) {
        window.FB.logout((response) => {
          console.log("logout response", response);
          // Person is now logged out
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { social_signup: old_social_signup } = prevProps;
    const { social_signup } = this.props;
    if (social_signup && social_signup !== old_social_signup) {
      const { name, email } = social_signup;
      const [firstname, lastname] = name.split(" ");
      this.setState({ firstname, lastname, email });
    }
  }

  hidePop = () => {
    let element = document.getElementById("home-call-fixpop");
    element.classList.add("hide");
  };

  closeModal = () => {
    this.setState(
      {
        openModal: false,
      },
      () => {
        window.removeBackdrop();
      }
    );
  };

  render() {
    const { promotional_code, openModal, click, firstname, lastname, company, phone, email, password, call_number, affiliate_code } = this.state;
    const { get_user_country: { country_code = "ZA" } = {} } = this.props;
    this.validator.purgeFields();
    return (
      <>
        <div className="home-call-popup home-call-fixpop bg--white z-index--9 p--15 radius--16 text-center w--min-290 " id="home-call-fixpop">
          <div className="p--relative">
            <span
              className="close-popup"
              onClick={(e) => {
                this.hidePop();
                e.stopPropagation();
              }}>
              <img src={`${"./../assets/images"}/cross-add-modal.svg`} alt="1" className="h--25 w-auto c--pointer" />
            </span>
            <span className=" w--70 h--70 radius--50 overflow-hidden object-fit--cover bg--gray-f5 d-flex align-items-center justiffy-content-center m-auto border--4 border--orange">
              <img src={`${"./../assets/images"}/home-profile-img.svg`} alt="2" />
            </span>
            <div className="font--24 mt--8 mb--8 text--black sf--d-bold">Welcome to Nxatel</div>
            <div
              className="text--blue-1d3f font--16 bg--light-yellow pt--7 pb--7 pr--10 pl--10 radius--50 c--pointer sf--d-semibold"
              id="zoho-chat-popup">
              Speak to our sales team
            </div>
            <div className="text--blue-1d3f font--16 mt--10 lg--25 dm--sans">Call us on</div>
            <div className="text--blue-1d3f font--20 dm--sans fw--700">086 169 2835</div>
          </div>
        </div>
        <div className=" banner p--relative">
          <div className="banner-inner  bg--gray-eff3 ">
            <div className=" container">
              <div className="row">
                <div className="col-lg-6 col-md-7">
                  <div className=" mt--48">
                    <h1 className="font--36 lh--49  sf--d-bold font--sm-30 lh--sm-42">
                      Manage Your Business<br></br>Communication With <br></br>
                      One Platform
                    </h1>
                    <h5 className="font--16 mt--20 lh--25 mb--50 text--gray-778f w-80" w-80>
                      Calls, Virtual Meetings, Message and Cloud Documents all in one place Fully Enterprise Cloud Platform. No waiting access
                      services instantly, Pay monthly.
                    </h5>
                    <div className="d-flex banner-btn mt--15 mb--15">
                      <Link
                        to="#"
                        className="watch-video-link d-none d-md-block"
                        onClick={() => this.setState({ openModal: true })}
                        style={{ cursor: "pointer" }}>
                        Watch Video
                        <i className="fas fa-play ml-2"></i>
                      </Link>
                      <Link
                        to="/pricing"
                        className="btn hover-bg--orange text--orange hover-text--white radius--16-0 border--2 border--orange sf--d-semibold">
                        See pricing
                      </Link>
                      <Link to="/signup" className="btn  bg--orange text--white radius--16-0 border--2 border--orange ml--24 sf--d-semibold">
                        Try for 14 days free
                      </Link>
                      <Link to="#" className="watch-video-link d-block d-md-none mt-3">
                        Watch Video
                        <i className="fas fa-play ml-2"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-5 p-0">
                  <div className="banner-right">
                    <img src={`${"./../assets/images"}/home-page-img/home-banner-img.svg`} alt="1" className="w-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-bottom-client z-index--1 p--relative ">
            <div className="container">
              <div
                className="text-center font--20 mb-0 text--black sf--d-medium mt--15
    ">
                No contract. No set-up Fees. One Provider for everything. Easy to scale. Work from Home Ready​​
              </div>
              <div className="text--gray-5b7d text-center mt--25 sf--d-bold font--18 mb--15">TRUSTED BY OVER 1000+ COMPANIES</div>
              <div className="  d-flex align-items-center   flex-wrap justify-content-around">
                <span className="company-logo  text-center">
                  <a href={void 0}>
                    <img src={`${"./../assets/images"}/home-page-img/13.png`} className="img-fluid  p--8 c--pointer" alt="1" />
                  </a>
                </span>
                <span className="company-logo  text-center">
                  <a href={void 0}>
                    <img src={`${"./../assets/images"}/home-page-img/9.png`} className="img-fluid  p--8 c--pointer" alt="1" />
                  </a>
                </span>
                <span className="company-logo  text-center">
                  <a href={void 0}>
                    <img src={`${"./../assets/images"}/home-page-img/12.png`} className="img-fluid  p--8 c--pointer" alt="1" />
                  </a>
                </span>
                <span className="company-logo  text-center">
                  <a href={void 0}>
                    <img src={`${"./../assets/images"}/home-page-img/4.png`} className="img-fluid  p--8 c--pointer" alt="1" />
                  </a>
                </span>
                <span className="company-logo  text-center">
                  <a href={void 0}>
                    <img src={`${"./../assets/images"}/home-page-img/10.png`} className="img-fluid  p--8 c--pointer" alt="1" />
                  </a>
                </span>
                <span className="company-logo  text-center">
                  <a href={void 0}>
                    <img src={`${"./../assets/images"}/home-page-img/5.png`} className="img-fluid  p--8 c--pointer" alt="1" />
                  </a>
                </span>
                <span className="company-logo  text-center">
                  <a href={void 0}>
                    <img src={`${"./../assets/images"}/home-page-img/14.png`} className="img-fluid  p--8 c--pointer" alt="1" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* how it works section */}
        <section className="step-card-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="w-80 text-center text--black font--36 lh--42 sf--d-semibold  m-auto font--sm-30 lh--sm-42">
                  Get your cloud communication system up
                  <br />
                  and running in 3 easy steps{" "}
                </div>
                <div className="text--gray-6971 font--20 lh--25 text-center sf--d-semibold mt--10">
                  Choose your business Phone Numbers from 20+ Countries. Start Receiving and Making Calls across the world
                </div>
                <div className="font--16 lh--27 mt--10 mb--40 text-center text--gray-9a dm-sans fw--400">
                  No hardware. No downloads. No maintenance cost. Plug-n-Play cloud communication system.
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className=" step-card pt--25 pb--25 pr--45 pl--45 h--min-240 bg--white">
                      <span className="w--50 h--50 radius--50 bg--orange d-flex align-items-center justify-content-center dm-sans fw--700 font--24 text--white">
                        1
                      </span>
                      <div className="text--black font--16 mt--15 lh-29 sf--d-medium fw--700">
                        Select New or<br></br> Port Your<br></br> Number.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className=" step-card pt--25 pb--25 pr--45 pl--45 h--min-240 bg--white">
                      <span className=" w--50 h--50 radius--50 bg--orange d-flex align-items-center justify-content-center dm-sans fw--700 font--24 text--white">
                        2
                      </span>
                      <div className="text--black font--16 mt--15 lh-29 sf--d-medium fw--700">
                        Add team <br></br>members.​
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className=" step-card pt--25 pb--25 pr--45 pl--45 h--min-240 bg--white">
                      <span className="w--50 h--50 radius--50 bg--orange d-flex align-items-center justify-content-center dm-sans fw--700 font--24 text--white">
                        3
                      </span>
                      <div className="text--black font--16 mt--15 lh-29 sf--d-medium fw--700">
                        Start receiving <br></br>and making calls<br></br> and hosting <br></br>online meetings.​
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className=" step-card pt--25 pb--25 pr--45 pl--45 h--min-240 bg--white">
                      <span className="w--50 h--50 radius--50 bg--orange d-flex align-items-center justify-content-center dm-sans fw--700 font--16 text--white">
                        {/* <small className="font--20">Grow!</small> */}
                        Grow!
                      </span>
                      <div className="text--black font--16 mt--15 lh-29 sf--d-medium fw--700">
                        Monitor team<br></br> metrics in <br></br>real-time,
                        <br></br> Manage cost.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center gap--15 mt--15">
                  {" "}
                  <Link to="/pricing" className="btn  bg--transparent text--red-f037 border--2 border--red-f037 sf--t-medium radius--16-0">
                    See pricing
                  </Link>
                  <Link to="/signup" className="btn  bg--red-f037 text--white border--2 border--red-f037 sf--t-medium radius--16-0">
                    Try for 14 days free
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* how it works section */}
        {/* why switch to nxatel */}
        <section className="switch-nxa-sec ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className=" text-center text--black font--36 lh--42 sf--d-semibold   font--sm-30 mb--8 lh--sm-42">Why switch to Nxatel?​​​</div>
                <div className="text--gray-6971 text-center font--20 w-80 m-auto sf dm-sans fw--700 w-sm-100 font--sm-16">
                  You have many choices when it comes to business communications systems. Nxatel offers a unified experience, saving you time and
                  money.​​​
                </div>
                <div className="pt--50">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="switch-nxa-card p--20 bg--white radius--8 mb--50 h--min-225 mt--10">
                        <span className="card-circle w--75 h--75 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src={`${"./../assets/images"}/wn-1.svg`} className=" h--44 w-auto" alt="1" />
                        </span>
                        <div className="text--blue-1d3f font--20 lh--28 sf--t-medium fw--700 mb--5 text-center">
                          No more implementation nightmares.
                        </div>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans text-center">
                          With Nxatel, getting started is painless. There’s no special hardware to install, No waiting period.​
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="switch-nxa-card p--20 bg--white radius--8 mb--50 h--min-225 mt--10">
                        <span className="card-circle w--75 h--75 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src={`${"./../assets/images"}/wn-2.svg`} alt="1" className="h--36 w-auto" />
                        </span>
                        <div className="text--blue-1d3f font--20 lh--28 sf--t-medium fw--700 mb--5 text-center">
                          No Long-term commitments and flexible.
                        </div>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans text-center">
                          With Nxatel there’s no contract and no long-term commitment. Scaling it’s a click away.​​
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="switch-nxa-card p--20 bg--white radius--8 mb--50 h--min-225 mt--10">
                        <span className="card-circle w--75 h--75 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src={`${"./../assets/images"}/wn-3.svg`} alt="1" className="h--36 w-auto" />
                        </span>
                        <div className="text--blue-1d3f font--20 lh--28 sf--t-medium fw--700 mb--5 text-center">Secure & Reliable </div>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans text-center">
                          ​Enabling enterprise-grade security for businesses.​
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="switch-nxa-card p--20 bg--white radius--8 mb--50 h--min-225 mt--10">
                        <span className="card-circle w--75 h--75 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src={`${"./../assets/images"}/wn-4.svg`} alt="1" className="h--36 w-auto" />
                        </span>
                        <div className="text--blue-1d3f font--20 lh--28 sf--t-medium fw--700 mb--5 text-center">
                          No Technician needed to deploy as everything is web-based and administration is simple.
                        </div>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans text-center">
                          ​Add users or make system changes on the fly from anywhere. ​
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="switch-nxa-card p--20 bg--white radius--8 mb--50 h--min-225 mt--10">
                        <span className="card-circle w--75 h--75 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src={`${"./../assets/images"}/wn-5.svg`} className="h--36 w-auto" alt="1" />
                        </span>
                        <div className="text--blue-1d3f font--20 lh--28 sf--t-medium fw--700 mb--5 text-center">
                          No using your personal number for calls and messages.
                        </div>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans text-center">
                          Having a business phone numbers creates a more polished, professional appearance and gives you more control over customer
                          relationships.​​​
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="switch-nxa-card p--20 bg--white radius--8  h--min-225 mt--10">
                        <span className="card-circle w--75 h--75 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src={`${"./../assets/images"}/wn-6.svg`} alt="1" className="h--36 w-auto" />
                        </span>
                        <div className="text--blue-1d3f font--20 lh--28 sf--t-medium fw--700 mb--5 text-center">Modern Enterprise features</div>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans text-center">
                          that will make your business as well equipped as any big business. ​​​
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* why switch to nxatel end */}

        {/* contact form start */}
        <section className="start-form-sec p--relative">
          <div className="container-fluid">
            <div className="row ">
              <div className="col-lg-1 d-lg-block d-none"></div>
              <div className="col-md-5 col-lg-4 pt--48">
                <div className="">
                  <div className="text-align-start font--36 lh--45 pr--90 text--black sf--d-semibold pr--sm-unset font--sm-30 lh--sm-42">
                    Instant Set-Up Get Your Business Phone Number In Just 3 Minutes!
                  </div>
                  <ul className=" mb-0 pl--20 mt--10">
                    <li className="text--black lh--21 font--16 sf--d-medium mt--10">Choose a local business number from 20+ countries​</li>
                    <li className="text--black lh--21 font--16 sf--d-medium mt--10">Activate your number instantly​</li>
                    <li className="text--black lh--21 font--16 sf--d-medium mt--10">Unlimited calls domestic only*​</li>
                    <li className="text--black lh--21 font--16 sf--d-medium mt--10">Advanced Reports in real time​</li>
                    <li className="text--black lh--21 font--16 sf--d-medium mt--10">Virtual Meetings, SMS and Chat​</li>
                    <li className="text--black lh--21 font--16 sf--d-medium mt--10">Unlimited Cloud Storage​</li>
                    <li className="text--black lh--21 font--16 sf--d-medium mt--10">Access Nxatel on Web and Mobile app</li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-1 d-lg-block d-none"></div>
              <div className="col-lg-6 col-md-7  pr-0">
                <div className="start-form-box services-right-box bg--white radius--0-100 p--25">
                  <div className="text--black text-center font--36 sf--d-semibold mb--8 font--sm-30 lh--sm-42">Get Started Now!</div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input--group mb--15">
                        <label className="label ">First Name*</label>
                        <input
                          type="text"
                          className="input"
                          placeholder="Enter First Name"
                          name="firstname"
                          value={firstname}
                          onChange={this.onChangeHandler}
                          maxLength="20"
                        />
                        {this.validator.message("First Name", firstname, "required")}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input--group mb--15">
                        <label className="label">Last Name*</label>
                        <input
                          type="text"
                          className="input"
                          placeholder="Enter Last Name"
                          name="lastname"
                          value={lastname}
                          onChange={this.onChangeHandler}
                          maxLength="20"
                        />
                        {this.validator.message("Last Name", lastname, "required")}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="label">
                        {/* Work Phone* */}
                        Mobile Number*
                      </label>
                      <div className="input--group mb-3">
                        <PhoneInput
                          limitMaxLength={11}
                          name="phone"
                          className="PhoneInput"
                          placeholder="Phone Number"
                          value={phone}
                          onChange={(phone) => this.numberChange(phone)}
                          defaultCountry={country_code}
                        />
                        {this.validator.message("Phone", call_number, "required|Phone")}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input--group mb--15">
                        <label className="label">Work Email*</label>
                        <input type="email" className="input" name="email" placeholder="Enter Email" value={email} onChange={this.onChangeHandler} />
                        {this.validator.message("Email", email, "required|email|min:10,string|max:60,string")}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input--group mb--15">
                        <label className="label">Company Name</label>
                        <input
                          type="text"
                          className="input"
                          name="company"
                          placeholder="Enter Company"
                          value={company}
                          onChange={this.onChangeHandler}
                          maxLength="20"
                        />
                        {this.validator.message("Company", company, "required|min:2,string|max:50,string")}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input--group mb--15">
                        <label className="label">Password</label>
                        <input
                          type="password"
                          className="input"
                          placeholder="Password"
                          name="password"
                          value={password}
                          onChange={this.onChangeHandler}
                          maxLength="20"
                        />
                        {this.validator.message("Password", password, "required|min:5|max:60")}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input--group mb--15">
                        <label className="label">Affiliate Code</label>
                        <input
                          type="text"
                          className="input"
                          name="affiliate_code"
                          placeholder="Enter Affiliate Code"
                          value={affiliate_code}
                          onChange={this.onChangeHandler}
                          maxLength="20"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* <div className="input--group mb--15">
                                                            <label className="label">
                                                                Promotional Code
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="input"
                                                                placeholder="Enter Promotional Code"
                                                                name="promotional_code"
                                                                value={promotional_code}
                                                                onChange={
                                                                    this.onChangeHandler
                                                                }
                                                                maxLength="20"
                                                            />
                                                        </div> */}
                      <div className="input--group mb--15">
                        <label className="label">Which CRM or Ticketing tool do you use? *</label>
                        <select
                          type="text"
                          className="input"
                          placeholder="Enter Promotional Code"
                          name="promotional_code"
                          value={promotional_code}
                          onChange={this.onChangeHandler}
                          maxLength="20"></select>
                      </div>
                    </div>
                  </div>
                  <div className=" row">
                    <div className="col-md-6">
                      <div
                        type="button"
                        className="btn  text-uppercase text-uppercase text--red-f037 bg--transparent radius--6 border--1 border--red-f037 text-center w-100 dm--sans hover-text--red-f037 mb--12"
                        onClick={() => this.props.navigate("/demo")}>
                        Get a Demo
                      </div>
                    </div>
                    <div className="col-md-6">
                      <button
                        type="button"
                        className="btn text-uppercase text-uppercase text--white bg--red-f037 radius--6 border--1 border--red-f037 text-center w-100 dm--sans hover-text--white mb--12"
                        onClick={this.handleSubmit}
                        onKeyPress={this.onEnterPress}>
                        {click ? "Please wait..." : "TRY FOR 14 DAYS"}
                      </button>
                    </div>
                  </div>
                  <div className="login-via p--relative mb--12 ">
                    <hr className="w-100"></hr>
                    <span className="or-inner">OR</span>
                  </div>
                  <div className="row justify-content-center gap--15">
                    <div className="col-md-3 ">
                      <GoogleLogin
                        clientId={REACT_APP_GOOGLE_APP_ID}
                        render={(renderProps) => (
                          <div
                            className="c--pointer text-center border--1 border--gray-9c radius--5 p--4 text--gray-9c hover-bg--gray-f4 font--11"
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}>
                            <svg width="20" height="20" viewBox="0 0 50 50" fill="none" className="mr-2">
                              <g clip-path="url(#clip0_180_4)">
                                <path
                                  d="M50 25.5539C50 23.5043 49.8298 22.0085 49.4614 20.4575H25.5102V29.7086H39.569C39.2857 32.0077 37.7551 35.47 34.3537 37.7965L34.306 38.1062L41.8789 43.8391L42.4036 43.8903C47.2221 39.5416 50 33.1432 50 25.5539Z"
                                  fill="#4285F4"
                                />
                                <path
                                  d="M25.5102 49.9286C32.3978 49.9286 38.1801 47.7126 42.4036 43.8904L34.3537 37.7966C32.1995 39.2646 29.3083 40.2894 25.5102 40.2894C18.7642 40.2894 13.0386 35.9409 10.9976 29.9304L10.6984 29.9552L2.82399 35.9104L2.72101 36.1901C6.91598 44.3334 15.5328 49.9286 25.5102 49.9286Z"
                                  fill="#34A853"
                                />
                                <path
                                  d="M10.9976 29.9303C10.4591 28.3792 10.1474 26.7172 10.1474 25C10.1474 23.2826 10.4591 21.6207 10.9693 20.0697L10.955 19.7393L2.98189 13.6885L2.72102 13.8097C0.992074 17.189 0 20.9837 0 25C0 29.0163 0.992074 32.8108 2.72102 36.1901L10.9976 29.9303Z"
                                  fill="#FBBC05"
                                />
                                <path
                                  d="M25.5102 9.7104C30.3004 9.7104 33.5316 11.7324 35.3741 13.4221L42.5736 6.55284C38.152 2.53658 32.3978 0.0714111 25.5102 0.0714111C15.5328 0.0714111 6.91598 5.66645 2.72101 13.8097L10.9693 20.0696C13.0386 14.0591 18.7642 9.7104 25.5102 9.7104Z"
                                  fill="#EB4335"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_180_4">
                                  <rect width="50" height="50" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            {/* <img src={`${"./../assets/images"}/home-page-img/google.png`} className="img-fluid" alt="1" /> */}
                            Sign Up with G Suite
                          </div>
                        )}
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      />
                    </div>
                    <div className="col-md-3 ">
                      <div className="c--pointer text-center border--1 border--gray-9c radius--5 p--4 hover-bg--gray-f4 font--11">
                        <a
                          href={LINKEDIN_WEB_SIGNUP_URL}
                          className="d-flex align-items-center  justify-content-center text--gray-9c hover-text--gray-9c ">
                          <img src={`${"./../assets/images"}/home-page-img/link.png`} alt="1" className="w--20 mr--8" />
                          Sign Up with LinkedIn
                        </a>
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div
                        className="c--pointer text-center border--1 border--gray-9c radius--5 p--4 text--gray-9c font--11 hover-bg--gray-f4"
                        style={{ cursor: "pointer" }}
                        onClick={this._facebookLogin}>
                        <img src={`${"./../assets/images"}/home-page-img/face.svg`} className="w--22 mr--8" alt="1" />
                        Sign Up with Facebook
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt--15">
                    <input type="checkbox" id="exampleCheck1" />
                    <label
                      className="form-check-label text--black font--14 dm-sans fw-700 d-flex align-items-center justify-content-center flex-wrap"
                      for="exampleCheck1">
                      By signing up,you agree to our&nbsp;
                      <Link to={"/terms"} className="text--orange">
                        Terms
                      </Link>
                      &nbsp;&&nbsp;
                      <Link to={"/privacy"} className="text--orange">
                        Privacy Policy
                      </Link>
                    </label>
                  </div>
                  {/* <p className="mt-3 mb-0 terms text-center agree-terms-home">
                                                    By signing up,you agree to our{" "}
                                                    <a href={void 0}>Terms</a> &{" "}
                                                    <a href={void 0}>Privacy Policy</a>{" "}
                                                </p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* contact form end */}
        {/* simple work any size team section */}
        <section className="team-size-sec sec-small-bussiness anysize-team-section how-it-works-home-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center sf--d-semibold mb--8 font--36 lh--42 text--black font--sm-30 lh--sm-42">
                  Simply works for any size team ​
                </div>
                <div className="text--gray-6971  font--20 lh--25 text-center sf--d-bold mb--40">
                  Nxatel unifies your business communication seamlessly across multiple devices​
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="team-size-card text-center pt--15 pb--15 pr--30 pl--30">
                      <img src="./../assets/images/for-sale.png" className="h--170 w-auto" alt="1" />
                      <div className="text--black font--22 mt--15 sf--d-bold mb--12 ">For Sales Teams</div>
                      <div
                        className="font--16 lh--27 mt--10 text-center dm--sans text--gray-9a  lh--27
                        mt--10
                        text-center">
                        Set a new standard for sales productivity and performance by switching to a phone system that connects with your CRM.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="team-size-card text-center pt--15 pb--15 pr--30 pl--30">
                      <img src="./../assets/images/for-support.png" className="h--170 w-auto" alt="1" />

                      <div className="text--black font--22 mt--15 sf--d-bold mb--12 ">For Support Teams</div>
                      <div
                        className="font--16 lh--27 mt--10 text-center dm--sans text--gray-9a  lh--27
                        mt--10
                        text-center">
                        Connect your phone system to your Helpdesk system and empower your team to focus on delighting customers.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="team-size-card text-center pt--15 pb--15 pr--30 pl--30">
                      <img src="./../assets/images/for-start.png" className="h--170 w-auto" alt="1" />

                      <div className="text--black font--22 mt--15 sf--d-bold mb--12 ">
                        For Small Business
                        <br />
                        and Start-Ups
                      </div>
                      <div
                        className="font--16 lh--27 mt--10 text-center dm--sans text--gray-9a  lh--27
                        mt--10
                        text-center">
                        Transform your cell phone into a business communications powerhouse with the Nxatel app. Available for Android and iOS users,
                        it allows you to connect with your customers when you are on the go or working remotely.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center gap--20 mt--40 mt--sm-30 ">
                  <Link
                    to="/signup"
                    className="btn  text-uppercase text--white bg--red-f037 radius--16-0 border--2 border--red-f037 text-center  sf--t-medium hover-text--white  font--sm-15">
                    Try for 14 days free
                  </Link>
                  <Link
                    to="/pricing"
                    className="btn   text-uppercase text--red-f037 bg--transparent radius--16-0 border--2 border--red-f037 text-center  sf--t-medium hover-text--red-f037  font--sm-15">
                    See Pricing
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* simple work any size team section */}
        {/* buisness communication system section */}
        <section className="communication-features  buisness-communication-system">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className=" text-center text--black font--36 lh--42 sf--d-semibold mb--8 font--sm-30 lh--sm-42">
                  Business Communication System Features​​
                </div>
                <div className="text--gray-6971 text-center font--20 w-80 m-auto sf dm-sans fw--700 w-sm-100 font--sm-16 mb--sm-15 ">
                  50+ powerful features designed to empower sales, support teams
                  <br />
                  and your business customer service processes​​
                </div>
                <div className="pt--65 pt--sm-38">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="feature-card p--20 bg--white radius--8 mb--35 h--min-160 h--sm-min-auto">
                        <span className="card-circle w--45 h--45 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src="./../assets/images/bs-1.svg" className="h--25 w-auto" alt="1" />
                        </span>
                        <div className="text--blue-1d3f font--18 lh--28 sf--t-medium fw--700 mb--5 ">Access Local Numbers from 20+ Countries. </div>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans "> Activate new numbers in a matter of seconds.</div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="feature-card p--20 bg--white radius--8 mb--35 h--min-160 h--sm-min-auto">
                        <span className="card-circle w--45 h--45 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src="./../assets/images/bs-2.svg" className="h--25 w-auto" alt="1" />
                        </span>

                        <div className="text--gray-7c87 font--16 lh--25 dm-sans ">
                          Stay productive with multiple device support. Use smartphones and tablets to make and receive calls from anywhere.​
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="feature-card p--20 bg--white radius--8 mb--35 h--min-160 h--sm-min-auto">
                        <span className="card-circle w--45 h--45 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src="./../assets/images/bs-3.svg" className="h--25 w-auto" alt="1" />
                        </span>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans ">
                          Securely record all calls and store unlimited calls on your NxaBox Axxount​
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="feature-card p--20 bg--white radius--8 mb--35 h--min-160 h--sm-min-auto">
                        <span className="card-circle w--45 h--45 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src="./../assets/images/bs-4.svg" className="h--25 w-auto" alt="1" />
                        </span>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans ">
                          Stay in touch even when your office is out of reach by forwarding calls to your mobile number.​
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ------ */}
                  <div className="row">
                    <div className="col-md-3">
                      <div className="feature-card p--20 bg--white radius--8 mb--35  h--min-185 h--sm-min-auto">
                        <span className="card-circle w--45 h--45 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src="./../assets/images/bs-5.svg" className="h--25 w-auto" alt="1" />
                        </span>

                        <div className="text--blue-1d3f font--18 lh--28 sf--t-medium fw--700 mb--5 ">
                          Easily assign calls to team mbers to mobile or desk phones with IVR
                        </div>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans ">your calls correctly with automated greeting and distribution.</div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="feature-card p--20 bg--white radius--8 mb--35  h--min-185 h--sm-min-auto">
                        <span className="card-circle w--45 h--45 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src="./../assets/images/bs-6.svg" className="h--25 w-auto" alt="1" />
                        </span>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans ">
                          Stay productive from anywhere with HD audio and video conferencing capabilities. Host up to 100 participants with Nxatel​
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="feature-card p--20 bg--white radius--8 mb--35  h--min-185 h--sm-min-auto">
                        <span className="card-circle w--45 h--45 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src="./../assets/images/bs-7.svg" className="h--25 w-auto" alt="1" />
                        </span>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans ">
                          Empower team communication in all forms. Send text messages, and collaborative with instant chats directly from your Nxatel
                          platform.​​
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="feature-card p--20 bg--white radius--8 mb--35  h--min-185 h--sm-min-auto">
                        <span className="card-circle w--45 h--45 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src="./../assets/images/bs-8.svg" className="h--25 w-auto" alt="1" />
                        </span>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans ">
                          With Nxatel Call pop client you get one view of notes, previous chats, and customer details.​​
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ------ */}
                  <div className="row">
                    <div className="col-md-3">
                      <div className="feature-card p--20 bg--white radius--8  mb--35 h--min-155 h--sm-min-auto">
                        <span className="card-circle w--45 h--45 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src="./../assets/images/bs-9.svg" className="h--25 w-auto" alt="1" />
                        </span>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans ">
                          Stay in the know with call metrics like average call time, missed call rate, call cost.​
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="feature-card p--20 bg--white radius--8 mb--35 h--min-155 h--sm-min-auto">
                        <span className="card-circle w--45 h--45 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src="./../assets/images/bs-10.svg" className="h--25 w-auto" alt="1" />
                        </span>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans ">
                          Give your teammates the option to quickly speak to one another before transferring a live call.​
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="feature-card p--20 bg--white radius--8 mb--35 h--min-155 h--sm-min-auto">
                        <span className="card-circle w--45 h--45 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src="./../assets/images/bs-11.svg" className="h--25 w-auto" alt="1" />
                        </span>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans ">
                          Classify your calls with custom tags for easy reference and smart analysis.​​​
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="feature-card p--20 bg--white radius--8 mb--35 h--min-155 h--sm-min-auto">
                        <span className="card-circle w--45 h--45 radius--50 bg--orange d-flex align-items-center justify-content-center ">
                          <img src="./../assets/images/bs-12.svg" className="h--25 w-auto" alt="1" />
                        </span>
                        <div className="text--gray-7c87 font--16 lh--25 dm-sans ">
                          Set customized schedules to confirm exactly when each of your numbers are available to receive calls.​
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----- */}
                </div>
                <div className=" text-center text--gray-6971 font--18 sf--d-medium mb--8">​Preconfigured and ready to use, if you need them​</div>
                <div className="text-center text--gray-6971 font--16 dm--sans fw--normal text-capitalize">
                  Need IP phones? We’ve got you covered. Shop for IP devices to use with your Nxatel service
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  buisness communication systemsection */}
        <section
          className="explore-products-sec​ 
 customer-sec our-product">
          {" "}
          {/*  after-img */}
          <div className="container">
            <div className="row ">
              <div className="col-md-12">
                <div className=" text-center text--black font--36 lh--42 sf--d-semibold mb--8  font--sm-30 lh--sm-42">Explore our products​</div>
                <div className="text--gray-6971 text-center font--20 sf dm-sans fw--700 w-sm-100 font--sm-16 mb--sm-15 mb--40 ">
                  Get everything to keep your team and customer conversation on one easy system.​
                </div>
                <div className="row ">
                  <div className="col-lg-3 col-md-6">
                    <div className="explore-products-box  bg--white h--min-375 mb--sm-30 text--sm-center">
                      <div className="explore-products-box-img customer-inner product-img1 bg--yellow-ff79  h--min-300">
                        <img src="./../assets/images/home-page-img/product_1.png" className="w-100 pt--45 pb--45 pr--5 pl--5" alt="1" />
                      </div>
                      <div className="sf--t-regular  fw--700 pt--17 pr--17 pl--17 pb--5 font--20 text--black">Call</div>
                      <div className=" font--13 pb--20 pr--20 pl--20 text--black sf--t-regular">Cloud phone system with 50+ robust features</div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="explore-products-box  bg--white h--min-375 mb--sm-30 text--sm-center">
                      <div
                        className="explore-products-box-img customer-inner d-flex flex-end  bg--space-blue  h--min-300 
                       ">
                        <img src="./../assets/images/home-page-img/product_2.png" className="w-100 pt--80" alt="1" />
                      </div>
                      <div className="sf--t-regular  fw--700 pt--17 pr--17 pl--17 pb--5 font--20 text--black">Meet</div>
                      <div className=" font--13 pb--20 pr--20 pl--20 text--black sf--t-regular">
                        Video meetings solution with HD video and screen sharing
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="explore-products-box  bg--white h--min-375 mb--sm-30 text--sm-center">
                      <div className="explore-products-box-img  bg--pink-ffc5  h--min-300 ">
                        <img src="./../assets/images/home-page-img/product_3.png" className="w-100  pl--34  radius--top-right-62" alt="1" />
                      </div>
                      <div className="sf--t-regular  fw--700 pt--17 pr--17 pl--17 pb--5 font--20 text--black">Message</div>
                      <div className=" font--13 pb--20 pr--20 pl--20 text--black sf--t-regular">
                        SMS and Team messaging seamless collaboration with files, tasks, and more
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="explore-products-box  bg--white h--min-375 m--sm-0 text--sm-center">
                      <div className="explore-products-box-img  bg--gray-d8  h--min-300 ">
                        <img src="./../assets/images/home-page-img/product_4.png" className="w-100 pt--57 pr--38 pl--38" alt="1" />
                      </div>
                      <div className="sf--t-regular  fw--700 pt--17 pr--17 pl--17 pb--5 font--20 text--black">Store Documents</div>
                      <div className=" font--13 pb--20 pr--20 pl--20 text--black sf--t-regular">
                        SMS and Team messaging seamless collaboration with files, tasks, and more
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* compliance security section */}
        <section className=" product-class-sec ">
          <div className="container">
            <div className="d-flex pt--10 pb--10 pr--50 pl--50 gap--20 flex--sm-column text--sm-center align-items--sm-center p--sm-0">
              <img src="./../assets/images/made-in-south-africa.svg" className="w--110" alt="..." />
              <div className="d-flex flex-column">
                <div className="font--36 lh--42  text--black sf--d-semibold fw--700 mb--8 text--sm-center font--sm-30 lh--sm-42 ">
                  A proudly African and world class product​
                </div>
                <div className="font--18 lh--29  text--gray-6971 dm-sans fw--700 text--sm-center font--sm-15">
                  Nxatel is made with love Locally and has local support. Nxatel proudly competes with the best international Telco SaaS (Software as
                  a Service) companies to offering the best, business communication solution in the market with affordable, flexible packages and
                  world-class support.
                </div>
              </div>
            </div>
            <div className="pt--10 pb--10 pr--50 pl--50 mt--60 p--sm-0">
              <div className="row mb--40 m--sm-0 ">
                <div className="col-md-6">
                  <div className="d-flex align-items-start gap--20 flex--sm-column text--sm-center align-items--sm-center mb--sm-30 ">
                    <img src="./../assets/images/compliance-img-1.svg" className="w--95" alt="..." />

                    <div className="d-flex flex-column  ">
                      <div className="font--25 lh--35  text--blue-1d3f sf--d-bold  mb--8 font--sm-20 lh--sm-27 mb--sm-10">BBB-EE Level </div>
                      <div className="font--16 text--gray-9a lh--25 dm-sans">
                        Nxatel is a level 1 BEE service provider and our woman led team consists of young diverse team members.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-start gap--20 flex--sm-column text--sm-center align-items--sm-center mb--sm-30 ">
                    <img src="./../assets/images/compliance-img-2.svg" className="w--95" alt="..." />

                    <div className="d-flex flex-column ">
                      <div className="font--25 lh--35  text--blue-1d3f sf--d-bold  mb--8 font--sm-20 lh--sm-27 mb--sm-10">
                        Flexible plans,
                        <br />
                        Affordable pricing
                      </div>
                      <div className="font--16 text--gray-9a lh--25 dm-sans">
                        We offer our products in ZAR, so you can manage your expenses without the monthly fluctuations of USD billing and the benefit
                        to claim VAT.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ----- */}
              <div className="row">
                <div className="col-md-6">
                  <div className="d-flex align-items-start gap--20 flex--sm-column text--sm-center align-items--sm-center  mb--sm-30">
                    <div className="compliance-inner-right">
                      <img src="./../assets/images/compliance-img-3.svg" className="w--95" alt="..." />
                    </div>
                    <div className="d-flex flex-column">
                      <div className="font--25 lh--35  text--blue-1d3f sf--d-bold  mb--8 font--sm-20 lh--sm-27 mb--sm-10">
                        Our Support is
                        <br />
                        Local and Nxa!{" "}
                      </div>
                      <div className="font--16 text--gray-9a lh--25 dm-sans">
                        Our friendly trained customer support representatives are ready and available to assist with any support or training related
                        queries on the Nxatel product.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-start gap--20 flex--sm-column text--sm-center align-items--sm-center  ">
                    <div className="compliance-inner-right">
                      <img src="./../assets/images/compliance-img.svg" className="w--95" alt="..." />
                    </div>
                    <div className="d-flex flex-column">
                      <div className="font--25 lh--35  text--blue-1d3f sf--d-bold  mb--8 font--sm-20 lh--sm-27 mb--sm-10">
                        Protection of
                        <br />
                        Personal Information{" "}
                      </div>
                      <div className="font--16 text--gray-9a lh--25 dm-sans">
                        Nxatel complies with all South African legislation including the Protection of Personal Information (POPI) Act 4 of 2013, and
                        in accordance with the definition of Personal Data in Article 4(1) of the General Data Protection Regulation GDPR (EU)
                        2016/679
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* compliance security section */}
        <section className="video-sec">
          <div className="container">
            <div className="row ">
              <div className="col-md-5">
                <div className="mb--25 text--black sf--d-semibold font--36  lh--45 font--sm-30 lh--sm-42">
                  Made for the dreamers.​​<br></br> Like you.
                </div>
                <div className="pr--100 text--black font--16 dm--sans fw--700">
                  See Nxatel in action.
                  <br />
                  Discover how the Nxatel platform can help you grow your business like never before.
                </div>
                {/* <a
                                href={void 0}
                                className="btn  orange-radius active mt-2 "
                            >
                                Watch Now
                            </a> */}
                <div className="d-flex  gap--15 mt--40 mb--sm-40">
                  {" "}
                  <Link to="/signup" className="btn  bg--red-f037 text--white border--2 border--red-f037 sf--t-medium radius--16-0">
                    Try for 14 days free
                  </Link>
                  <Link
                    to="/pricing"
                    className="btn btn  bg--transparent text--red-f037 border--2 border--red-f037 sf--t-medium radius--16-0 hover-text--red-f037">
                    See Pricing
                  </Link>
                </div>
              </div>
              <div className="col-md-7">
                <div className="radius--30">
                  <VideoAutoPlay />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="as-seen-logo-sec ">
          <div className="container">
            <div className=" text-center text--black font--36 lh--42 sf--d-semibold mb--8 font--sm-30 lh--sm-42">As Seen on</div>

            <div className="d-flex flex-wrap company-logo-main mt-5 justify-content-center">
              <span className="   ">
                <a href={void 0}>
                  <img
                    src="./../assets/images/home-page-img/seen_1.png"
                    className="pt--5 pb--5 pr--15 pl--15 h--110  w--200 object-fit--contain h--sm-130 "
                    alt="1"
                  />
                </a>
              </span>
              <span className="   ">
                <a href={void 0}>
                  <img
                    src="./../assets/images/home-page-img/seen_2.png"
                    className="pt--5 pb--5 pr--15 pl--15 h--110  w--200 object-fit--contain h--sm-130"
                    alt="1"
                  />
                </a>
              </span>
              <span className="  ">
                <a href={void 0}>
                  <img
                    src="./../assets/images/home-page-img/seen_3.png"
                    className="pt--5 pb--5 pr--15 pl--15 h--110  w--200 object-fit--contain h--sm-130"
                    alt="1"
                  />
                </a>
              </span>
              <span className="  ">
                <a href={void 0}>
                  <img
                    src="./../assets/images/home-page-img/seen_4.png"
                    className="pt--5 pb--5 pr--15 pl--15 h--110  w--200 object-fit--contain h--sm-130"
                    alt="1"
                  />
                </a>
              </span>
              <span className="  ">
                <a href={void 0}>
                  <img
                    src="./../assets/images/home-page-img/seen_5.png"
                    className="pt--5 pb--5 pr--15 pl--15 h--110  w--200 object-fit--contain h--sm-130"
                    alt="1"
                  />
                </a>
              </span>
              <span className="  ">
                <a href={void 0}>
                  <img
                    src="./../assets/images/home-page-img/seen_6.png"
                    className="pt--5 pb--5 pr--15 pl--15 h--110  w--200 object-fit--contain h--sm-130 "
                    alt="1"
                  />
                </a>
              </span>
            </div>
          </div>
        </section>

        {openModal && <NxaVideoModal openModal={openModal} closeModal={this.closeModal} />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    social_signup: state?.users?.social_signup ?? {},
    account_details: state?.users?.check_account ?? {},
    get_user_country: state?.users?.get_user_country ?? {},
  };
};

export default connect(mapStateToProps)(withRouter(InnerHome));
