import React, { useState } from "react";
import { Link } from "react-router-dom";

const NxaFAQ = () => {
  return (
    <div className="pricing-sec-1 pricing-page-faq">
      <div className="container">
        <h2 className="font--31 color-293F  fw--400">NxaTel Pricing FAQs</h2>
        <div className="row">
          <div className="col-md-4">
            <div className="pricing-sec-box d-flex">
              <div className=" ">
                <div className="pricing-collapse-box">
                  <div className="number-of-box">
                    <h6 className="text--orange">1</h6>
                  </div>
                  <a
                    class="font-18 fw--400 color-293F lh--28"
                    data-toggle="collapse"
                    href="#collapseExample"
                    onMouseEnter={(e) => e.target?.click()}
                    onMouseLeave={(e) => {
                      // const parent = e.target?.parentElement?.parentElement?.childNodes;
                      // const ref = parent?.[1];
                      // if (ref && ref?.classList?.contains("collapsing")) {
                      setTimeout(() => {
                        e.target?.click();
                      }, 350);
                      // } else {
                      //   e.target?.click();
                      // }
                    }}
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample">
                    How do I get started
                    <br /> with Nxatel?
                  </a>
                </div>
                <div class={`collapse `} id="collapseExample">
                  <p className="font-15 color-8087 lh--24">
                    To purchase Nxatel, select online the suitable package for your business needs and click and pay easily within 3 mins or contact
                    our sales team at (27) 87 930 3000, chat with us, or request a demo. We’ll discuss your business needs to suggest the best
                    solution for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="pricing-sec-box d-flex">
              <div className=" ">
                <div className="pricing-collapse-box">
                  <div className="number-of-box">
                    <h6 className="text--orange">2</h6>
                  </div>
                  <a
                    class="font-18 fw--400 color-293F lh--28"
                    data-toggle="collapse"
                    href="#collapseExample2"
                    onMouseEnter={(e) => e.target?.click()}
                    onMouseLeave={(e) => {
                      // const parent = e.target?.parentElement?.parentElement?.childNodes;
                      // const ref = parent?.[1];
                      // if (ref && ref?.classList?.contains("collapsing")) {
                      setTimeout(() => {
                        e.target?.click();
                      }, 350);
                      // } else {
                      //   e.target?.click();
                      // }
                    }}
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample2">
                    How much is business <br />
                    phone service?
                  </a>
                </div>
                <div class={`collapse `} id="collapseExample2">
                  <p className="font-15 color-8087 lh--24">
                    Phone service for your business starts at R99 per month per user, depending on the number of users and contract length. To
                    complement your cloud-hosted VoIP phone system, you also get access to an entire suite of business applications to maximise your
                    productivity.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="pricing-sec-box d-flex">
              <div className=" ">
                <div className="pricing-collapse-box">
                  <div className="number-of-box">
                    <h6 className="text--orange">3</h6>
                  </div>
                  <a
                    class="font-18 fw--400 color-293F lh--28"
                    data-toggle="collapse"
                    href="#collapseExample3"
                    onMouseEnter={(e) => e.target?.click()}
                    onMouseLeave={(e) => {
                      // const parent = e.target?.parentElement?.parentElement?.childNodes;
                      // const ref = parent?.[1];
                      // if (ref && ref?.classList?.contains("collapsing")) {
                      setTimeout(() => {
                        e.target?.click();
                      }, 350);
                      // } else {
                      //   e.target?.click();
                      // }
                    }}
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample3">
                    Does Nxatel sell or <br />
                    lease IP phones?
                  </a>
                </div>
                <div class={`collapse `} id="collapseExample3">
                  <p className="font-15 color-8087 lh--24">
                    Yes! With Nxatel, you can choose to buy or rent many high-performance IP phones that work perfectly in your business. We offer
                    many IP devices to purchase or lease, including desk phones, cordless phones, conference phones, and headsets. The best part is
                    that we pre-configure the devices, so you can use them instantly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-4">
            <div className="pricing-sec-box d-flex">
              <div className=" ">
                <div className="pricing-collapse-box">
                  <div className="number-of-box">
                    <h6 className="text--orange">4</h6>
                  </div>
                  <a
                    class="font-18 fw--400 color-293F lh--28"
                    data-toggle="collapse"
                    href="#collapseExample4"
                    onMouseEnter={(e) => e.target?.click()}
                    onMouseLeave={(e) => {
                      // const parent = e.target?.parentElement?.parentElement?.childNodes;
                      // const ref = parent?.[1];
                      // if (ref && ref?.classList?.contains("collapsing")) {
                      setTimeout(() => {
                        e.target?.click();
                      }, 350);
                      // } else {
                      //   e.target?.click();
                      // }
                    }}
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample4">
                    What are my payment <br />
                    options?
                  </a>
                </div>
                <div class={`collapse `} id="collapseExample4">
                  <p className="font-15 color-8087 lh--24">
                    We accept all major credit or debit cards. Optionally, you may switch to debit order after you complete the initial payment. You
                    can also choose to pay on a monthly or annual basis.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="pricing-sec-box d-flex">
              <div className=" ">
                <div className="pricing-collapse-box">
                  <div className="number-of-box">
                    <h6 className="text--orange">5</h6>
                  </div>
                  <a
                    class="font-18 fw--400 color-293F lh--28"
                    data-toggle="collapse"
                    href="#collapseExample5"
                    onMouseEnter={(e) => e.target?.click()}
                    onMouseLeave={(e) => {
                      // const parent = e.target?.parentElement?.parentElement?.childNodes;
                      // const ref = parent?.[1];
                      // if (ref && ref?.classList?.contains("collapsing")) {
                      setTimeout(() => {
                        e.target?.click();
                      }, 350);
                      // } else {
                      //   e.target?.click();
                      // }
                    }}
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample5">
                    Can I buy features à la <br />
                    carte?
                  </a>
                </div>
                <div class={`collapse `} id="collapseExample5">
                  <p className="font-15 color-8087 lh--24">
                    Yes. Our software is a modern communication tool for your business consolidating phone, meetings, collaboration tools, message,
                    cloud storage and more for one low price. If you have specific needs, call us at (27) 87 930 3000 so we can customise the Nxatel
                    platform for your business.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="pricing-sec-box d-flex">
              <div className=" ">
                <div className="pricing-collapse-box">
                  <div className="number-of-box">
                    <h6 className="text--orange">6</h6>
                  </div>
                  <a
                    class="font-18 fw--400 color-293F lh--28"
                    data-toggle="collapse"
                    href="#collapseExample6"
                    onMouseEnter={(e) => e.target?.click()}
                    onMouseLeave={(e) => {
                      // const parent = e.target?.parentElement?.parentElement?.childNodes;
                      // const ref = parent?.[1];
                      // if (ref && ref?.classList?.contains("collapsing")) {
                      setTimeout(() => {
                        e.target?.click();
                      }, 350);
                      // } else {
                      //   e.target?.click();
                      // }
                    }}
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample6">
                    What's included with
                    <br /> every plan?
                  </a>
                </div>
                <div class={`collapse `} id="collapseExample6">
                  <p className="font-15 color-8087 lh--24">
                    Nxatel provides every customer with unlimited 24/7 live support. Every plan includes an ultra-high uptime of 99.999%. We actively
                    monitor our network around-the-clock for maximum security and reliability. But that's just the start.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="pricing-sec-box d-flex">
              <div className=" ">
                <div className="pricing-collapse-box">
                  <div className="number-of-box">
                    <h6 className="text--orange">7</h6>
                  </div>
                  <a
                    class="font-18 fw--400 color-293F lh--28"
                    data-toggle="collapse"
                    href="#collapseExample7"
                    onMouseEnter={(e) => e.target?.click()}
                    onMouseLeave={(e) => {
                      // const parent = e.target?.parentElement?.parentElement?.childNodes;
                      // const ref = parent?.[1];
                      // if (ref && ref?.classList?.contains("collapsing")) {
                      setTimeout(() => {
                        e.target?.click();
                      }, 350);
                      // } else {
                      //   e.target?.click();
                      // }
                    }}
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample7">
                    Do you offer monthly or <br />
                    annual plans?
                  </a>
                </div>
                <div class={`collapse }`} id="collapseExample7">
                  <p className="font-15 color-8087 lh--24">
                    Nxatel offers many flexible options, all built to meet your needs. You can choose a month to month plan with no commitment. Get
                    more savings by committing to our flexible contract terms for 12, 24, or 36 months.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="pricing-sec-box d-flex">
              <div className=" ">
                <div className="pricing-collapse-box">
                  <div className="number-of-box">
                    <h6 className="text--orange">8</h6>
                  </div>
                  <a
                    class="font-18 fw--400 color-293F lh--28"
                    data-toggle="collapse"
                    href="#collapseExample8"
                    onMouseEnter={(e) => e.target?.click()}
                    onMouseLeave={(e) => {
                      // const parent = e.target?.parentElement?.parentElement?.childNodes;
                      // const ref = parent?.[1];
                      // if (ref && ref?.classList?.contains("collapsing")) {
                      setTimeout(() => {
                        e.target?.click();
                      }, 350);
                      // } else {
                      //   e.target?.click();
                      // }
                    }}
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample8">
                    Can I change plans?
                  </a>
                </div>
                <div class={`collapse }`} id="collapseExample8">
                  <p className="font-15 color-8087 lh--24">
                    Yes, anytime. We design our plans to grow with your company as it grows and to fit your needs at every stage. If you find your
                    current plan no longer suits your needs, reach out to your Client Success Manager or call us at (27) 87 930 3000 to adjust your
                    plan.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="pricing-sec-box d-flex">
              <div className=" ">
                <div className="pricing-collapse-box">
                  <div className="number-of-box">
                    <h6 className="text--orange">9</h6>
                  </div>
                  <a
                    class="font-18 fw--400 color-293F lh--28"
                    data-toggle="collapse"
                    href="#collapseExample9"
                    onMouseEnter={(e) => e.target?.click()}
                    onMouseLeave={(e) => {
                      // const parent = e.target?.parentElement?.parentElement?.childNodes;
                      // const ref = parent?.[1];
                      // if (ref && ref?.classList?.contains("collapsing")) {
                      setTimeout(() => {
                        e.target?.click();
                      }, 350);
                      // } else {
                      //   e.target?.click();
                      // }
                    }}
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample9">
                    What makes Nxatel
                    <br /> different?
                  </a>
                </div>
                <div class={`collapse `} id="collapseExample9">
                  <p className="font-15 color-8087 lh--24">
                    First, we provide you the perfect blend of guidance, training, and support from day one. From porting your existing phone numbers
                    with ease through our digital process to helping your company scale with enterprise-ready professional services, we've got you
                    covered.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* partner logo */}
        <div className="sign-up-faq-partner-logo">
          <Link to="#" className="">
            <img src="./../assets/images/Method=Visa.svg" alt="logo" />
          </Link>
          <Link to="#" className="">
            <img src="./../assets/images/Mastercard.svg" alt="logo" />
          </Link>
          <Link to="#" className="">
            <img src="./../assets/images/Method=Stripe.svg" alt="logo" />
          </Link>
          <Link to="#" className="">
            <img src="./../assets/images/Ozwo Pay 1.svg" alt="logo" />
          </Link>
          {/* <Link to="#" className="">
            <img src="./../assets/images/voucher-log.svg" alt="logo" />
          </Link> */}
          {/* <Link to="#" className="">
            <img src="./../assets/images/blue-voucher.svg" alt="logo" />
          </Link> */}
          <Link to="#" className="">
            <img src="./../assets/images/nxa-voucher.svg" alt="logo" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NxaFAQ;
