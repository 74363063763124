import * as React from "react";
import { useRoutes } from "react-router-dom";

import Home from "../Pages/Home";
import LoginSignup from "../Pages/LoginSignup";
import ForgotPassword from "../Pages/LoginSignup/ForgotPassword";
import ForgotEmail from "../Pages/LoginSignup/ForgotEmail";
import NumberVerification from "../Pages/LoginSignup/NumberVerification";
import Call from "../Pages/Product/Call";
import InnerHome from "../Pages/InnerHome";
import Meet from "../Pages/Product/Meet";
import Message from "../Pages/Product/Message";
import NxaDocs from "../Pages/Product/NxaDocs";
import Cloud from "../Pages/Product/Cloud";
import WhyNxatel from "../Pages/WhyNxatel";
import Privacy from "../Pages/Privacy";
import GetDemoBooking from "../Pages/GetDemoBooking";
import Pricing from "../Pages/Pricing";
import ResetPassword from "../Pages/LoginSignup/ResetPassword";
import FetchPayment from "../Pages/LoginSignup/FetchPayment";
import Terms from "../Pages/Terms";
import AboutUs from "../Pages/AboutUs";
import Solution from "../Pages/LoginSignup/Solution";
import MobilePrivacy from "../Pages/MobilePrivacy";

export const AppRouter = (props) => {
  const routes = [
    {
      path: "/",
      element: <Home />,
      children: [
        // The index route defines what should be displayed on the default route i.e. '/'
        {
          index: true,
          element: <InnerHome {...props} />,
        },
        {
          path: "calls",
          element: <Call />,
        },
        {
          path: "meetings",
          element: <Meet />,
        },
        {
          path: "message",
          element: <Message />,
        },
        {
          path: "nxa-docs",
          element: <NxaDocs />,
        },
        {
          path: "business-cloud",
          element: <Cloud />,
        },
        {
          path: "why-nxatel",
          element: <WhyNxatel />,
        },
        {
          path: "privacy",
          element: <Privacy />,
        },
         {
          path: "pricing",
          element: <Pricing />,
        },
        {
          path: "terms",
          element: <Terms />,
        },
        {
          path: "demo",
          element: <GetDemoBooking />,
        },
        {
          path: "about-us",
          element: <AboutUs />,
        },
        {
          path: "solution",
          element: <Solution />,
        },
      ],
    },
    {
      path: "/",
      element: <LoginSignup />,
      children: [
        {
          path: "login",
          element: <LoginSignup />,
        },
        {
          path: "signup",
          element: <LoginSignup />,
        },
        {
          path: "registration/:token",
          element: <LoginSignup />,
        },
        {
          path: "reset-password-step2/:token",
          element: <ResetPassword />,
        },
      ],
    },
    {
      path: "mobile-privacy",
      element: <MobilePrivacy />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/forgot-email",
      element: <ForgotEmail />,
    },
    {
      path: "/verify-number",
      element: <NumberVerification />,
    },
    {
      path: "/fetch-payment/:token",
      element: <FetchPayment />,
    },
  ];

  // The useRoutes() hook allows you to define your routes as JavaScript objects
  // instead of <Routes> and <Route> elements. This is really just a style
  // preference for those who prefer to not use JSX for their routes config.
  return useRoutes(routes);
};
