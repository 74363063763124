import React from "react";

const Solution = () => {
  return (
    <>
      <div className="container">
        <div className="d-flex pt--40 pb--40 justify-content-between ">
          <img
            src="./../assets/images/solution_page/solution-banner.png"
            alt="1"
            className="w-50 img-fluid"
          />

          <div className="d-flex flex-column align-items-center  justify-content-center">
            <div className="text--blue-1d3f font--36 sf--d-bold  mt--15 lh--52 w-80">
              Nxatel all in one Office Solutions
            </div>
            <div className="text--gray-7c87 font--16 sf--t-regular  mt--15  w-80">
              Integrated phone, video conferencing, Team messaging & SMS’s and
              Cloud Storage
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="pt--40 pb--40">
          <div className="d-grid grid--column-3 gap--25 gap--row-30 mt--30">
            <div class="box-shadow-3 bg--white radius--4 pt--50 pb--50 pr--50 pl--30 gap--20  ">
              <div class="text--blue-1d3f font--20 sf--t-regular fw--700 ">
                Call
              </div>
              <div class="text--gray-7c87 font--18 sf--d-regular mt--5">
                Cloud phone system with 50+ robust features
              </div>
            </div>
            <div class="box-shadow-3 bg--white radius--4 pt--50 pb--50 pr--50 pl--30 gap--20  ">
              <div class="text--blue-1d3f font--20 sf--t-regular fw--700 ">
                Meet
              </div>
              <div class="text--gray-7c87 font--18 sf--d-regular mt--5">
                Virtual meetings solution with HD video and screen sharing{" "}
              </div>
            </div>
            <div class="box-shadow-3 bg--white radius--4 pt--50 pb--50 pr--50 pl--30 gap--20  ">
              <div class="text--blue-1d3f font--20 sf--t-regular fw--700 ">
                Message
              </div>
              <div class="text--gray-7c87 font--18 sf--d-regular mt--5">
                SMS and Team messaging seamless collaboration with files, tasks,
                and more
              </div>
            </div>
            <div class="box-shadow-3 bg--white radius--4 pt--50 pb--50 pr--50 pl--30 gap--20  ">
              <div class="text--blue-1d3f font--20 sf--t-regular fw--700 ">
                NxaDox
              </div>
              <div class="text--gray-7c87 font--18 sf--d-regular mt--5">
                Team messaging and collaboration with files, tasks, and more
              </div>
            </div>
            <div class="box-shadow-3 bg--white radius--4 pt--50 pb--50 pr--50 pl--30 gap--20  ">
              <div class="text--blue-1d3f font--20 sf--t-regular fw--700 ">
                Business Cloud
              </div>
              <div class="text--gray-7c87 font--18 sf--d-regular mt--5">
                Secure Cloud Storage, seamless collaboration wit
              </div>
            </div>
            <div class="box-shadow-3 bg--white radius--4 pt--50 pb--50 pr--50 pl--30 gap--20  ">
              <div class="text--blue-1d3f font--20 sf--t-regular fw--700 ">
                Business Internet
              </div>
              <div class="text--gray-7c87 font--18 sf--d-regular mt--5">
                NEW! Improved, and all you need to work from anywhere
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center gap--48  pt--40 pb--40 flex--sm-column ">
          <img
            src="./../assets/images/about_page/1.png"
            alt="1"
            className="w-50 img-fluid"
          />

          <div class="text--sm-center w-50">
            <div class=" m--sm-auto">
              <div className="text--blue-1d3f font--36 sf--d-bold  mt--15 lh--40 w-80">
                By Business type
              </div>
              <div className="text--gray-7c87 font--16 sf--t-regular    w-80">
                Startup Small Business Enterprise
              </div>
            </div>
            <div class=" m--sm-auto mt--30">
              <div className="text--blue-1d3f font--36 sf--d-medium  mt--15 lh--40 w-80">
                By Solution
              </div>
              <div className="text--gray-7c87 font--16 sf--t-regular    w-80">
                Cloud Phone System Virtual Offlice Line Remote Solution for
                Teams Small Business Phone System Outbound Call Solution
                Software Inbound Call Centre Software
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Solution;
