import { Modal, Switch } from "@material-ui/core";
import React from "react";

const feature_groups = [
  {
    feature_group_name: "Design systems",
    feature_group_descr: "Any editor can upgrade a team to the Professional plan by entering payment information",
    features: ["Organization-wide design systems", "Auto layout"],
  },
  {
    feature_group_name: "Calls",
    feature_group_descr: "This will make them a team admin",
    features: [
      "Components",
      "Multiplayer",
      "On-canvas commenting",
      "Activity + Retention Insights",
      "Cohort based events predictions",
      "Saved reports",
      "Excel/PDF report export",
    ],
  },
  {
    feature_group_name: "Meet",
    feature_group_descr: "Any editor can upgrade a team to the Professional plan by entering payment information",
    features: ["Google Firebase", "Google Ads", "Adjust", "Appsflyer", "Facebook Ads"],
  },
  {
    feature_group_name: "Team libraries",
    feature_group_descr: "Any editor can upgrade a team to the Professional plan by entering payment information",
    features: ["Events included"],
  },
  {
    feature_group_name: "Design systems",
    feature_group_descr: "Any editor can upgrade a team to the Professional plan by entering payment information",
    features: [
      "Retention booster",
      "Channel control for Google Ads",
      "Complex conversions",
      "Proxy events without coding",
      "Smart conversion bidding",
    ],
  },
  {
    feature_group_name: "Team libraries",
    feature_group_descr: "This will make them a team admin",
    features: [
      "Developer handoff, Advanced drawing tools, Third-party integrations",
      "Centralized administration, Domain capture, Full content ownership",
      "Design system analytics",
      "Data and features export",
    ],
  },
  {
    feature_group_name: "Design systems",
    feature_group_descr: "This will make them a team admin",
    features: ["Client support", "Data Analyst support", "Data Scientist support"],
  },
];

const plans = [
  {
    name: "Starter",
    plan_features: {
      "Design systems": {
        "Organization-wide design systems": {
          exists: true,
        },
        "Auto layout": {
          exists: true,
        },
        "Retention booster": { exists: true },
        "Channel control for Google Ads": { exists: true },
        "Complex conversions": { exists: true },
        "Proxy events without coding": { exists: false },
        "Smart conversion bidding": { exists: false },
        "Client support": { exists: false, header: "Basic", descr: "email & chat" },
        "Data Analyst support": { exists: false },
        "Data Scientist support": { exists: false },
      },
      Calls: {
        Components: {
          exists: true,
        },
        Multiplayer: {
          exists: true,
        },
        "On-canvas commenting": {
          exists: true,
        },
        "Activity + Retention Insights": {
          exists: true,
        },
        "Cohort based events predictions": {
          exists: true,
        },
        "Saved reports": {
          exists: true,
        },
        "Excel/PDF report export": {
          exists: true,
        },
      },
      Meet: {
        "Google Firebase": { exists: true },
        "Google Ads": { exists: false },
        Adjust: { exists: false },
        Appsflyer: { exists: false },
        "Facebook Ads": { exists: true },
      },
      "Team libraries": {
        "Events included": {
          exists: true,
        },
        "Developer handoff, Advanced drawing tools, Third-party integrations": { exists: true },
        "Centralized administration, Domain capture, Full content ownership": { exists: false },
        "Design system analytics": { exists: false },
        "Data and features export": { exists: false },
      },
    },
  },
  {
    name: "Professional",
    plan_features: {
      "Design systems": {
        "Organization-wide design systems": {
          exists: true,
        },
        "Auto layout": {
          exists: true,
        },
        "Retention booster": { exists: true },
        "Channel control for Google Ads": { exists: true },
        "Complex conversions": { exists: false },
        "Proxy events without coding": { exists: true },
        "Smart conversion bidding": { exists: true },
        "Client support": { exists: false, header: "Advanced", descr: "email/phone" },
        "Data Analyst support": { exists: false, header: "6 hours", descr: "" },
        "Data Scientist support": { exists: false },
      },
      Calls: {
        Components: {
          exists: true,
        },
        Multiplayer: {
          exists: true,
        },
        "On-canvas commenting": {
          exists: true,
        },
        "Activity + Retention Insights": {
          exists: true,
        },
        "Cohort based events predictions": {
          exists: true,
        },
        "Saved reports": {
          exists: true,
        },
        "Excel/PDF report export": {
          exists: true,
        },
      },
      Meet: {
        "Google Firebase": { exists: true },
        "Google Ads": { exists: true },
        Adjust: { exists: true },
        Appsflyer: { exists: false },
        "Facebook Ads": { exists: true },
      },
      "Team libraries": {
        "Events included": {
          exists: false,
          header: "5 min",
          descr: "additional 20$ per 10 000 items",
        },
        "Developer handoff, Advanced drawing tools, Third-party integrations": { exists: false },
        "Centralized administration, Domain capture, Full content ownership": { exists: true },
        "Design system analytics": { exists: true },
        "Data and features export": { exists: true },
      },
    },
  },
  {
    name: "Enterprise",
    plan_features: {
      "Design systems": {
        "Organization-wide design systems": {
          exists: true,
        },
        "Auto layout": {
          exists: true,
        },
        "Retention booster": { exists: true },
        "Channel control for Google Ads": { exists: true },
        "Complex conversions": { exists: true },
        "Proxy events without coding": { exists: true },
        "Smart conversion bidding": { exists: true },
        "Client support": { exists: false, header: "High priority", descr: "email/phone" },
        "Data Analyst support": { exists: false, header: "10 hours", descr: "" },
        "Data Scientist support": { exists: false, header: "6 hours", descr: "" },
      },
      Calls: {
        Components: {
          exists: true,
        },
        Multiplayer: {
          exists: true,
        },
        "On-canvas commenting": {
          exists: true,
        },
        "Activity + Retention Insights": {
          exists: true,
        },
        "Cohort based events predictions": {
          exists: true,
        },
        "Saved reports": {
          exists: true,
        },
        "Excel/PDF report export": {
          exists: true,
        },
      },
      Meet: {
        "Google Firebase": { exists: true },
        "Google Ads": { exists: true },
        Adjust: { exists: true },
        Appsflyer: { exists: true },
        "Facebook Ads": { exists: true },
      },
      "Team libraries": {
        "Events included": {
          exists: false,
          header: "20 min",
          descr: "additional 150$ per 10 000 items",
        },
        "Developer handoff, Advanced drawing tools, Third-party integrations": { exists: true },
        "Centralized administration, Domain capture, Full content ownership": { exists: true },
        "Design system analytics": { exists: true },
        "Data and features export": { exists: true },
      },
    },
  },
];

const PlanComparisonModal = ({ showComparisonModal, setShowComparisonModal }) => {
  return (
    <Modal open={showComparisonModal} onClose={() => setShowComparisonModal(false)}>
      <div className="w-100 h-100 d-flex align-items-center justify-content-center subscriptions-plan-modal">
        <div className="w-100 bg--white subscriptions-plan-modal-content radius--10">
          <div class="subscriptions-plan-modal-header border--bottom-1 p-3 radius--top-left-right-10">
            <button type="button" class="close" onClick={() => setShowComparisonModal(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="font--18 text--black mb-0">Subscriptions plans</h4>
          </div>
          <div class="subscriptions-plan-modal-body  p--30">
            <div className="subscriptions-plan-section">
              {/* header start here */}
              <div className="subscriptions-plan-header  d-flex align-items-center gap--15">
                <div className="subscription-left-content w--260 w--min-220 d-flex align-items-center gap--10">
                  <span className="font--12 text--gray-64">Annual</span>
                  <Switch defaultChecked />
                  <span className="font--12 text--black">Monthly</span>
                </div>
                <div className="subscription-right-content w-100 d-flex align-items-center gap--15">
                  <div className="w-100 w--min-220 text-left "></div>
                  {plans.map(({ name }) => {
                    return (
                      <div className="w-100 w--min-220 text-center">
                        <h3 className="font--16 text--black fw--600">{name}</h3>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* container start here */}
              <div className="subscriptions-plan-container   ">
                {feature_groups.map((feature_group) => {
                  const { feature_group_descr, feature_group_name, features } = feature_group;
                  return (
                    <div className="subscriptions-plan-box w-100 d-flex align-items-start gap--15 mt--15 pb--15 border--bottom-1 border--black">
                      <div className="subscription-left-content w--260 w--min-220">
                        <h3 className="font--16 text--black plan-title position-relative pl--15">{feature_group_name}</h3>
                        <p className="font--12 text--gray-97 lh--19">{feature_group_descr}</p>
                      </div>
                      <div className="subscription-right-content w-100">
                        {features.map((feature, index) => {
                          return (
                            <div
                              className={`subscription-right-content-row w-100 d-flex align-items-start gap--15 ${
                                features.length - 1 !== index ? " border--bottom-1 border--light-gray-ee" : ""
                              } p-2`}>
                              <div className="w-100 w--min-220 text-left ">
                                <p className="font--14 mb-0 text--black">{feature}</p>
                              </div>
                              {plans.map(({ name }) => {
                                const planName = name;
                                const planData = plans.find((item) => item.name === planName);

                                const feature_data = planData.plan_features?.[feature_group_name]?.[feature];
                                return (
                                  <div className="w-100 w--min-220 text-center">
                                    {feature_data?.exists ? (
                                      <img src="/assets/images/tickcircle.svg" className="w--mx-25" />
                                    ) : feature_data.header ? (
                                      <>
                                        {feature_data.header && (
                                          <h3 className="font--14 text--black plan-title position-relative mb--5">{feature_data.header}</h3>
                                        )}
                                        {feature_data.descr && <p className="font--12 text--gray-97 lh--19">{feature_data.descr}</p>}
                                      </>
                                    ) : (
                                      <img src="/assets/images/crossRed.svg" className="w--mx-25" />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* footer start here */}
              <div className="subscriptions-plan-footer  d-flex align-items-start gap--15 mt--15 pt--12 pb--12 bg--white">
                <div className="subscription-left-content w--260 w--min-220 d-flex align-items-start gap--10">
                  <h3 className="font--14 text--black plan-title position-relative mb--5">Price</h3>
                </div>
                <div className="subscription-right-content w-100 d-flex align-items-start gap--15">
                  <div className="w-100 w--min-220 text-left ">
                    <p className="font--12 text--black lh--19 mb-0">Billed monthly, from</p>
                  </div>
                  <div className="w-100 w--min-220 text-center">
                    <div className="w-100 h--min-65 text-center">
                      <h3 className="font--16 text--black fw--600 mb--5">300$/month</h3>
                      <p className="font--12 mb--10"></p>
                    </div>
                    <span class="btn bg--blue-45fe border--blue-45fe pt--5 pb--5 pr--20 pl--20 radius--8 font--15 text--white dm--sans fw--700">
                      Order
                    </span>
                  </div>
                  <div className="w-100 w--min-220 text-center">
                    <div className="w-100 h--min-65 text-center">
                      <h3 className="font--16 text--black fw--600 mb--5">119$/month</h3>
                      <p className="font--12 mb--10">+ $100 for each next item</p>
                    </div>
                    <span class="btn bg--blue-45fe border--blue-45fe pt--5 pb--5 pr--20 pl--20 radius--8 font--15 text--white dm--sans fw--700">
                      Order
                    </span>
                  </div>
                  <div className="w-100 w--min-220 text-center">
                    <div className="w-100 h--min-65 text-center">
                      <h3 className="font--16 text--black fw--600 mb--5">3999$/month</h3>
                      <p className="font--12 mb--10">+ $100 for each next item</p>
                    </div>
                    <span class="btn bg--blue-45fe border--blue-45fe pt--5 pb--5 pr--20 pl--20 radius--8 font--15 text--white dm--sans fw--700">
                      Order
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PlanComparisonModal;
