import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContactBar from "./ContactBar";
import PageHeader from "./PageHeader";
import ProgressBar from "./ProgressBar";
import { store } from "../../Services";
import {
  createCustomerDatabase,
  _show_payment,
} from "../../Services/Actions/LoginSignupActions";
import { connect } from "react-redux";
import { ShowLoader } from "../../Services/Actions/userActions";
import LoaderPage from "../../Component/Loader/LoaderPage";

const account_token = localStorage.getItem("token");

const FetchPayment = (props) => {
  const [state, setState] = useState({
    app_token: "",
    isLoading: false,
    onMobile: false,
  });
  const navigate = useNavigate();

  //   const { mutateAsync: create_customer_db_mutate, isLoading: create_db_loader } = useMutation(["create-customer-db"], _create_customer_db, {
  //     onSuccess: ({ ResponseCode, ResponseText, token, data }) => {
  //       if (ResponseCode === "1") {
  //         localStorage.setItem("token", token);
  //         return 1;
  //       } else if (ResponseCode === "0") {
  //         swal({
  //           title: "Error!",
  //           text: ResponseText,
  //           icon: "error",
  //           buttons: true,
  //           dangerMode: true,
  //         }).then((willDelete) => {
  //           if (willDelete) {
  //             if (data?.db_name) {
  //               window.location.href = `https://${AppDomain}/rica-process`;
  //             } else {
  //               console.log("unable to create db");
  //               return 0;
  //             }
  //           } else {
  //             console.log("you clicked on false");
  //           }
  //         });
  //         return 0;
  //       }
  //     },
  //   });
  useEffect(() => {
    window.scrollTo(0, 0);
    _show_payment(
      {
        token: window.location.pathname.split("/").pop(),
        callback_url: window.location.origin + "/",
      },
      store.dispatch
    );
    let data = window;
    if (navigator.userAgent.includes("Android")) {
      data = document;
    }
    data.addEventListener("message", (message) => {
      if (typeof message?.data === "string") {
        setState((state) => ({ ...state, app_token: message?.data }));
      }
    });
    // loadCssFile("./../assets/css/nxa-outer.css", "nxa-outer");
    return () => {
      window.$("#nxa-outer").remove();
    };
  }, []);

  const onNavigateHandler = async () => {
    const { status } = props?.payment_info || {};
    const { app_token } = state;
    if (status === "S") {
      setState({ ...state, isLoading: true, onMobile: true });
      const response = await createCustomerDatabase(app_token, store.dispatch);
      if (response) {
        setState({ ...state, isLoading: false });
      }
      if (response === 1) {
        navigate("/rica-process");
        MobileMessageSend({
          status: "success",
          token: localStorage.getItem("token"),
        });
        setTimeout(() => {
          ShowLoader(false);
        }, 2000);
      }
    } else if (status === "F") {
      navigate("/order-summary");
      MobileMessageSend({
        status: "failure",
        token: localStorage.getItem("token"),
      });
      setTimeout(() => {
        ShowLoader(false);
      }, 2000);
    } else {
      navigate("/order-summary");
      MobileMessageSend({
        status: "processing",
        token: localStorage.getItem("token"),
      });
      setTimeout(() => {
        ShowLoader(false);
      }, 2000);
    }
  };

  const MobileMessageSend = (value) => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify(value));
    } catch (error) {}
  };
  const {
    status,
    firstname = "unknown",
    lastname = "unknown",
    user_plan,
    currency,
    amount: plan_cost = 0,
    trial,
    trial_amount = 0,
    did_number = "",
    duration = 1,
    message = "",
  } = props?.payment_info || {};
  const {
    voice_minute = 0,
    participants_for_video_confrence = 0,
    storage = 0,
    annual_plan_discount = 0,
    two_year_plan_discount = 0,
  } = user_plan || {};

  if (state.app_token && state.onMobile) {
    // showing loader for the nxatel app
    <LoaderPage type="account-setup" name={`${firstname} ${lastname}`} />;
  }

  if (state.isLoading) {
    return (
      <div className="pricing-step">
        {account_token && (
          <div className="sign-up-step-bar">
            <div className="container">
              <PageHeader />
              <ContactBar />
              <ProgressBar active="order-page" />
              <LoaderPage
                type="account-setup"
                name={`${firstname} ${lastname}`}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="pricing-step pb-5">
      {account_token && (
        <div className="sign-up-step-bar">
          <div className="container">
            <PageHeader />
            <ContactBar />
            <ProgressBar active="order-page" />
          </div>
        </div>
      )}
      <div className="business-number successful-img  text-center pt-5 pb-4">
        <h1 className="font-36 mb-4 color-7c2b fw-700">
          {status === "S" &&
            `Hi ${firstname} ${lastname} , Your payment was successful.`}
          {(status === "P" || status === "F") &&
            `Hi ${firstname} ${lastname} , Your payment was unsuccessful.`}
        </h1>
        {/* {(status === "P" || status === "F") && (
            <>
              {message && (
                <p className="order-contact">
                  {`${
                    message.charAt(0).toUpperCase() + message.slice(1)
                  }, please contact`}
                  <a className="order-contact-link" onClick={zohoLiveChat}>
                    Nxatel Support
                  </a>
                </p>
              )}
            </>
          )} */}
        {status === "S" && (
          <img src="./../assets/images/signup/success-payment.png" alt="1" />
        )}
        {(status === "P" || status === "F") && (
          <img src="./../assets/images/signup/sad-emoji.svg" alt="1" />
        )}
      </div>
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4 text-center">
          <div className="payment-success-box">
            <div className="d-flex justify-content-center">
              <span className="font-20 color-3b vertical-align-top">
                {currency === "USD" ? "$" : "R"}
              </span>
              <h1 className="color-3b font-36 fw-700">
                {trial === "Y" ? trial_amount : plan_cost}
              </h1>
              <div className="form-group w-20% currency mb-0 align-item-center d-flex">
                <a className="color-293F p-0" href={void 0}>
                  <span
                    className={`color-7AFE lh--40 ${
                      trial === "Y" ? "payment-amount" : ""
                    }`}>
                    {/* /{duration === 1 ? "Monthly" : duration === 12 ? `12-month - discount upto ${parseInt(annual_plan_discount * 100)}%` : `24-month - discount upto ${parseInt(two_year_plan_discount * 100)}%`} */}
                    <br />
                    {trial === "Y" && <small>after 14-Days trial ends</small>}
                  </span>
                </a>
              </div>
            </div>
            <p className="font--14 color-6666 fw--400">
              <li>FREE Number {did_number}</li>
              {/* <li>{voice_minute} Minutes</li> */}
              <li>50 SMS</li>
              <li> Video Calling </li>
              {/* <li>{participants_for_video_confrence} Users</li> */}
              {/* <li>{storage} cloud Storage</li> */}
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-4 text-center">
        {status === "S" && (
          <button
            className="btn btn-pay"
            type="button"
            onClick={() => onNavigateHandler()}>
            Continue
          </button>
        )}
        {(status === "P" || status === "F") && (
          <button
            className="btn btn-pay"
            type="button"
            onClick={() => onNavigateHandler()}>
            Try Again
          </button>
        )}
      </div>
      {/* {payment_info_loader && <Loader />} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    payment_info: state?.users?.payment_info ?? {},
  };
};
export default connect(mapStateToProps)(FetchPayment);
