import swal from "sweetalert";
import { handleResponse } from "./userActions";
import { GET_PLAN_DETAILS, PLAN_LISTING_NEW, USERPANEL } from "../Constants/apiConstants";

export const getPlanDetails = (data, dispatch) => {
  // dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(`${process.env.REACT_APP_API_DOMAIN}${USERPANEL}/${GET_PLAN_DETAILS}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      console.log({ data });
      //   if (data.ResponseCode === "1") {
      //     localStorage.setItem("mbl_token", data?.request_id);
      //     return 1;
      //   } else {
      //     swal({
      //       title: "Error!",
      //       text: `${data.ResponseText}`,
      //       icon: "error",
      //     });
      //     return 0;
      //   }
    })
    .catch((error) => {
      swal({ title: "Error!", text: error, icon: "error" });
    });
};
export const getPlanListing = async (data) => {
  const request = new Request(`${process.env.REACT_APP_API_DOMAIN}${PLAN_LISTING_NEW}`, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
    body: JSON.stringify(data),
  });
  const response = await fetch(request);
  return await response.json();
};
