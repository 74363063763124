import React from "react";

function LoaderPage({ type , name }) {
  let message;
  switch (type) {
    case "initial":
      message = "Preparing your App";
      break;
    case "account-setup":
      message = `Hi ${name}, please wait while we set up your account`;
      break;
    default:
      message = "Preparing your App";
      break;
  }
  return (
    <>
      {(!type || type === "initial") && (
        <div className="loader-page">
          <a href={void 0} className="loader-logo">
            {" "}
            <img src="./../assets/images/loader-logo.svg" alt="1" />
          </a>
          <h3 className="loader-page-title">{message}</h3>
          <p>Please wait</p>
          <a href={void 0} className="loader-img">
            {" "}
            <img src="./../assets/images/loader-orange.gif" alt="1" />
          </a>
        </div>
      )}

      {(!type || type === "account-setup") && (
    
        <div className="loader-page">  
          <p>{message}</p>
          <a href={void 0} className="loader-img">
            {" "}
            <img src="./../assets/images/loader-orange.gif" alt="1" />
          </a>
        </div>
       
      )}
    </>
  );
}

export default LoaderPage;
