import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Layout/Footer";
import { connect } from "react-redux";
import { store } from "../../Services";
import Login from "./Login";
import Signup from "./Signup";
import Registration from "./Registration";
import {
  HomePageSubPages,
  HomePageSubTabs,
} from "../../Services/Actions/userActions";
import Loader from "../../Component/Loader";
import ResetPassword from "./ResetPassword";

const InitialState = { tab: "login", subTab: "" };
class LoginSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = InitialState;
  }

  componentDidMount() {
    const {
      nxatel: { sub_pages, sub_tabs },
    } = this.props;
    if (sub_pages && ["login", "join-meeting", "sign-up"].includes(sub_pages)) {
      if (sub_pages === "login") {
        this.setState({
          tab: "login",
        });
      } else if (sub_pages === "sign-up") {
        this.setState({ tab: "sign-up", subTab: sub_tabs ?? "" });
      }
    }
  }

  selectTab = (value) => {
    this.setState({ tab: value, subTab: "" }, () => {
      HomePageSubPages(value, store.dispatch);
    });
  };

  selectSubTab = (subTab) => {
    this.setState({ subTab }, () => {
      HomePageSubTabs(subTab, store.dispatch);
    });
  };

  render() {
    const { subTab } = this.state;
    const activeUrl = window.location.pathname.replace("/", "");
    return (
      <div className="h-100 overflow-auto">
        <div className="login-page signup-type">
          <div className="container">
            <nav className="navbar navbar-expand-lg mt--6">
              <Link className="navbar-brand  c--pointer" to="/">
                <img
                  src="./../assets/images/homepagelogo.png"
                  alt="logo"
                  className="w--147"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto ">
                  <li
                    className={`nav-item ${
                      activeUrl === "login" ? "active" : ""
                    }`}>
                    <Link
                      className="nav-link sf--t-regular text--white font--13 text-uppercase  pt--8 pb--8 pr--10 pl--10 radius--5 bg--red-f037 mr--30 m--sm-0"
                      to="/login"
                      onClick={() => this.selectTab("login")}>
                      Login
                      <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className={`nav-item`}>
                    <a
                      className="nav-link sf--t-regular hover-bg--red-f037 text--purple-3f4e hover-text--white font--13 text-uppercase pt--8 pb--8 pr--10 pl--10  radius--5 mr--30  m--sm-0"
                      href="https://support.nxa.tel/portal/">
                      Support
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            {activeUrl === "login" && <Login {...this.props} />}
            {activeUrl === "signup" && (
              <Signup
                {...this.props}
                selectSubTab={this.selectSubTab}
                subTab={subTab}
              />
            )}
            {activeUrl.includes("registration") && (
              <Registration {...this.props} />
            )}
            {activeUrl.includes("reset-password") && (
              <ResetPassword {...this.props} />
            )}
          </div>
        </div>
        <Loader />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nxatel: state?.users ?? [],
  };
};

export default connect(mapStateToProps)(LoginSignup);
