import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { withRouter } from "../../Utils/withRouter";

function PlanDetail({ plans_list, state, onPlanSelectHandler = () => null }) {
  const { selected_plan_id, selected_currency, selected_plan_duration } = state;
  const navigate = useNavigate();
  return (
    <div className="container">
      <div className="pricing pricing-bx pricing-palden pricing-palden-signup" style={{ marginBottom: "15px" }}>
        {plans_list instanceof Array &&
          plans_list.map((value, key) => {
            let planCost = {};
            if (selected_plan_duration === 1) {
              planCost = value?.monthly_cost;
            } else if (selected_plan_duration === 12) {
              planCost = value?.yearly_cost;
            } else {
              planCost = value?.twoYear_cost;
            }
            if (value.plan_name.includes("Enterprise")) {
              return (
                <div
                  className={`pricing-item justify-content-between ${value.planID === selected_plan_id ? "pricing__item--featured" : ""}`}
                  onClick={() => onPlanSelectHandler(value)}
                  key={key}
                  style={{ position: "relative" }}>
                  <div className="pricing-min-ht">
                    <div className="pricing-deco">
                      <div className="pricing-price">
                        <h6 className="fw-700 start-up-head color-66  mb-2">{value.plan_name}</h6>
                      </div>
                      <p className="font--16 fw--400 color-66  mb-1">All our enterprise-ready features.</p>
                      <p className="ideal-text">Ideal for Medium and Large teams</p>
                      <h5 className="team-size ">Team Size: {`${value.max_user}`}</h5>
                      <h6 className="every-text">Everything in Professional, PLUS</h6>
                      <h6 className="every-text">Custom Pricing</h6>
                    </div>
                    <ul className="pricing-feature-list">
                      <li className="pricing-feature custom-pricing-feature">
                        <h6 className="font-20 color-66 fw-700 mb-0">Custom pricing</h6>
                        <p className="font--16 mt-2 color-66  mb-4">Trial Available</p>
                      </li>
                    </ul>
                  </div>{" "}
                  <div className={`d-flex justify-content-center ${value.planID === selected_plan_id ? "mt-53" : "mt-3"}`}>
                    <button className="pricing-action m-0" onClick={() => navigate("demo")}>
                      Get a Demo
                    </button>
                  </div>
                  <p style={{ position: "absolute", bottom: "-50px", right: 0 }} className="text-align-end">
                    *Taxes and Fees will apply
                  </p>
                </div>
              );
            }
            return (
              <div
                key={value?.uniquecode}
                className={`pricing-item justify-content-between ${value.planID === selected_plan_id ? "pricing__item--featured" : ""}`}
                onClick={() => onPlanSelectHandler(value)}>
                <div className="pricing-min-ht">
                  <div className="pricing-deco">
                    <div className="pricing-price">
                      {value.plan_name.includes("Professional") && <span className="recomended-text">Recommended</span>}
                      <h6 className="fw-700 start-up-head color-66   mt-3 mb-0">{value.plan_name}</h6>
                      <p className=" pay-as-you start-up-head color-66">{`${value.plan_name.includes("Start Up") ? "(Pay as you Go)" : ""}`}</p>
                    </div>
                    <div className="price-div-2">
                      <span className="pricing-currency color-3b">{selected_currency}</span>
                      {/* {selected_plan_duration === 1 && ( */}
                      <span className="color-b3 font-42 fw--400 cost-span">
                        {/* {selected_currency === "$" ? planCost?.$ : selected_currency === "R" ? planCost?.R : "00"} */}
                        {planCost?.amount ? planCost?.amount : "00"}
                      </span>
                      {/* // )} */}
                      {/* {selected_plan_duration === 12 && (
                        <span className="color-b3 font-42 fw--400 cost-span">
                          {selected_currency === "$" ? parseInt(planCost?.$ * 12) : selected_currency === "R" ? parseInt(planCost?.R * 12) : "00"}
                        </span>
                      )}
                      {selected_plan_duration === 24 && (
                        <span className="color-b3 font-42 fw--400 cost-span">
                          {selected_currency === "$" ? parseInt(planCost?.$ * 24) : selected_currency === "R" ? parseInt(planCost?.R * 24) : "00"}
                        </span>
                      )} */}
                    </div>
                    <h3 className="pricing-title">per user/per mo</h3>.
                    {/* <h5 className="team-size ">Team Size: {`${value.min_user}-${value.max_user}`}</h5> */}
                    <div className="team-size font--16">{value?.planID === 3 ? "Minimum 3 license" : "Minimum 2 license"}</div>
                    <p className="ideal-text">
                      {value.plan_name === "Start Up"
                        ? "Ideal for Start-up’s or Small teams"
                        : value.plan_name === "Scale Up"
                        ? "Ideal Small teams"
                        : value.plan_name === "Professional"
                        ? "Ideal for Small  or Medium teams"
                        : ""}
                    </p>
                    <h6 className="every-text">
                      {value.plan_name === "Start Up"
                        ? "Ideal for Start-up’s or Small teams"
                        : value.plan_name === "Scale Up"
                        ? "Everything in Start-Up, PLUS"
                        : value.plan_name === "Professional"
                        ? "Everything in Scale-Up, PLUS"
                        : ""}
                    </h6>
                  </div>
                  <ul className="pricing-feature-list pricing-feature-list-new">
                    <li>
                      <i className="fas fa-check"></i>
                      {`${
                        value.plan_name.includes("Professional") ? "Business Landline or toll free numbers" : "Business Landline or National numbers"
                      }`}
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      {` 
                            ${value.voice_minute} Call Anywhere minutes
                            ${value.plan_name !== "Professional" ? "(Local Country)" : ""}
                        `}
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      {`Host up to ${value.max_user} participants`}
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      {`${
                        value.plan_name.includes("Start Up")
                          ? `Group meetings for up to ${value.video_calling_minutes} minutes`
                          : `Video Calling ${value.video_calling_minutes} minutes`
                      }`}
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      {`${
                        value.plan_name.includes("Start Up")
                          ? "Unlimited Team Calling, Video Meeting & Messaging"
                          : "Unlimited Team Calling & Messaging"
                      }`}
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      Business SMS *
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      {`Cloud Storage ${value.storage}`}
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      Mobile & Web App
                    </li>
                  </ul>
                </div>
                <div className={`d-flex justify-content-around ${value.planID === selected_plan_id ? "mt-3" : ""}`}>
                  {[1, 2].includes(value?.planID) && (
                    <Link to="/signup" className={`pricing-action`}>
                      14 day Trial
                    </Link>
                  )}
                  {[3].includes(value?.planID) && (
                    <Link to="/demo" className={`pricing-action`}>
                      Get a Demo
                    </Link>
                  )}
                  {value?.planID !== 4 && (
                    <Link to="/signup" className={`pricing-action`}>
                      Signup
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default withRouter(PlanDetail);
