import React, { Component, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";

export const VideoAutoPlay = () => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      videoRef.current.play();
    } else {
      if (videoRef.current.play) {
        videoRef.current.pause();
      }
    }
  }, [isVisible]);

  return (
    <VisibilitySensor onChange={(isVisible) => setIsVisible(isVisible)}>
      <video width="100%" height="100%" controls loop ref={videoRef}>
        <source
          src="./../assets/videos/nxatel-manage-calls.mp4"
          type="video/mp4"
        />
      </video>
    </VisibilitySensor>
  );
};

class Call extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <div className="call-outer buisness-cloud-banner">
          <div className="upgrade-business-phone-system-sec ">
            <div className=" container">
              <div className="row">
                <div className="col-md-6">
                  <div className="buisness-cloud-banner-text">
                    <div className="text--black font--36 lh--42 text-center sf--t-regular fw--700 font--sm-30">
                      Upgrade your business phone system to a cloud PBX.​
                    </div>
                    <div className="font--20 lh--25 text-center w-100 mt--50 mb--50 text--gray-6971 dm--sans fw--700">
                      Set up your business phone system in minutes. Choose your
                      business number from over 20+ countries​
                    </div>
                    <div className="text--gray-9a dm--sans  font--16 lh--27 text-center mt--70 mb--20">
                      One Provider for everything Easy to scale Work from Home
                      Ready. All your Business conversations in one place.​
                    </div>
                    <div className="d-flex align-items-center gap--15 justify-content-center">
                      <Link
                        to="/signup"
                        className="btn  bg--red-f037 text--white border--2 border--red-f037 sf--t-medium radius--16-0">
                        Try for 14 days free
                      </Link>
                      <Link
                        to="/pricing"
                        className="btn  bg--transparent text--red-f037 border--2 border--red-f037 sf--t-medium radius--16-0 hover-text--red-f037">
                        See Pricing
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="call-banner-img">
                    <img
                      src="./../assets/images/product-call-page-banner.png"
                      alt="1"
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="communication-system-sec">
            <div className=" container">
              <div className="row">
                <div className=" text--black font--36 lh--42 mb--40 text-center fw--700 w-100 dm--sans pr--15 pl--15 font--sm-30">
                  The complete communication system for <br></br> growing your
                  business.​
                </div>
                <div className="col-md-6">
                  <div className="text-center">
                    <img
                      src="./../assets/images/hybird-work-img.png"
                      alt="1"
                      className="w-65 "
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="hybrid-work-sec-right">
                    <div className="text--black font--36 lh--42 mt--50 mb--30 sf--t-regular fw--700 font--sm-30">
                      BUILT FOR HYBRID WORK​
                    </div>
                    <div className="font--16 mt--20 text--gray-9a lh--25 dm--sans">
                      <b>Easy to use​</b>
                      <br />
                      Your business phone number available instantly.​
                    </div>
                    <div className="font--16 mt--20 text--gray-9a lh--25 dm--sans">
                      <b>Secure and reliable​</b>
                      <br />
                      Stay connected with a 99.999% uptime SLA and
                      <br />
                      enterprise-grade security.​​
                    </div>
                    <div className="font--16 mt--20 text--gray-9a lh--25 dm--sans">
                      <b>Never miss a call​</b>
                      <br></br>
                      Quickly route incoming calls with a virtual
                      receptionist.​​
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap--15 mt--40 justify-content--sm-center">
                    <Link
                      to="/signup"
                      className="btn btn  bg--red-f037 text--white border--2 border--red-f037 sf--t-medium radius--16-0">
                      Try for 14 days free
                    </Link>
                    <Link
                      to="/pricing"
                      className="btn  bg--transparent text--red-f037 border--2 border--red-f037 sf--t-medium radius--16-0 hover-text--red-f037">
                      See Pricing
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="manage-device-features-sec ">
            <div className=" container">
              <div className="col-md-12">
                <div className="fw--700 text--black font--36 lh--42 text-center  mb--20 sf--t-regular font--sm-30 ">
                  Manage calls, voicemails, call recording and business{" "}
                  <br></br> SMS on any device.​
                </div>
                <p>Built for non-technical business owners.​​</p>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="manage-device-video mr--20">
                    <VideoAutoPlay />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="font--20  text--gray-6971 lh--25 dm--sans fw--700 ">
                    Mobile-ready​
                  </div>
                  <div className="font--16 mt--10 text--gray-9a lh--28 dm--sans mb--30">
                    ​Say hello! On the go​​
                    <br />
                    Take your business phone with you even
                    <br /> when you’re away from your office desk.​
                  </div>
                  <div className="font--20  text--gray-6971 lh--25 dm--sans fw--700 ">
                    Easily Switch between desktop​ and
                    <br /> mobile.
                  </div>
                  <div className="font--16 mt--10 text--gray-9a lh--28 dm--sans mb--20">
                    ​Receive business calls, send SMS contacts,
                    <br /> and​ more on your Apple or Android
                    <br /> smartphone.​​​​
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="work-together-sec">
            <div className=" container">
              <div className="text--black font--36 lh--42 text-center sf--t-regular fw--700 mb--15 font--sm-30">
                Work Anywhere, Together​​​
              </div>
              <div className="font--20 lh--25 text-center w-100  text--gray-6971 dm--sans fw--700">
                Keep it simple and stop juggling separate applications and
                <br /> get a single platform that has just what you need.​​
              </div>
              <div className="d-flex align-items-center gap--15 mt--20 justify-content-center">
                <Link
                  to="/signup"
                  className="btn btn  bg--red-f037 text--white border--2 border--red-f037 sf--t-medium radius--16-0">
                  Try for 14 days free
                </Link>
                <Link
                  to="/pricing"
                  className="btn  bg--transparent text--red-f037 border--2 border--red-f037 sf--t-medium radius--16-0 hover-text--red-f037">
                  See Pricing
                </Link>
              </div>
              <div className="pt--40 pb--40 text-center">
                <div className="row gap--sm-25">
                  <div className="col-md-4">
                    <div className="text--black font--18 lh--29 sf--t-medium mb--10">
                      Nxatel Mobile App​
                    </div>
                    <img
                      src="./../assets/images/dwn-our-app.png"
                      alt="1"
                      className="w--190 h--192 m-auto"
                    />
                    <Link
                      to="#"
                      className="mt--15  pr--16 pl--16 radius--5 text--white d-flex align-items-center justify-content-center w--200 bg--red-f037 m-auto c--pointer">
                      Download our App ​
                      <img
                        src="./../assets/images/right-arrow-dwn.svg"
                        alt="1"
                        className="w--30 h-auto mt--12 mb--12 pl--5"
                      />
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <div className="text--black font--18 lh--29 sf--t-medium mb--10">
                      Web App​
                    </div>
                    <img
                      src="./../assets/images/dwn-web-app.png"
                      alt="1"
                      className="w--190 h--192 m-auto"
                    />
                    <Link
                      to="#"
                      className="mt--15  pr--16 pl--16 radius--5 text--white d-flex align-items-center justify-content-center w--200 bg--red-f037 m-auto c--pointer">
                      Access our Web App
                      <img
                        src="./../assets/images/right-arrow-dwn.svg"
                        alt="1"
                        className="w--30 h-auto mt--12 mb--12 pl--5"
                      />
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <div className="text--black font--18 lh--29 sf--t-medium mb--10">
                      IP Phone​
                    </div>
                    <img
                      src="./../assets/images/shop-for-ipdv.png"
                      alt="1"
                      className="w--190 h--192 m-auto"
                    />
                    <Link
                      to="#"
                      className="mt--15  pr--16 pl--16 radius--5 text--white d-flex align-items-center justify-content-center w--200 bg--red-f037 m-auto c--pointer">
                      Shop for IP Devices​
                      <img
                        src="./../assets/images/right-arrow-dwn.svg"
                        alt="1"
                        className="w--30 h-auto mt--12 mb--12 pl--5"
                      />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="questions-about-nxatel ​work-anywhere-faq">
                <div id="accordion" className="work-anywork-fq-inner">
                  <div className="font--20 lh--25 text-center w-100  text--gray-6971 dm--sans fw--700 mb--30 mt--30">
                    Got questions about Nxatel?
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card ">
                        <div className="card-header" id="headingOne">
                          <button
                            className="btn  collapsed"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne">
                            Can I use my existing business phone numbers?​
                            <span className="collapse-arrow">
                              <img
                                src="./../assets/images/faq-dwn-arrow.svg"
                                alt="1"
                              />
                            </span>
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordion">
                          <div className="card-body">
                            <div className="font--14 text--gray-9a lh--25">
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout. The point of using
                              Lorem Ipsum is that it has a more-or-less normal
                              distribution of letters, as opposed to using
                              'Content here
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card ">
                        <div className="card-header" id="headingTwo">
                          <h5 className="mb-0">
                            <button
                              className="btn  collapsed"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo">
                              Can Nxatel work over Wi-Fi?​
                              <span className="collapse-arrow">
                                <img
                                  src="./../assets/images/faq-dwn-arrow.svg"
                                  alt="1"
                                />
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordion">
                          <div className="card-body">
                            <div className="font--14 text--gray-9a lh--25">
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout. The point of using
                              Lorem Ipsum is that it has a more-or-less normal
                              distribution of letters, as opposed to using
                              'Content here
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card fq-card-anywhere">
                        <div className="card-header" id="headingThree">
                          <h5 className="mb-0">
                            <button
                              className="btn collapsed"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree">
                              How do I set up Nxatel service?​
                              <span className="collapse-arrow">
                                <img
                                  src="./../assets/images/faq-dwn-arrow.svg"
                                  alt="1"
                                />
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordion">
                          <div className="card-body">
                            <div className="font--14 text--gray-9a lh--25">
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout. The point of using
                              Lorem Ipsum is that it has a more-or-less normal
                              distribution of letters, as opposed to using
                              'Content here
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card fq-card-anywhere">
                        <div className="card-header" id="heading4">
                          <h5 className="mb-0">
                            <button
                              className="btn collapsed"
                              data-toggle="collapse"
                              data-target="#collapse4"
                              aria-expanded="false"
                              aria-controls="collapse4">
                              Do I need a special phone to use Nxatel?​
                              <span className="collapse-arrow">
                                <img
                                  src="./../assets/images/faq-dwn-arrow.svg"
                                  alt="1"
                                />
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapse4"
                          className="collapse"
                          aria-labelledby="heading4"
                          data-parent="#accordion">
                          <div className="card-body">
                            <div className="font--14 text--gray-9a lh--25">
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout. The point of using
                              Lorem Ipsum is that it has a more-or-less normal
                              distribution of letters, as opposed to using
                              'Content here
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card fq-card-anywhere">
                        <div className="card-header" id="heading5">
                          <h5 className="mb-0">
                            <button
                              className="btn collapsed"
                              data-toggle="collapse"
                              data-target="#collapse5"
                              aria-expanded="false"
                              aria-controls="collapse5">
                              What's the difference between Fixed landlines,
                              PABX and Nxatel?​
                              <span className="collapse-arrow">
                                <img
                                  src="./../assets/images/faq-dwn-arrow.svg"
                                  alt="1"
                                />
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapse5"
                          className="collapse"
                          aria-labelledby="heading5"
                          data-parent="#accordion">
                          <div className="card-body">
                            <div className="font--14 text--gray-9a lh--25">
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout. The point of using
                              Lorem Ipsum is that it has a more-or-less normal
                              distribution of letters, as opposed to using
                              'Content here
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card fq-card-anywhere">
                        <div className="card-header" id="heading6">
                          <h5 className="mb-0">
                            <button
                              className="btn collapsed"
                              data-toggle="collapse"
                              data-target="#collapse6"
                              aria-expanded="false"
                              aria-controls="collapse6">
                              Does Nxatel offer month to month or annual plans?​
                              <span className="collapse-arrow">
                                <img
                                  src="./../assets/images/faq-dwn-arrow.svg"
                                  alt="1"
                                />
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapse6"
                          className="collapse"
                          aria-labelledby="heading6"
                          data-parent="#accordion">
                          <div className="card-body">
                            <div className="font--14 text--gray-9a lh--25">
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout. The point of using
                              Lorem Ipsum is that it has a more-or-less normal
                              distribution of letters, as opposed to using
                              'Content here
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="try-free-days-sec ">
            <div className=" container">
              <div className="row">
                <div className="col-md-6">
                  <img
                    src="./../assets/images/try-free-seven-day-callpage.png"
                    alt="1"
                    className="w-70 d-block m-auto"
                  />
                </div>
                <div className="col-md-6">
                  <div className="nxatel-free-seven-days-right">
                    <div className="text--black font--36 lh--42 mt--65 dm--sans fw--700 font--sm-30">
                      Try Nxatel for<br></br> free for 14 Days
                    </div>
                    <div className="font--16 sf--t-ragular fw--700  text--gray-9a mt--10 ">
                      Plans start at R99 per user/per month No long-term
                      contracts. No hidden fees. No nonsense.
                    </div>
                    {/* <p className="mt-4">
                    Ready to switch to a unified platform?​{" "}
                  </p> */}
                    <div className="d-flex align-items-center gap--15 mt--20 justify-content--sm-center">
                      <Link
                        to="/signup"
                        className="btn btn  bg--red-f037 text--white border--2 border--red-f037 sf--t-medium radius--16-0">
                        Try for 14 days free
                      </Link>
                      <Link
                        to="/pricing"
                        className="btn  bg--transparent text--red-f037 border--2 border--red-f037 sf--t-medium radius--16-0 hover-text--red-f037">
                        See Pricing
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Call;
