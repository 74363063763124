import React from "react";
import { Link } from "react-router-dom";

function ContactBar() {
  const openLiveSalesHandle = () => {
    if (window?.$zohosq?.floatwindow) {
      window?.$zohosq?.floatwindow?.visible("show");
    }
  };
  return (
    <div className="live-chat  d-flex  align-items-center justify-content-end">
      <span className="mr-4">
        <a href="#">0861 692 835</a>
      </span>
      <span onClick={() => openLiveSalesHandle()}>
        <Link className="" to="#">
          <img src="./../assets/images/signup/live-chat.png" alt="chat" />
          Live Sales Chat
        </Link>
      </span>
    </div>
  );
}

export default ContactBar;
