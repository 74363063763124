import React, { Component } from "react";
import { Link } from "react-router-dom";

class Message extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div className="product-message-banner">
          <div className=" container">
            <div className="col-md-12">
              <h1 class="">Securely chat instantly​ and share important files.​</h1>
              <h3>Engage customers, send updates, schedule appointments and answer questions with Nxatel’s powerful two-way texting.​</h3>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="product-message-banner-left ">
                  <img src="./../assets/images/product-message-banner-img-new.png" alt="1" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="product-message-banner-right">
                  <h4 className="">
                    Nxatel Mobile App​ <br></br>Any conversation, any time, Any device​
                  </h4>
                  <p>
                    ​Send text messages for appointment reminders, office closures, follow ups, invitations to leave you a review and payment
                    requests. Need to run out of the office for 30 minutes? Seamlessly take any ongoing conversations with you with the Nxatel mobile
                    app.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-message-teamwork">
          <div className=" container">
            <div className="col-md-12">
              <h2 class="">Team conversation for efficient teamwork​</h2>
              <h3>Improve and unify your communications​</h3>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="product-message-teamwork-left">
                  <h4 className="">Send messages and texts</h4>
                  <p>
                    ​Chat with the team or external partners across your favorite devices. Send 1:1 messages, team messages, and texts all in the same
                    app.
                  </p>
                  <h4 className="mt-3">Share videos, photos, and files​</h4>
                  <p>​Send files of all shapes and sizes—just drag and drop. Plus, connect tools like Google Drive so everything’s in one place.​​</p>
                  <div className="btn-center-home ">
                    <Link to="/pricing" className="btn htw-learn-more mr-2">
                      See Pricing
                    </Link>
                    <Link to="#" className="link-blue">
                      Try it Schedule your first meeting ​​
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="product-message-teamwork-right">
                  <img src="./../assets/images/communications-img.png" alt="1" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nxatel-free-seven-days ">
          <div className=" container">
            <div className="row">
              <div className="col-md-6">
                <div className="nxatel-free-seven-days-left">
                  <img src="./../assets/images/NxaMessage.png" alt="1" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="nxatel-free-seven-days-right">
                  <h3 className="">
                    Try Nxatel for<br></br> free for 14 Days
                  </h3>
                  <p>
                    Nxatel runs on the device you're <br></br>using right now.​
                  </p>
                  <p className="mt-4">Ready to switch to a unified platform?​ </p>
                  <div className="btn-center-home ">
                    <Link to="/signup" className="btn htw-learn-more mr-2">
                      Try for 14 days free
                    </Link>
                    <Link to="/pricing" className="btn get-demo-red">
                      See Pricing
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Message;
