import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Terms = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div className="terms-page-banner">
        <h1>Terms and Conditions</h1>
        <div className="terms-respect-box">
          <div className="terms-svg"></div>
          <div className="container">
            <div className="terms-respect-bx-inner">
              <div className="terms-left">
                <p>
                  The website https://nxa.tel and the software application accessible through the Website at https://nxa.tel (collectively “the
                  Website”), which software application comprises the Services are operated and/or owned by Nxatel, Pty Ltd (hereinafter referred to
                  as “Nxatel”, “we”, “our” or “us”). The Terms herein are entered into by and between Nxatel and the User. Any reference to “Nxatel “,
                  “we”, “our” or “us”, shall include our employees, officers, directors, representatives, agents, shareholders, affiliates,
                  subsidiaries, holding companies, related entities, advisers,
                </p>
              </div>
              <div className="terms-right">
                <img src="./../assets/images/policies.svg" alt="1" className="img-fluid" />
              </div>
            </div>
            <p className="mt-4">
              <b>
                The website https://nxa.tel and the software application accessible through the Website at https://nxa.tel (collectively “the
                Website”), which software application comprises the Services are operated and/or owned by Nxatel, Pty Ltd (hereinafter referred to as
                “Nxatel”, “we”, “our” or “us”). The Terms herein are entered into by and between Nxatel and the User. Any reference to “Nxatel “,
                “we”, “our” or “us”, shall include our employees, officers, directors, representatives, agents, shareholders, affiliates,
                subsidiaries, holding companies, related entities, advisers,
              </b>
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="terms-content">
          <div className="w-100">
            <h3>What Section Would You Like To View?</h3>
            <div className="terms-content-box ">
              <div className="terms-content-left ">
                <a href="#orderingServices">1.Ordering Services </a>
                <a href="#serviceDescriptions">2. Service Descriptions </a>
                <a href="#equipment">3. Equipment</a>
                <a href="#serviceterm">4. Services Term and Automatic Renewal</a>
                <a href="#invoice_payment">5. Invoicing and Payment</a>
                <a href="#pricing_billing">6. Pricing, Billing and Refund Policy</a>
                <a href="#fair_use">7. Fair Use Policy</a>
                <a href="#portability">8. Portability and Reclaiming Numbers</a>
                <a href="#protection_data">9. Protection of Data</a>
                <a href="#responsibility">10. Responsibility For User Content and Call Recording</a>
                <a href="#rights">11. Proprietary Rights</a>
                <a href="#licence">12. Content License</a>
                <a href="#disclaimer">13. Disclaimer</a>
                <a href="#liability">14. Limitations of Liability</a>
                <a href="#revisions">15. Revisions</a>
              </div>
              <div className="terms-content-right ">
                <a href="#risk">16. Assumption of Risk</a>
                <a href="#dataProtection">17. Your Responsibility to Protect Data and Password</a>
                <a href="#links">18. Links</a>
                <a href="#security">19. Enforcing Security</a>
                <a href="#compliance">20. Compliance with Laws</a>
                <a href="#indemnity">21. Indemnity</a>
                <a href="#termination">22. Termination of License</a>
                <a href="#law">23. Governing Law</a>
                <a href="#classActions">25. No Class Actions</a>
                <a href="#waiver">26. Waiver of Compliance of this Agreement</a>
                <a href="#delegation">27. Assignment and Delegation</a>
                <a href="#relief">28. No Injunctive Relief</a>
                <a href="#contact_info">29. Contact Information</a>
              </div>
            </div>
          </div>

          <div className="terms-content-list mt-5">
            <h3>Ordering and Term</h3>
            <div className="terms-content-list-box" id="orderingServices">
              <h4 className="pt-4">1. Ordering Services</h4>
              <p>
                Customer may order Services by submitting electronically an Order in the format provided by Nxatel on the Nxatel website or, for
                subsequent orders, via the Nxatel Portal. The Order will identify the Services requested by Customer together with:{" "}
              </p>
              <p>1.1 the price for each Service;</p>
              <p>1.2 scheduled Start Date; </p>
              <p>1.3 and products leased, licensed or sold to Customer, if any.</p>
              <br></br>
              <p>
                An Order will become binding when it is executed by the Customer and accepted by Nxatel. Nxatel may accept an Order by commencing
                performance of the requested Services. The Services will begin on the Start Date, as identified in the applicable Order. Customer may
                purchase additional Services, software, and equipment through Orders via the Web Portal.
              </p>
            </div>
            <div className="terms-content-list-box" id="serviceDescriptions">
              <h4 className="pt-4">2. Service Descriptions</h4>
              <p>
                Nxatel All in One is referred to as Nxatel App. All references to "Nxatel App," whether in this Agreement or its attachments, Order
                Forms or descriptions, mean "Nxatel All in One".
              </p>
              <br></br>
              <p>The Service Attachment(s) applicable to Customer’s Services are incorporated into and forms a part of this Agreement.</p>
              <br></br>
              <p>
                Nxatel App, is a cloud-based unified communications services that include enterprise-class voice, fax, text, call handling, mobile
                apps, and bring your own device (BYOD) capabilities that integrate with a growing list of applications.
              </p>
              <br></br>
              <p>
                NxaMeets is an enterprise-class cloud-based video conferencing and collaboration service that includes screen and file sharing, text,
                and mobile applications.
              </p>
            </div>
            <div className="terms-content-list-box" id="equipment">
              <h4 className="pt-4">3. Equipment</h4>
              <p>
                Customer may purchase or rent equipment from Nxatel for use with the Services. The terms and conditions that govern any such
                transaction can be found at:
              </p>
              <br></br>
              <p>
                (i) General Sales Terms: <span className="text-blue">Annexure B</span>
              </p>
            </div>
            <div className="terms-content-list-box" id="serviceterm">
              <h4 className="pt-4">4. Services Term and Automatic Renewal</h4>
              <p>
                The Services’ term will begin on the Start Date of the initial Order and continue for the initial term set forth in the initial Order
                (“Initial Term”). Upon expiration of the Initial Term, recurring Services will automatically renew for successive periods of the same
                length as the Initial Term (each a “Renewal Term”) unless either Party gives notice of non-renewal at least thirty (30) days before
                the expiration of the Initial Term or the then-current Renewal Term. The term of any recurring Services added to the Account after the
                initial Order is submitted will start on the Start Date of the subsequent Order, will run coterminously with the then-current term of
                any pre-existing Services, and will be billed on the same billing cycles as the pre-existing Services.
              </p>
            </div>
            <div className="terms-content-list-box" id="invoice_payment">
              <h4 className="pt-4">5. Invoicing and Payment</h4>
              <h5>5.1 Prices and Charges</h5>
              <p>
                Prices are ZAR or US dollars on the nxa.tel website Portal. Additional charges may result if Customer activates additional features,
                exceeds usage thresholds, or purchases additional Services or equipment. Customer will be liable for all charges resulting from use of
                the Services on its Account. Recurring charges for the Services begin on the Start Date, and will continue for the Term. Recurring
                charges (such as charges for Digital Lines, product licenses, minute bundles, and equipment rental fees) will, once incurred, remain
                in effect for the Initial Term (as described in an Order Form) or the then-current Renewal Term. Nxatel will provide notice of any
                proposed increase in such charges no later than thirty (30) days before the end of the Initial Term or then-current Renewal Term, and
                any such increase will be effective on the first day of the next Renewal Term. Administrative Fees that Nxatel is entitled to pass on
                to its customers as a surcharge pursuant to applicable Law may be increased on thirty (30) days’ written notice.
                <br />
                Outbound calling rates will be applied based on the rate in effect at the time of use. Customer may locate the currently effective
                rates in the Nxatel Portal.
              </p>
              <h5>5.2 Billing and Payment</h5>
              <p>
                All Services and equipment must be purchased via valid credit or debit card at the time of purchase. By providing a valid credit or
                debit card, Customer is expressly authorizing all Services and equipment charges and fees to be charged to such payment card,
                including recurring payments billed on a monthly or annual basis. In addition, Customer’s supplied credit card shall be used for any
                in-month purchases of additional services and products, or where Customer has exceeded usage or threshold limits, or any overage
                charges. Recurring charges are billed in advance in the frequency set forth in the Order Form, and usage-based and one-time charges
                are billed monthly in arrears. Credit and debit card payments are subject to the approval of the card issuer, and Nxatel will not be
                liable in any way if a card issuer refuses to accept a credit or debit card for any reason. Customer is responsible for any credit
                card chargeback or similar fees for refused or rejected payments that Nxatel is entitled to charge under this Agreement. If the
                payment card associated with Customer’s Account is declined or fails for any reason, Nxatel will send Customer a notice using the
                contact information associated with Customer’s Account. Nxatel may continue to attempt charging Customer’s payment card for
                outstanding charges and additional fees along with any other rights and remedies available to Nxatel under this Agreement, at law or
                in equity.
                <br />
                Unless otherwise stated at the time of purchase or on the invoice, payment is due in full, without deduction or set-off, within thirty
                (30) days of the date on the invoice. Any payment not made when due will be subject to a late payment fee equivalent to the lesser of
                (i) one and a half percent (1.5%) per month; or (ii) the highest rate allowed by Law. Nxatel’s acceptance of late or partial payments
                (regardless of how they are marked or designated (including without limitation as ‘Paid in Full’, ‘Accord and Satisfaction’, or
                similarly)) will not waive, limit, or prejudice in any way Nxatel’s rights to collect any amount due. Nxatel may terminate the
                Services and this Agreement for non-payment if any fees or charges are not paid within thirty (30) days of the due date.
              </p>
            </div>
            <div className="terms-content-list-box" id="pricing_billing">
              <h4 className="pt-4">6. Pricing, Billing and Refund Policy</h4>
              <p>
                6.1.You authorize Nxatel to charge and/or place a hold on your credit card with respect to any unpaid charges related to the Services.
                You authorize the issuer of the credit card to pay any amounts described herein without requiring a signed receipt, and You agree that
                Your acceptance of this Agreement is authorization to the issuer of the credit card to pay all such amounts.
              </p>
              <p>
                6.2. You authorize Nxatel and/or any other company acting as billing agent for Nxatel to charge Your credit card on a recurring
                monthly basis on the monthly anniversary of Your initial registration for a paid Nxatel plan and to continue to attempt to charge
                and/or place holds with respect to all sums described herein, or any portion thereof, to Your credit card until such amounts are paid
                in full.
              </p>
              <p>
                6.3. You will provide Nxatel with updated credit card information upon Nxatel’s request and any time the information You previously
                provided is no longer valid. You are solely responsible for maintaining and updating the credit card information. Nxatel is not liable
                for any non-sufficient funds or other charges incurred by You as a result of such attempts to charge, and/or place holds on, Your
                credit card. If You mistakenly provide a debit card number, instead of a credit card number, You authorize all charges described
                herein to be applied to such debit card unless and until You provide a credit card number.
              </p>
              <ul>
                <li className="ml-4">
                  <p>
                    For our subscription plans, usage and monthly or annual fees will be charged directly to the credit card You provide to us as
                    specified in our pricing plans found at Pricing Page. Except as provided below, monthly services and certain other charges are
                    billed and charged one month in advance, and there is no proration of such charges if service is terminated on other than the last
                    day of Your billing cycle. You agree to pay for all Nxatel Services. For annual plans, your billing cycle will be of 12 months
                    from the date of payment of your annual plan subscription fees. If any new user or number is added during the billing cycle, an
                    amount equivalent to prorated amount for the remaining period in the billing cycle will be charged on your credit card on the
                    file. If You cancel your annual plan subscription during the billing cycle, your subscription won't be renewed after the end of
                    current billing cycle. No refund will be given for cancellation or team member/phone number removal during the ongoing billing
                    cycle. In case of team member or phone number removal, the annual plan subscription amount will be accordingly adjusted (if
                    applicable) for next billing cycle onwards.
                  </p>
                </li>
                <li className="ml-4">
                  <p>
                    AIRTIME AND OTHER MEASURED USAGE ("CHARGEABLE TIME") IS BILLED IN FULL-MINUTE INCREMENTS, AND ACTUAL AIRTIME AND USAGE ARE ROUNDED
                    UP TO THE NEXT FULL-MINUTE INCREMENT AT THE END OF EACH CALL FOR BILLING PURPOSES. IF PAY PER MINUTE BILLING APPLIES WITHIN YOUR
                    PLAN, WE CHARGE A FULL MINUTE OF AIRTIME USAGE FOR EVERY FRACTION OF THE LAST MINUTE OF AIRTIME USED ON EACH CALL.
                  </p>
                </li>
                <li className="ml-4">
                  <p>
                    Chargeable time begins for outgoing calls when a connection to the number you are trying to reach is established and recorded in
                    our system and for incoming calls when a signal connection from the caller is established with our facilities. Chargeable Time
                    ends after the call has ended, either by You, the person You are speaking with or due to a technical malfunction, but not until
                    Your wireless telephone's signal of call disconnect is received by us and the call disconnect signal has been confirmed.
                  </p>
                </li>
                <li className="ml-4">
                  <p>
                    All outgoing calls for which we receive answer supervision including ring time shall incur a minimum of one-minute airtime charge.
                    Answer supervision is generally received when a call is answered; however, answer supervision may also be generated by voicemail
                    systems, private branch exchanges, and interexchange switching equipment. Chargeable Time may include time for us to recognize
                    that only one party has disconnected from the call, time to clear the channels in use, and ring time. Chargeable Time may also
                    occur from other uses of our facilities, including by way of example, voicemail deposits and retrievals, and call transfers.
                  </p>
                </li>
                <li className="ml-4">
                  <p>
                    If You select a rate plan that includes a predetermined allotment of Services (for example, a predetermined amount of airtime or
                    messages), unless otherwise specifically provided as a part of such rate plan, any unused allotment of Services from one billing
                    cycle will not carry over to any other billing cycle. We may bill You in a format as we determine from time to time. Additional
                    charges may apply for additional copies of Your bill, or for detailed information about Your usage of Services.
                  </p>
                </li>
                <li className="ml-4">
                  <p>
                    Nxatel may change the rates of outgoing calls, text messages at any time without notice to you by posting such change at Call
                    Rates and SMS Rates. The new rate will apply to your next call after the new rates have been published. Please check the latest
                    rates before You make your call. If you do not accept the new rates, do not make your call.
                  </p>
                </li>
                <li className="ml-4">
                  <p>You also remain responsible for paying Your monthly Service fee if Your Service is suspended for nonpayment.</p>
                </li>
                <li className="ml-4">
                  <p>
                    You agree that for amounts not paid by the due date, we may charge, as a part of its rates and charges, and You agree to pay, a
                    late payment fee of 8% or the maximum allowed by law. In the event You fail to pay billed charges when due and it becomes
                    necessary for us to refer Your account(s) to a third party for collection, we will charge a collection fee at the maximum
                    percentage permitted by applicable law, but not to exceed 18% to cover our internal collection-related costs.
                  </p>
                </li>
                <li className="ml-4">
                  <p>
                    Using Nxatel will not incur charges to Your personal voice plan (cellular or otherwise) unless You redirect calls to a mobile
                    located in a foreign country. In that case, Your operator will charge its standard rates for roaming on incoming calls.
                  </p>
                </li>
                <li className="ml-4">
                  <p>
                    If You use the Nxatel Service from a device using mobile data connection (ex. 3G or 4G, Fibre or DSL), incoming and outgoing calls
                    will use a part of the data allowance of Your personal data plan. If You use a mobile data connection outside of Your home
                    country, Your operator may charge You roaming fees.
                  </p>
                </li>
                <li className="ml-4">
                  <p>Charges are applicable for incoming calls for Numbers registered outside of South Africa.</p>
                </li>
                <li className="ml-4">
                  <p>
                    SMS rates mentioned on SMS Rates page are for single segment. 1 Segment = 160 characters. For example, if you send a 200
                    characters long message, it will cost you 2 X SMS Cost as 200 characters will be counted as 2 segements.
                  </p>
                </li>
                <li className="ml-4">
                  <p>
                    If you are not clear about the pricing for subscription, phone numbers, calls, texts or fax, please make sure to confirm prices
                    from our support team by emailing us at support@nxa.tel. NO REFUND WILL BE GIVEN FOR ALREADY INCURRED USAGE COSTS.
                  </p>
                </li>
                <li className="ml-4">
                  <p>All refund-related requests will be governed by our publicly available Refund Policy</p>
                </li>
              </ul>
            </div>
            <div className="terms-content-list-box" id="fair_use">
              <h4 className="pt-4">7. Fair Use Policy</h4>
              <p>
                7.1. Nxatel may offer currently or in the future plans on an "unlimited" use and/or “unlimited minutes” basis. All unlimited plans:
              </p>
              <ul>
                <li className="ml-4">
                  <p>May only be used for normal business use.</p>
                </li>
                <li className="ml-4">
                  <p>Are provided only for live dialog between two individuals</p>
                </li>
                <li className="ml-4">
                  <p>May not be used for any of the following prohibited use:</p>
                </li>
                <li className="ml-4">
                  <p>
                    Trunking or forwarding your Nxatel number to another phone number(s) capable of handling multiple simultaneous calls, or to a
                    private branch exchange (PBX) or a key system.
                  </p>
                </li>
                <li className="ml-4">
                  <p>
                    Auto-dialing or "predictive" dialing (i.e., non-manual dialing or using a software program or other means to continuously dial or
                    place out-bound calls).
                  </p>
                </li>
              </ul>
              <p>
                7.2. In addition, Nxatel is not recommended for massive bulk call-in lines (e.g., customer support or sales call centers handling over
                hundreds of simultaneous calls, sports-line numbers, etc.{" "}
              </p>
              <p>
                7.3. Nxatel sets no limit to the number of calls received through the Nxatel app. However, Nxatel considers a fair use policy in the
                volume of communications forwarded to a mobile device according to the following limit:
              </p>
              <p>7.4. 1,000 voice minutes/month in Nxatel Small Plan (including up to 2 users)</p>
              <p>7.5. 500 voice minutes/month for each additional user</p>
              <p>
                7.6. When a customer reaches the above fair use limit, he will receive an alert message and Nxatel reserves its right to restrict call
                usage or take any other call limit measure. Calls will always be possible through the Nxatel App.
              </p>
              <p>7.7. These limits can be overcome by upgrading to a custom enterprise plan, which can be obtained by contacting support@nxa.tel.</p>
              <p>
                7.8. Nxatel reserves the right upon prior notice to disconnect or suspend your Service if Nxatel determines that your use of the
                Service violates any point of the Fair Use Policy above.{" "}
              </p>
            </div>
            <div className="terms-content-list-box" id="portability">
              <h4 className="pt-4">8. Portability and Reclaiming Numbers</h4>
              8.1. Phone numbers for only specific countries (like US, UK, Australia) can be ported in. Nxatel will investigate the possibility to
              port manually a number, after assessing the implied cost. You may port Your Nxatel number to another provider as long as You have
              subscribed to a paid plan (i.e. You are not using the free trial). If You wish to export Your Nxatel number to another service, You
              should request Your new service provider to take responsibility to this process. Nxatel will not charge You for porting Your number out
              of the Nxatel Service, but Your new service provider may charge You a fee for the portability. Although it is not Nxatel’s
              responsibility to port Your number out of Nxatel, You may as well request guidance from Nxatel on this process by writing to
              support@nxa.tel
              <p></p>
            </div>
            <div className="terms-content-list-box" id="protection_data">
              <h4 className="pt-4">9. Protection of Data </h4>
              <p>Application Of The Electronic Communications And Transactions Act 25 Of 2002 ("Ect Act")</p>
              <p>
                9.1 Data Messages (as defined in the ECT Act) will be deemed to have been received by Nxatel if and when Nxatel responds to the Data
                Messages.
              </p>
              <p>
                9.2 Data Messages sent by Nxatel to a user will be deemed to have been received by such user in terms of the provisions specified in
                section 23(b) of the ECT Act.
              </p>
              <p>
                9.3 Users acknowledge that electronic signatures, encryption and/or authentication are not required for valid electronic
                communications between users and Nxatel.
              </p>
              <p>9.4 Information to be provided in terms of section 43(1) of the ECT Act:</p>
              <p>
                9.4.1 Users warrant that Data Messages sent to Nxatel from any electronic device, used by such user, from time to time or owned by
                such user, were sent and or authorised by such user, personally.
              </p>
              <p>
                9.4.2 This Website is owned and operated by Nxatel (Pty) Ltd, a private company owned and operated in accordance with the laws of the
                Republic of South Africa.
              </p>
              <p>9.5 Address for service of legal documents: 150 Frikkie De Beer St, Menlyn, Pretoria, 0063</p>
              <p>9.6 Contact Number: +27(0)861 NXATEL (692835).</p>
              <p>9.7 Nxatel – located at https://nxa.tel/.</p>
              <p>9.8 Email address: legal@nxa.technology</p>
              <p>9. List of Processors:</p>
              <ul>
                <li className="ml-4">Microsoft Azure</li>
                <li className="ml-4">Hosting services in South Africa (Cape Town) (Privacy Shield certified).</li>
                <li className="ml-4">Zoho Corporation</li>
                <li className="ml-4">Support Chat, Sales Chat and transactional email automation (Privacy Shield certified).</li>
                <li className="ml-4">Peach Payment</li>
                <li className="ml-4">Payment gateway (PCI compliant and Privacy Shield certified).</li>
                <li className="ml-4">Zoho CRM</li>
                <li className="ml-4">Sales CRM tool (Privacy Shield certified).</li>
                <li className="ml-4">Neximo</li>
                <li className="ml-4">SMS provider,powers our Solution with SMS capabilities. (Privacy Shield certified)</li>
              </ul>
            </div>
            <div className="terms-content-list-box" id="responsibility">
              <h4 className="pt-4">10. Responsibility For User Content and Call Recording </h4>
              <p>
                We respect the rights of third party creators and content owners and expect that you will do the same. Given the nature of the Service
                and the volume of information submitted, we cannot and do not monitor all of the Materials posted or transmitted by you and other
                third-party information providers via the Service, including, without limitation, any Materials posted via the Service. You expressly
                agree that we will not be liable for Materials. We reserve the right, but are not obligated, to remove content from the Service for
                any reason, including content that we believe violates these Terms & Conditions mentioned in this document.
              </p>
              <p>
                Please note that while Nxatel offers the option for you to record calls, if you choose to use this service, then you must comply with
                electronic communications laws and the Protection of Personal Information Act, No 4 of 2013., regulations and rules prior to recording
                any telephone calls and you expressly warrant and represent to Nxatel that you shall comply at all times. We make no representations
                or warranties with respect to call recording and recommend that always secure consent before recording. You acknowledge that these
                representations and obligations are essential to the ability of Nxatel to provide you with access to call recordings and you further
                agree to indemnify, defend and hold Nxatel and its officers, directors, owners, employees, agents, consultants and vendors harmless
                from and against any and all liabilities, losses, claims, damages, causes of action, costs and expenses (including attorneys’ fees)
                that may be incurred by Nxatel arising out of or related to your acts or omissions in connection with call recordings, whether such
                claims arise under contract, tort, statute or other legal theory.
              </p>
            </div>
            <div className="terms-content-list-box" id="rights">
              <h4 className="pt-4">11. Proprietary Rights</h4>
              <p>
                All right, title and interest in the Service and any content contained herein is the exclusive property of Nxatel, except as otherwise
                stated. Unless otherwise specified, this Service is for Your personal and limited commercial use only and You may print, copy and
                download any information or portion of the Site for Your personal use only. If You copy or download any information or software from
                this Site, You agree that You will not remove or obscure any copyright or other notices or legends contained in any such information.
                You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, frame, create derivative works from,
                transfer, or otherwise use in any other way for commercial or public purposes, in whole or in part, any information, software,
                products or services obtained from the Service, except for the purposes expressly provided herein, without Nxatel’s prior written
                approval. All trademarks, trade names, service marks and logos are proprietary to Nxatel. Your use of any marks on the Site in any
                manner other than as authorized in these Terms and Conditions, or as authorized in writing by Nxatel, is strictly prohibited.
              </p>
            </div>
            <div className="terms-content-list-box" id="licence">
              <h4 className="pt-4">12. Content License </h4>
              <p>
                You grant Nxatel a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to
                exercise any and all rights that You have as the copyright owner in any content or feedback that You provided to us.
              </p>
            </div>
            <div className="terms-content-list-box" id="disclaimer">
              <h4 className="pt-4">13. Disclaimer </h4>
              <p>
                The materials on Nxatel’s web site are provided “as is”. Nxatel makes no warranties, expressed or implied, and hereby disclaims and
                negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a
                particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Nxatel does not warrant or
                make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site
                or otherwise relating to such materials or on any sites linked to this site. Since the Nxatel Service is dependent on the IP network,
                the availability of an adequate power supply, and correct equipment configuration, we do not guarantee that any portion of the Service
                will be continuous or error-free. You also acknowledge and understand that we cannot guarantee that IP based communications are
                completely secure. Therefore, the Nxatel Service should not be used with any third-party services or devices such as monitored burglar
                alarms, monitored fire alarms, and/or medical monitoring systems or devices.
              </p>
            </div>
            <div className="terms-content-list-box" id="liability">
              <h4 className="pt-4"> 14. Limitations of Liability</h4>
              <p>
                YOU AGREE THAT WE WILL NOT BE LIABLE FOR ANY (1) SUSPENSION OR LOSS OF THE SERVICES (2) USE OF THE SERVICES, (3) INTERRUPTION OF THE
                SERVICES OR INTERRUPTION OF YOUR BUSINESS, (4) ACCESS DELAYS OR ACCESS INTERRUPTIONS TO OUR SITE OR SERVICES OR DELAYS OR ACCESS
                INTERRUPTIONS YOU EXPERIENCE IN RELATION TO THE SERVICES; (5) LOSS OR LIABILITY RESULTING FROM ACTS OF OR EVENTS BEYOND OUR CONTROL
                INCLUDING , BUT NOT LIMITED TO ANY ERRORS OR TECHNICAL ISSUES OF ANY DOMAIN NAME REGISTRY OR OTHER THIRD PARTY PROVIDER, (6) DATA
                NON-DELIVERY, MIS-DELIVERY, CORRUPTION, DESTRUCTION OR OTHER MODIFICATION; (7) OR LOSS OR LIABILITY RESULTING FROM THE UNAUTHORIZED
                USE OR MISUSE OF YOUR ACCOUNT IDENTIFIER OR PASSWORD.
              </p>
              <p>
                YOU ALSO AGREE THAT WE WILL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING LOST
                PROFITS) OR FOR ANY FINANCIAL OR ECONOMIC LOSS OR FOR LOSS OF PROFITS, LOSS OF BUSINESS, DEPLETION OF GOODWILL OR SIMILAR LOSSES, LOSS
                OF ANTICIPATED SAVINGS OR LOSS OR CORRUPTION OF DATA OR INFORMATION, REGARDLESS OF THE FORM OF ACTION WHETHER IN CONTRACT, TORT
                (INCLUDING NEGLIGENCE), OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              </p>
              <p>
                NO EVENT WILL THE COLLECTIVE LIABILITY OF Nxatel OR ITS OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, AGENTS, LICENSORS,
                REPRESENTATIVES, ATTORNEYS, AND BUSINESS PARTNERS TO ANY PARTY, REGARDLESS OF THE TYPE OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING
                NEGLIGENCE), OR OTHERWISE, EXCEED $100. Any cause of action You may have with respect to Your use of the Site and service provided by
                the Site must be commenced within one (1) year after the claim or cause of action arises. Because some jurisdictions do not allow
                limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not
                apply to You.
              </p>
            </div>
            <div className="terms-content-list-box" id="revisions">
              <h4 className="pt-4">15. Revisions</h4>
              <p>
                The materials appearing on Nxatel’s Site may include technical, typographical, or photographic errors. Nxatel does not warrant that
                any of the materials on its Site are accurate, complete, or current. Nxatel may make changes to the materials contained on its Site at
                any time without notice. Nxatel however, makes no commitment of any kind to update the materials.
              </p>
            </div>
            <div className="terms-content-list-box" id="risk">
              <h4 className="pt-4">16. Assumption of Risk</h4>
              <p>
                You use the Internet solely at Your own risk and subject to all applicable local, state, national, and international laws and
                regulations. While Nxatel has endeavored to create a secure and reliable Service, please be advised that the confidentiality of any
                communication or material transmitted to/from a Service over the Internet cannot be guaranteed. Accordingly, Nxatel is not responsible
                for the security of any information transmitted via the Internet, the accuracy of the information contained on the Site, or for the
                consequences of any reliance on such information. Nxatel shall have no liability for interruptions or omissions in Internet, network
                or hosting services. You assume the sole and complete risk of using the Service. Any material downloaded or otherwise obtained through
                the use of the Service is done at Your own discretion and risk. You will be solely responsible for any damage to Your computer system
                or loss of data that results from the download of any such material.
              </p>
            </div>
            <div className="terms-content-list-box" id="dataProtection">
              <h4 className="pt-4">17. Your Responsibility to Protect Data and Password</h4>
              <p>
                It is Your responsibility to protect Your personal data and maintain the confidentiality of Your user information and password. You
                are also responsible for immediately notifying Nxatel of any unauthorized use of Your account, or breach of Your account information
                or password. Nxatel will not be liable for any loss that You may incur as a result of someone else using Your username or password,
                either with or without Your knowledge. To the extent allowable by law, You shall be liable for any expenses, including usage charges
                and fines, fees, civil judgments, and reasonable attorney’s fees for Your failure to safeguard user and password information and/or
                promptly notify Nxatel about unauthorized use of Your account or breach of Your account information or password.
              </p>
            </div>
            <div className="terms-content-list-box" id="links">
              <h4 className="pt-4">18. Links</h4>
              <p>
                The Site may include links to certain website, materials, or content developed by third parties. Nxatel has not reviewed all of the
                sites linked to its Internet Site and is not responsible for the contents of any such linked site. The inclusion of any link does not
                imply endorsement by Nxatel of the Site. Use of any such linked website is at Your own risk. Nxatel reserves the right in its sole and
                absolute discretion to discontinue links to any other sites at any time and for any reason.
              </p>
            </div>
            <div className="terms-content-list-box" id="security">
              <h4 className="pt-4">19. Enforcing Security</h4>
              <p>
                Actual or attempted unauthorized use of any of the Site may result in criminal and/or civil prosecution. For Your protection, we
                reserve the right to view, monitor, and record activity on the Site without notice or further permission from You, to the fullest
                extent permitted by applicable law. This right extends to our review of message content and details pertaining to claimed violations
                of our Spam Policy. Any information obtained by monitoring, reviewing, or recording is subject to review by law enforcement
                organizations in connection with the investigation or prosecution of possible criminal activity on any of the Site.
              </p>
            </div>
            <div className="terms-content-list-box" id="compliance">
              <h4 className="pt-4">20. Compliance with Laws</h4>
              <p>
                You will use the Nxatel Service in full compliance with all applicable laws and regulations with regard to Your use of the Service
                including, without limitation, South African laws, international laws and all other laws and regulations concerning privacy,
                telemarketing, Internet marketing and the transmission of electronic communications. You will not use the Service in violation of any
                service agreement that You have with a third-party telecommunications provider. If necessary and in accordance with applicable law,
                Nxatel will cooperate with local, provisional, National, and/or international government authorities to protect its Site, Materials,
                Content, Services, Accounts, visitors, customers, its parents, subsidiaries, affiliates and their respective members, managers,
                directors, officers, employees, stockholders and agents and operational providers, from the unauthorized use of its Site, Materials,
                Content, Services and Accounts.
              </p>
            </div>
            <div className="terms-content-list-box" id="indemnity">
              <h4 className="pt-4">21. Indemnity</h4>
              <p>
                You will indemnify and hold Nxatel, its affiliates, officers, directors, employees, agents, legal representatives, licensors,
                subsidiaries, joint ventures and suppliers, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third
                party due to or arising out of Your breach of this Agreement, Your violation of any law, or the rights of a third party.
              </p>
            </div>

            <div className="terms-content-list-box" id="termination">
              <h4 className="pt-4">22. Termination of License</h4>
              <p>
                Nxatel may terminate Your right to use the Service with or without cause at any time, including when we feel that You are taking
                advantage of unlimited call redirection offered under a paid plan. We would consider “taking advantage” to be the breach of any point
                of Nxatel’s Fair Use Policy set above. You may also terminate this Agreement at any time directly from Your Nxatel account.
                Termination of this Agreement becomes effective at the end of the ongoing billing cycle.
              </p>
              <p>
                Your obligation to pay accrued charges and fees shall survive any termination of this Agreement. In the event of any termination of
                this Agreement, the limitations on Your use of the Service as set forth in the Section Pricing & Billing shall survive such
                termination, and You agree to be bound by those terms.
              </p>
            </div>
            <div className="terms-content-list-box" id="law">
              <h4 className="pt-4">23. Governing Law</h4>
              <p>
                Any claim relating to Nxatel’s site shall be governed by the laws of South Africa and the Electronic Communications And Transactions
                Act 25 Of 2002 without regard to its conflict of law provisions. Sole and exclusive jurisdiction for any action or proceeding arising
                out of or related to this agreement shall be in an appropriate province or court located in the city of Pretoria, Province of Gauteng.
                If for any reason a court of competent jurisdiction finds any provision of this agreement, or a portion thereof, to be unenforceable,
                that provision shall be enforced to the maximum extent permissible so as to affect the intent of this agreement, and the remainder of
                this agreement shall continue in full force and effect. A printed version of this agreement shall be admissible in judicial or
                administrative proceedings.
              </p>
            </div>
            <div className="terms-content-list-box" id="classActions">
              <h4 className="pt-4"> 25. No Class Actions</h4>
              <p>
                All claims between the parties related to this Agreement will be litigated individually and You will not consolidate or seek class
                treatment for any claim with respect to the Services.
              </p>
            </div>
            <div className="terms-content-list-box" id="waiver">
              <h4 className="pt-4">26. Waiver of Compliance of this Agreement</h4>
              <p>
                Even if we do not require strict compliance with the Agreement in each instance, You will be obligated to comply with the Agreement.
                Our failure to enforce, at any time, any of the provisions, conditions or requirements of the Agreement, or the failure to require, at
                any time, performance by You of any of the provisions of the Agreement, will in no way waive Your obligation to comply with any of the
                provisions of the Agreement or our ability to enforce each and every such provision as written.
              </p>
              <p>
                Any and all waivers by Nxatel of any provision, condition or requirement of the Agreement will only be effective against Nxatel if it
                is in writing and signed by an authorized officer of Nxatel, and any such written waiver will not constitute a waiver of any future
                obligation to comply with such provision, condition or requirement.
              </p>
            </div>
            <div className="terms-content-list-box" id="delegation">
              <h4 className="pt-4">27. Assignment and Delegation</h4>
              <p>
                You may not assign or delegate any rights or obligations under the Agreement. Any purported assignment and delegation shall be
                ineffective. We may freely assign or delegate all rights and obligations under the Agreement, fully or partially without notice to
                You. We may also substitute, by way of unilateral novation, effective upon notice to You, Nxatel, Pty Ltd. for any third party that
                assumes our rights and obligations under this Agreement.
              </p>
            </div>
            <div className="terms-content-list-box" id="relief">
              <h4 className="pt-4">28. No Injunctive Relief</h4>
              <p>
                In no event shall You seek or be entitled to rescission, injunctive or other equitable relief, or to enjoin or restrain the operation
                of the Service, exploitation of any advertising or other materials issued in connection therewith, or exploitation of the Service or
                any content or other material used or displayed through the Service.
              </p>
            </div>
            <div className="terms-content-list-box" id="contact_info">
              <h4 className="pt-4">29. Contact Information</h4>
              <p>
                If you have any questions, concerns, or complaints about our Services or anything found in this Agreement or other agreements with us,
                please contact us by email to support@nxa.tel
              </p>
            </div>
            <div className="mt-5 text-address">
              <p>
                Nxatel Pty Ltd
                <br />
                Attn: Nxa.tel Support Team
                <br />
                150 Frikkie De Beer St
                <br />
                Glen Manor Office Park
                <br />
                Menlyn
                <br />
                Pretoria
                <br />
                South Africa
                <br />
                support@nxa.tel
                <br />
              </p>
              <br />
              <p className="mt-2">
                IF YOU DO NOT AGREE TO THE TERMS STATED ABOVE OR TO ANY CHANGES MADE IN THESE TERMS, PLEASE EXIT THIS WEBSITE AND SERVICE IMMEDIATELY.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
