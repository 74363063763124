import React from "react";
import { Link } from "react-router-dom";

const MobilePrivacy = () => {
  return (
    <>
      <div className="privacy-page-banner">
        <h1>Privacy Policy</h1>
      </div>
      <div className="privacy-content">
        <div className="container">
          <h6>
            <b> Introduction:</b>
          </h6>
          <p>
            NxaTel Pty Ltd ("we", "us", or "our") is dedicated to protecting the privacy and security of our users. This Privacy Policy outlines the
            types of information we collect, how we use it, and the measures we take to safeguard it. By using NxaTel Mobile Application, you consent
            to the terms outlined in this Privacy Policy.
          </p>
          <ul className="disc-list pl-3">
            <li> App Name: NxaTel </li>
            <li> Developer: NxaTel Pty Ltd </li>
            <li> Company: NxaTel Pty Ltd</li>
          </ul>
          <h6>
            <b> Information We Collect:</b>
          </h6>
          <p>
            <b>Contact Details: </b>We may collect and store contact details provided by users, including:
          </p>
          <ul className="disc-list pl-3">
            <li> Name </li>
            <li> Email address</li>
            <li>Phone number</li>
            <li> Profile pictures</li>
          </ul>
          <p>
            <b>Communication Data: </b>We collect data generated through user interactions with our communication features, including:
          </p>
          <ul className="disc-list pl-3">
            <li> Messages </li>
            <li>Calls</li>
            <li>Video conferences</li>
            <li> File transfers</li>
          </ul>
          <p>
            <b> Media Files:</b>
            We may collect and store photos and videos shared by users within the app for communication purposes
          </p>
          <p>
            <b> Device Information: </b>
            We may collect information about users' devices, including:
          </p>
          <ul className="disc-list pl-3">
            <li> Device model</li>
            <li>Operating system version</li>
            <li>Unique device identifiers</li>
          </ul>
          <h6>
            <b> How We Use Your Information:</b>
          </h6>
          <p>
            <b> Providing Services: </b>
            We use the information collected to deliver and optimize our communication services, including:
          </p>
          <ul className="disc-list pl-3">
            <li>Facilitating messaging, calling, and video conferencing.</li>
            <li>Personalizing user experience.</li>
            <li>Storing and sharing media files as per user instructions.</li>
          </ul>
          <p>
            <b>Analytics: </b>
            We may use aggregated data for analytics purposes to:
          </p>
          <ul className="disc-list pl-3">
            <li>Understand user behavior and preferences.</li>
            <li>Improve our services and features.</li>
            <li>Measure the effectiveness of our communications.</li>
          </ul>
          <p>
            <b>Customer Support: </b>
            We may use collected information to provide customer support and address user inquiries and feedback.
          </p>
          <h6>
            <b> Sharing of Information:</b>
          </h6>
          <p>
            <b>Third-Party Service Providers: </b>
            We may share information with trusted third-party service providers to:
          </p>
          <ul className="disc-list pl-3">
            <li>Assist in delivering our services (e.g., hosting, analytics).</li>
            <li>Provide technical support.</li>
          </ul>
          <p>
            <b>User Consent: </b>
            We will not share users' personal information or media files with third parties without their explicit consent, except as required by law
            or to comply with legal processes.
          </p>
          <h6>
            <b> Data Security:</b>
          </h6>
          <p>
            We employ industry-standard security measures to protect the confidentiality, integrity, and availability of user information. The
            security of your Personal information is important to us, but remember that no method of transmission over the Internet, or method of
            electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot
            guarantee its absolute security.
          </p>
          <h6>
            <b>Log Data: </b>
          </h6>
          <p>
            We may also collect information that your browser sends whenever you use our App ("Log Data"). This Log Data may include information such
            as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our App that you visit, the time and date
            of your visit, the time spent on those pages, and other statistics.
          </p>
          <h6>
            <b>User Controls:</b>
          </h6>
          <p>
            Users have the right to access, modify, or delete their personal information stored by the app. Users can manage their privacy settings
            within the app or contact us for assistance.
          </p>
          <h6>
            <b> Communications</b>
          </h6>
          <p>
            We may use your Personal Information to contact you with newsletters, marketing, or promotional materials and other information that may
            be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or
            instructions provided in any email we send.
          </p>
          <h6>
            <b>Changes to This Privacy Policy: </b>
          </h6>
          <p>
            We may update this Privacy Policy from time to time to reflect changes in our data practices or legal requirements. We will notify users
            of any material changes through the app or other appropriate channels. We reserve the right to update or change our Privacy Policy at any
            time, and you should check this Privacy Policy periodically. Your continued use of the App after we post any modifications to the Privacy
            Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified
            Privacy Policy.
          </p>
          <h6>
            <b>Contact Us: </b>
          </h6>
          <p>
            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at{" "}
            <a href="https://support@nxatel.co.za" target="_blank">support@nxatel.co.za</a> or +27711755727.
          </p>
          <h6>
            <b>Effective Date:</b>
          </h6>
          <p>This Privacy Policy is effective as of 11-03-2024 and applies to all users of NxaTel Mobile App</p>
        </div>
      </div>
    </>
  );
};

export default MobilePrivacy;
