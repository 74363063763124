import $ from "jquery";
import authService from "./authService";

export const NavigationPage = (status, user_status) => {
  const { db_name, payment_token } = authService.getUserDetail();
  if (status === "P") {
    if (user_status === "UP") {
      return (window.location.href = `https://${process.env.REACT_APP_PORTAL_DOMAIN}/${"choose-plan"}`);
    } else if (user_status === "UF") {
      return (window.location.href = `https://${process.env.REACT_APP_PORTAL_DOMAIN}/${"order-summary"}`);
    } else if (user_status === "RE") {
      if (db_name === "") {
        return (window.location.href = `https://${process.env.REACT_APP_PORTAL_DOMAIN}/fetch-payment/${payment_token}`);
      } else {
        return (window.location.href = `https://${process.env.REACT_APP_PORTAL_DOMAIN}/${"rica-process"}`);
      }
    } else {
      return (window.location.href = `https://${process.env.REACT_APP_PORTAL_DOMAIN}/${"choose-number"}`);
    }
  } else {
    return (window.location.href = `https://${process.env.REACT_APP_PORTAL_DOMAIN}/${"dashboard"}`);
  }
};

export const randomString = (length) => {
  var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const removeBackdrop = () => {
  $("body").removeClass("modal-open");
  $(".modal-backdrop").remove();
};

export const REACT_APP_GOOGLE_APP_ID = "753511039530-lnooti2rk692iobli6d8uu1lj5lvbq4o.apps.googleusercontent.com";
export const REACT_APP_LINKEDIN_APP_ID = "78cqir2karm6a5";
export const REACT_APP_LINKEDIN_APP_SECRET = "DV8zq0QFnb6oGg21";
