import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Footer(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
    console.log(inputValue);
  };

  const handleSubmit = () => {
    console.log(email);
    const queryParams = new URLSearchParams();
    queryParams.set("email", email);
    navigate(`/signup?${queryParams.toString()}`);
  };

  return (
    <div className="homepage">
      <footer className="footer-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="footer-nav">
                    <ul className="">
                      <div className="mb--14 text--black font--16 lh--25 sf--t-regular fw--700">Sales</div>
                      <li className="list-style-none pt--2 pb--2">
                        <Link to="#" className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          Contact Sales
                        </Link>
                      </li>
                      <li className="list-style-none pt--2 pb--2">
                        <Link to="#" className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          +27 87 910 3000
                        </Link>
                      </li>
                      <li className="list-style-none pt--2 pb--2">
                        <Link
                          to="/pricing"
                          className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          Plan & Pricing
                        </Link>
                      </li>
                      <li className="list-style-none pt--2 pb--2">
                        <Link
                          to="/demo"
                          className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          Request a Demo
                        </Link>
                      </li>
                      <li className="list-style-none pt--2 pb--2">
                        <Link to="#" className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          Webinars and Events
                        </Link>
                      </li>
                      <li className="list-style-none pt--2 pb--2">
                        <Link to="#" className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          Partner Program
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="footer-nav">
                    <ul className="">
                      <div className="mb--14 text--black font--16 lh--25 sf--t-regular fw--700">Product</div>
                      <li className="list-style-none pt--2 pb--2">
                        <a
                          href="/calls"
                          className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          Phone
                        </a>
                      </li>
                      <li className="list-style-none pt--2 pb--2">
                        <a
                          href="/meetings"
                          className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          Meeting
                        </a>
                      </li>
                      <li className="list-style-none pt--2 pb--2">
                        <a
                          href="/message"
                          className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          Message
                        </a>
                      </li>
                      <li className="list-style-none pt--2 pb--2">
                        <a
                          href="/business-cloud"
                          className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          Cloud Storage
                        </a>
                      </li>
                      <li className="list-style-none pt--2 pb--2">
                        <a href="#" className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          Devices
                        </a>
                      </li>
                      <li className="list-style-none pt--2 pb--2">
                        <a href="#" className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          Business Pre-Paid Internet
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="footer-nav">
                    <ul className="">
                      <div className="mb--14 text--black font--16 lh--25 sf--t-regular fw--700">Company</div>
                      <li className="list-style-none pt--2 pb--2">
                        <a
                          href="/why-nxatel"
                          className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          Why Nxatel
                        </a>
                      </li>
                      <li className="list-style-none pt--2 pb--2">
                        <a href="#" className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          {" "}
                          Contact Us
                        </a>
                      </li>
                      <li className="list-style-none pt--2 pb--2">
                        <a href="#" className="text--black font--12 pt--7 pb--7 sf--t-regular hover-text--orange c--pointer text--decoration-none">
                          Join Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="pl--20 about-nexa-footer">
                    <a className="footer-logo">
                      <img src="./../assets/images/homepagelogo.png" alt="logo" className="w--120 mb--10" />
                    </a>
                    <div className="text--gray-4757 lh--23 mb--10 font--14 dm--sans ">
                      Nxatel, Pty Ltd. is a Modern unified communication platform, for Small and Medium businesses, It’s easy to set-up you just need
                      3 minutes and you gain access to cloud phone system, virtual meetings, chats, SMS’s and cloud storage solutions all in single
                      App.
                    </div>
                    <div className="mt--24 mb--8 font--20 text--blue-1d3f dm--sans fw--700">Be in the know</div>
                    <div className=" d-flex align-items-center gap--10">
                      <input className="footer-input" placeholder="Email" type="text" value={email} onChange={handleInputChange} />
                      <a
                        className="nav-link sf--t-reguler text--white font--14    p--10 radius--50 bg--orange w--min-110"
                        href="#"
                        onClick={handleSubmit}>
                        Sign Up Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt--24 gap--sm-10 ">
                <div className="col-md-4 d-flex align-self-center justify-content--sm-center">
                  <div className="font--16 text--black dm--sans fw-700">Copyright@Nxatel.2020</div>
                </div>
                <div className="col-md-4 d-flex align-self-center justify-content--sm-center">
                  <ul className="nav ">
                    <li>
                      <a href="https://support.nxa.tel/portal/" className="font--16 dm--sans pr--12 pl--12 text--black  fw-700">
                        Support
                      </a>
                    </li>
                    <li>
                      <a href="/privacy" className="font--16 dm--sans pr--12 pl--12 text--black  fw-700">
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a href="/terms" className="font--16 dm--sans pr--12 pl--12 text--black  fw-700">
                        Terms
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 d-flex align-self-center justify-content--sm-center">
                  <ul className=" nav pl--20">
                    <li>
                      <Link
                        to="//www.facebook.com/Nxatel/"
                        target={"_blank"}
                        className="font--16 fw--700 border--1 border--black mr--5 ml--5 radius--50 w--45 h--45 lh--35 d-flex align-items-center justify-content-center text--black dm--sans ">
                        fb.
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="//www.instagram.com/nxatel/?hl=en"
                        target={"_blank"}
                        className="font--16 fw--700 border--1 border--black mr--5 ml--5 radius--50 w--45 h--45 lh--35 d-flex align-items-center justify-content-center text--black dm--sans ">
                        ig.
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="//twitter.com/nxatel_"
                        target={"_blank"}
                        className="font--16 fw--700 border--1 border--black mr--5 ml--5 radius--50 w--45 h--45 lh--35 d-flex align-items-center justify-content-center text--black dm--sans ">
                        tw.
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="//www.youtube.com/channel/UCgUIl2_YkGxiipc2M_-GE5A"
                        target={"_blank"}
                        className="font--16 fw--700 border--1 border--black mr--5 ml--5 radius--50 w--45 h--45 lh--35 d-flex align-items-center justify-content-center text--black dm--sans ">
                        yt.
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
