import * as constants from "../Constants/userConstants";



const initalState = {
    is_social_signup_from: {},
    social_signup: {},
    loader: false,
    get_user_country: {},
    check_account: {},
    plans_list: [],
    currency_list: [],
    payment_info: {},
    selected_plan_during_signup: {},
    sub_pages: "login",
    sub_tabs: "",
    toastr: {}

}

const userReducer = (state = initalState, action) => {
    switch (action.type) {
        case constants.SHOW_LOADER:
            return {
                ...state,
                loader: action.payload,
            };
        case constants.TOASTR:
            return {
                ...state,
                toastr: action.payload,
            };
        case constants.PAYMENT_INFO:
            return {
                ...state,
                payment_info: action.payload,
            };
        case constants.IS_SOCIAL_SIGNUP_FROM:
            return {
                ...state,
                is_social_signup_from: action.payload
            };
        case constants.SOCIAL_SIGNUP:
            return {
                ...state,
                social_signup: action.payload
            };
        case constants.FETCH_USER_COUNTRY_DETAILS:
            return {
                ...state,
                get_user_country: action.payload
            };
        case constants.CHECK_ACCOUNT:
            return {
                ...state,
                check_account: action.payload
            };
        case constants.PLANS_LIST:
            return {
                ...state,
                plans_list: action.payload,
            };
        case constants.FETCH_CURRENCY_LISTING:
            return {
                ...state,
                currency_list: action.payload,
            };
        case constants.SELECTED_PLAN_DURING_SIGNUP:
            return {
                ...state,
                selected_plan_during_signup: action.payload,
            };
        case constants.SUB_PAGES:
            return {
                ...state,
                sub_pages: action.payload,
            };
        case constants.SUB_TABS:
            return {
                ...state,
                sub_tabs: action.payload,
            };
        default:
            return state
    }
}

export default userReducer;
