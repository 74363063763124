import React, { useState, useEffect, useCallback } from "react";
import Fade from "@material-ui/core/Fade";
import { withRouter } from "../../Utils/withRouter";
import NxaFAQ from "../NxaFAQ";
import { getCurrencyListing } from "../../Services/Actions/userActions";
import { getPlanListing } from "../../Services/Actions/planActions";
import PlanDetail from "./PlanDetail";
import { useQuery } from "@tanstack/react-query";
import PlanComparisonModal from "./PlanComparisonModal";
import Loader from "../../Component/Loader";

const initialPricingState = {
  selected_members: 1,
  selected_plan_duration: 1,
  selected_currency: "R",
  selected_plan_id: "",
};
function Pricing() {
  const [state, setState] = useState(initialPricingState);
  const { selected_members, selected_plan_duration, selected_currency, selected_plan_id } = state;
  const [showComparisonModal, setShowComparisonModal] = useState(false);

  const { data: plans_list = [], isFetching } = useQuery({
    queryKey: ["getPlanListing", selected_members, selected_currency],
    queryFn: () => getPlanListing({ plan_users: selected_members, currency: selected_currency === "R" ? "ZAR" : "USD" }),
    select: (data) => data?.data || [],
    keepPreviousData: true,
    cacheTime: Infinity,
    refetchOnMount: false,
    staleTime: Infinity,
  });

  const { data: currency_list = [], isLoading } = useQuery({
    queryKey: ["getCurrencyListing"],
    queryFn: getCurrencyListing,
    select: (data) => data?.data || [],
    cacheTime: Infinity,
    refetchOnMount: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (plans_list instanceof Array && !selected_plan_id) {
      // Recomended scenerio here
      plans_list.map((value) => {
        if (value.plan_name.includes("Professional")) {
          setState((prev) => ({ ...prev, selected_plan_id: value?.planID }));
        }
      });
    }
  }, [plans_list?.length]);

  const onPlanSelectHandler = (value) => {
    const { planID, max_user, min_user } = value;
    setState((prev) => ({ ...prev, selected_plan_id: planID }));
    // if (selected_members < Number(min_user) || selected_members > Number(max_user)) {
    //   setState((prev) => ({ ...prev, selected_members: Math.max(2, Number(min_user)) }));
    // }
  };

  const getSelectedPlanDetails = useCallback(
    ({ plan_id }) => {
      return plans_list.find((item) => item?.planID === plan_id) || null;
    },
    [plans_list]
  );

  const subtractHandler = useCallback(
    (current_selected_members) => {
      const planData = getSelectedPlanDetails({ plan_id: selected_plan_id });
      const { min_user } = planData;

      if (current_selected_members === 1) {
        return;
      } else {
        setState((prev) => ({
          ...prev,
          selected_members: prev.selected_members - 1,
        }));
      }
    },
    [selected_plan_id]
  );
  const addHandler = useCallback(
    (current_selected_members) => {
      const planData = getSelectedPlanDetails({ plan_id: selected_plan_id });
      const { max_user } = planData;
      // if (current_selected_members === Number(max_user)) {
      //   return;
      // } else {
      setState((prev) => ({
        ...prev,
        selected_members: prev.selected_members + 1,
      }));
      // }
    },
    [selected_plan_id]
  );

  return (
    <>
      <Loader showLoader={isLoading || isFetching} />
      <Fade in={true} timeout={500}>
        <React.Fragment>
          <div className="pricing-step pricing-signup pricing-page">
            <div className="pricing-after pricing-box-section pt-5">
              <div className="container">
                <div className="Steps-price pricing-page">
                  <div className="row">
                    <div className="col-md-6">
                      <span className="font--16 text--black fw--700 pl-90  mb-1 d-inline-block w-10">How many members is your Team?</span>
                      <div className="Size-team">
                        <>
                          <div className="d-flex gap--12">
                            <button
                              onClick={() => subtractHandler(selected_members)}
                              className="border--none btn btn--orange radius--50 w--50 h--50 font--24">
                              -
                            </button>
                            <button
                              style={{ backgroundColor: "#252B42" }}
                              className="radius--8 text--white border--none bg--black w--65 h--50"
                              disabled>
                              {selected_members}
                            </button>
                            <button
                              onClick={() => addHandler(selected_members)}
                              className="border--none btn btn--orange radius--50  w--50 h--50 font--18">
                              +
                            </button>
                          </div>
                        </>
                      </div>
                    </div>
                    <div className="col-md-6 text-right">
                      <div className="btn-group btn-group-pricing " role="group" aria-label="Basic example">
                        <button
                          type="button"
                          className={`btn btn-secondary ${selected_plan_duration === 1 ? "active" : ""}`}
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              selected_plan_duration: 1,
                            }))
                          }>
                          Monthly
                        </button>
                        <button
                          type="button"
                          className={`fw-700 btn btn-secondary ${selected_plan_duration === 12 ? "active" : ""}`}
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              selected_plan_duration: 12,
                            }))
                          }>
                          12-Month Save Up to 18%
                        </button>
                        <button
                          type="button"
                          className={`fw-700 btn btn-secondary ${selected_plan_duration === 24 ? "active" : ""}`}
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              selected_plan_duration: 24,
                            }))
                          }>
                          24-Month Save Up to 20%
                        </button>
                      </div>
                      <div className="form-group currency currency-div pt-3 ml-auto mr-2">
                        <select
                          className="form-control  color-293F"
                          id="exampleFormControlSelect1"
                          name="selected_currency"
                          value={selected_currency}
                          onChange={(e) =>
                            setState((prev) => ({
                              ...prev,
                              selected_currency: e.target.value,
                            }))
                          }>
                          <option value="">Currency</option>
                          {currency_list &&
                            currency_list.map((value, index) => {
                              return (
                                <option key={index} value={value.symbol}>
                                  {value.currencyCode}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pricing pricing-palden">
                  <PlanDetail plans_list={plans_list} state={state} onPlanSelectHandler={onPlanSelectHandler} />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6 free-trial-div">
                  <h5 className="12 color-696871 fw--400">Your 14-day Free Trial includes:</h5>
                  <span className="font--10">
                    - Full use of the Nxatel cloud phone system for up to 14 days
                    <br /> - Maximum of 3 users <br />- 20 min talk time per user
                    <br /> - 3 SMS <br /> - 1 Business Landline number
                  </span>
                </div>
                <div className="col-md-6">
                  <div className="still-not-sure d-flex align-items-center justify-content-end gap--12">
                    <h4 className="mb-0 font--18 fw--400">Still not sure?</h4>
                    <span
                      className="btn bg--orange border--orange pt--5 pb--5 pr--20 pl--20 radius--8 font--15 text--white dm--sans fw--700"
                      onClick={() => setShowComparisonModal(true)}>
                      Compare All Features{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ----- */}

          <NxaFAQ />
        </React.Fragment>
      </Fade>
      <PlanComparisonModal setShowComparisonModal={setShowComparisonModal} showComparisonModal={showComparisonModal} />
    </>
  );
}

export default withRouter(Pricing);
