import React from "react";
import { connect } from "react-redux";
import "./loader.css";

const Loader = (props) => {
  const { showLoader = false } = props;
  return (
    <div className={`${props.loader || showLoader ? "loader--spinner" : ""}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loader: state?.users?.loader ?? false,
});

export default connect(mapStateToProps)(Loader);
